import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { FaPlay, FaTrash } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import api from "../../../services/api";
const BatchModal = ({ onCancelForm, selectedBatch }) => {
  const [record, setRecord] = useState([]);
  const [isLoading, setIsloading] = React.useState(false);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  const getBatch = async () => {
    setIsloading(true);
    setRecord([]);
    try {
      const response = await fetch(
        `https://seodata.ecommcube.com/api/batch/detail/${selectedBatch?.valueserp_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRecord(data);
    } catch (err) {
      console.error(err);
    }
    setIsloading(false);
  };

  React.useEffect(() => {
    if (
      selectedBatch?.valueserp_id === "" ||
      selectedBatch?.valueserp_id === null
    ) {
      toast.error("ValueSERP not available for this Batch!");
      onCancelForm();
    } else {
      getBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnDefs = [
    {
      headerName: "Batch ID",
      field: "valueserp_id",
      flex: 1,
    },
    { headerName: "Name", field: "name", flex: 1, minWidth: 130 },
    { headerName: "Short Note", field: "short_note", flex: 1 },
    {
      headerName: "Schedule Type",
      field: "schedule_type",
      flex: 1,
    },
    {
      headerName: "Last Run",
      field: "last_run",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
    },
    {
      headerName: "Searches Total Count",
      field: "searches_total_count",
      flex: 1,
    },
    {
      headerName: "Notification Email",
      field: "notification_email",
      flex: 1,
    },
    { headerName: "Enabled", field: "enabled", align: "center", width: 80 },
    {
      headerName: "Batch Run Count",
      field: "results_count",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Batch Delete Status",
      field: "delete_status",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Batch Result Status",
      field: "result_set_status",
      flex: 1,
      align: "center",
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row?._id ? (
            <>
              <button
                onClick={() => handleStart(params.row?.valueserp_id)}
                title={"Start the position checking process"}
              >
                {params.row?.status && params.row?.status !== "idle" ? (
                  <FaPlay style={{ color: "green" }} />
                ) : (
                  <FaPlay />
                )}
              </button>
              <button
                className="ml-2"
                onClick={() => handleDelete(params.row?.valueserp_id)}
                title={"Delete batch"}
              >
                <FaTrash />
              </button>
            </>
          ) : null}
          {params.row?.delete_status === "Deleted" ? (
            <button
              className="ml-2"
              onClick={() => handleRegenerate(params.row?.valueserp_id)}
              title={"Regenerate batch"}
            >
              <TbReload />
            </button>
          ) : null}
        </div>
      ),
    },
  ];
  const handleStart = async (valueserp_id) => {
    const c = window.confirm("Are you sure want to start the batch?");
    if (!c) return;
    setIsloading(true);
    try {
      const res = await api.post(`/api/domains/start_batch/${valueserp_id}`);
      if (res.status === 200) {
        getBatch();
        toast.success("Batch started successfully");
      } else {
        toast.error(res?.data?.message || "Batch couldn't be started");
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      toast.error(err?.response?.data?.message || "Batch couldn't be started");
      console.log(err);
    }
  };
  const handleDelete = async (valueserp_id) => {
    const c = window.confirm("Are you sure want to delete the batch?");
    if (!c) return;
    setIsloading(true);
    try {
      const res = await api.delete(`/api/domains/delete_batch/${valueserp_id}`);
      if (res.status === 200) {
        onCancelForm();
        toast.success("Batch deleted successfully");
      } else {
        toast.error("Batch couldn't be deleted");
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      toast.error("Batch couldn't be deleted");
      console.log(err);
    }
  };
  const handleRegenerate = async (valueserp_id) => {
    const c = window.confirm("Are you sure want to regenerate the batch?");
    if (!c) return;
    setIsloading(true);
    try {
      const res = await api.get(
        `/api/domains/regenerate_batch/${valueserp_id}`
      );
      if (res.status === 200) {
        getBatch();
        toast.success("Batch regenerate successfully");
      } else {
        toast.error("Batch couldn't be regenerate");
      }
      setIsloading(false);
    } catch (err) {
      setIsloading(false);
      toast.error("Batch couldn't be regenerate");
      console.log(err);
    }
  };
  return (
    <>
      <div className="mb-2 mt-2 ">
        <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[30px] mb-2 text-md rounded-sm mx-2">
          <h1 className="text-lg text-white font-medium flex items-center justify-center w-full">
            Batch List
          </h1>
        </header>
        <MUIDataTable
          items={[
            {
              _id: record?._id,
              name: record?.name,
              enabled: record?.enabled,
              schedule_type: record?.schedule_type,
              priority: record?.priority,
              custom_id: record?.custom_id,
              custom_notification_email: record?.custom_notification_email,
              short_note: record?.short_note,
              notification_email: record?.notification_email,
              searches_type: record?.searches_type,
              last_run: record?.last_run,
              status: record?.status,
              results_count: record?.results_count,
              searches_total_count: record?.searches_total_count,
              searches_page_count: record?.searches_page_count,
              credits_required: record?.credits_required,
              result_set_status: record?.result_set_status,
              delete_status: record?.delete_status,
              createdAt: dayjs(record?.createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              valueserp_id: record?.valueserp_id,
            },
          ]}
          columnDefs={columnDefs}
          isLoading={isLoading}
          searchable="No"
          showCount="No"
          toolbar="No"
          height="13vh"
          pagination="No"
          getRowId={(row) => row?._id}
          hideFooter
        />
      </div>
    </>
  );
};

export default BatchModal;
