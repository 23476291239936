import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import AddUpdateRows from "./AddUpdateRows";
import ImportExcel from "./ImportExcel";
import {
  delete_record,
  delete_table_rows,
  export_table,
  get_custom_tags,
  get_db_tables,
  get_table_rows,
} from "../../features/databaseSlice";
import { Button, DotsLoader } from "../../components";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
function DatabaseRows() {
  const { isLoading, tableRows, dbTables, customTags } = useSelector(
    (state) => state.database
  );
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const rowRef = React.useRef();
  const tagListRef = React.useRef();
  const matchRef = React.useRef();
  const bottomTag = React.useRef();

  const data = tableRows?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const [isCell, setIsCell] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isImport, setIsImport] = useState(false);

  const [tagsArray, setTagsArray] = useState([]);

  const [isIndex, setIsIndex] = useState();
  const params = useParams();
  const { dbName, tableName, tagName } = params;
  const dispatch = useDispatch();
  useEffect(() => {
    if (tableName) {
      dispatch(get_table_rows({ dbName: dbName, tableName: tableName }));
    }
    if (dbName && dbTables.data > 0) {
      dispatch(get_db_tables(dbName));
    }
    setTagsArray([]);
    // eslint-disable-next-line
  }, [tableName]);
  const refreshData = () => {
    if (tableName) {
      dispatch(get_table_rows({ dbName: dbName, tableName: tableName }));
    }
    if (dbName && dbTables.data > 0) {
      dispatch(get_db_tables(dbName));
    }
    setTagsArray([]);
  };
  useEffect(() => {
    dispatch(get_custom_tags({ dbName: dbName, tableName: "custom_tag" }));
    if (dbName && (!dbTables || !dbTables.length)) {
      dispatch(get_db_tables(dbName));
    }
    // eslint-disable-next-line
  }, [dbName]);

  const selectAll = () => {
    let id = [];
    if (selectedData?.length !== data.length) {
      // eslint-disable-next-line
      data.map((val) => {
        id = [...id, val];
      });
      setSelectedData(id);
    } else if (selectedData?.length === data.length) {
      setSelectedData([]);
    }
  };

  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData.filter(({ id }) => id === val.id).length > 0) {
        const arr = selectedData.filter(({ id }) => id !== +val.id);
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };

  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ id }) => id === val).length > 0) {
        return true;
      }
    }
    return false;
  };
  const deleteRecordHandler = (data) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    let ids = [];
    // eslint-disable-next-line
    data.map((val) => {
      ids = [...ids, val.id];
    });

    if (c) {
      try {
        const res = await dispatch(
          delete_table_rows({
            dbName: dbName,
            tableName: tableName,
            ids: ids,
          })
        );
        if (res.payload.status === 200) {
          toast.success("Selected record deleted");
          refreshData();
          dispatch(delete_record(ids));
          setSelectedData([]);
        } else {
          toast.error("Selected record couldn't be deleted");
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        toast.error("Selected record couldn't be deleted");
      }
    }
  };
  const addNewrecord = () => {
    setIsNew(true);
    let newObj;
    if (tableRows?.data?.length > 0) {
      newObj = Object.keys(tableRows?.data[0])?.reduce((accumulator, key) => {
        return { ...accumulator, [key]: "" };
      }, {});
    } else {
      newObj = Object.values(tableRows?.tableColumns)?.reduce(
        (accumulator, key) => {
          return { ...accumulator, [key]: "" };
        },
        {}
      );
    }

    let id =
      tableRows?.data?.length > 0 ? Math.max(...data?.map((o) => o?.id)) : 0;
    newObj = { ...newObj, id: id + 1 };
    setEditingRecord([newObj]);
    setIsEditing(true);
  };
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setSelectedData([]);
    setIsCell("");
    setIsNew(false);
  };
  const handleShowTags = (value) => {
    setTagsArray([]);
    if (value?.match(/\[.*?\]/g)) {
      let tags = [];
      value?.match(/\[.*?\]/g)?.map((tag) => {
        if (customTags?.data?.find(({ tagName }) => tagName === tag)) {
          const isTag = customTags?.data?.find(
            ({ tagName }) => tagName === tag
          );
          if (!tags.filter(({ tagName }) => tagName === tag).length > 0) {
            tags = [...tags, isTag];
          }
        }
      });
      setTagsArray(tags);
    }
  };

  useEffect(() => {
    rowRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [tagsArray, tableName]);
  useEffect(() => {
    bottomTag.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [tableName, bottomTag.current]);

  useEffect(() => {
    if (matchRef.current) {
      matchRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [data, tagName, matchRef.current]);
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setTagsArray([]);
    }
  };
  const handleClickOutside = (event) => {
    if (tagListRef.current && !tagListRef.current.contains(event.target)) {
      setTagsArray([]);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  const TagList = () => {
    return (
      <div
        className="z-10 min-w-[200px] max-h-[500px] top-0 right-[30%] overflow-y-auto absolute bg-white text-primary-100 z-50 border-l border-[#ddd] border-r shadow-lg"
        ref={tagListRef}
      >
        <div
          className="bg-[#f5f5f5] text-black p-2.5 border-t border-[#ddd] flex justify-between items-center"
          ref={rowRef}
        >
          Used Tags list
        </div>
        <table className="table table-bordered">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th>#</th>
              <th>TagName</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody className="!bg-white">
            {tagsArray?.map((tag, index) => {
              const { tagName, description } = tag;
              return (
                <tr
                  key={index}
                  className="text-black p-2.5 border-t border-[#ddd] hover:bg-[#e1e1e1] cursor-pointer"
                  onClick={() => {
                    navigate(
                      `/industry_database/${dbName}/custom_tag/${tagName}`
                    );
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{tagName}</td>
                  <td>{description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const handleExport = async () => {
    try {
      const res = await dispatch(
        export_table({ dbName: dbName, tableName: tableName })
      );
      if (res.payload?.status === 200) {
        const blobData = res.payload.data;
        console.log("🚀 ~ handleExport ~ blobData:", blobData);
        saveBlobAsExcelFile(blobData, tableName);
        toast.success("Table export successfully");
      } else {
        toast.error("Table couldn't be exported");
      }
    } catch (error) {
      toast.error("Table couldn't be exported");
      console.error("Error fetching Excel data:", error);
    }
  };
  const saveBlobAsExcelFile = (blobData, dbName) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `${dbName}.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const CustomButtons = () => {
    return (
      <div className="space-x-1">
        <Button
          text="Export"
          variant="btn_submit"
          onClick={handleExport}
          // onClick={() =>
          //   dispatch(export_table({ dbName: dbName, tableName: tableName }))
          // }
        />
        <Button
          text={`${tableName} Import`}
          variant="btn_submit"
          onClick={() => setIsImport(true)}
        />
        <Button
          text="Delete"
          variant="btn_cancel"
          onClick={deleteRecordHandler(selectedData)}
          disabled={selectedData?.length > 0 ? false : true}
        />
        <Button
          text="Add New Row"
          variant="btn_submit"
          onClick={addNewrecord}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        heading={tableName?.replaceAll("_", " ")}
        CustomButtons={CustomButtons}
      />
      {isEditing && (
        <AddUpdateRows
          editingRecord={editingRecord}
          modalTitle="Edit content"
          onCancelForm={cancelFormHandler}
          isNew={isNew}
          dbName={dbName}
          tableName={tableName}
          refreshData={refreshData}
        />
      )}
      {isImport && (
        <ImportExcel
          editingRecord={editingRecord}
          modalTitle="Import Excel"
          onCancelForm={() => setIsImport(false)}
          dbName={dbName}
          tableName={tableName}
        />
      )}
      <div className="bg-white my-3 border rounded p-2">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="relative w-1/2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className="h-[36px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
              placeholder="Search"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </div>
        </div>

        {isLoading && <DotsLoader text="Please Wait" />}
        <div className="bg-white !my-3 borde rounded overflow-y-auto flex-wrap flex-row w-full borde-[#ccc]">
          {data?.length > 0 ? (
            <div className="table-responsive h-[69vh]">
              <table className="table table-striped table-bordered !mb-0">
                <thead className="z-10 sticky top-0 bg-white">
                  <tr className="bg-white">
                    <th>
                      <input
                        type="checkbox"
                        onChange={selectAll}
                        checked={
                          selectedData?.length === data?.length ? true : false
                        }
                        className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
                      />
                    </th>
                    {Object?.keys(data[0] ?? {})?.map((header, index) => {
                      return (
                        <th
                          key={index}
                          className="capitalize"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {header?.replace("_", " ")}
                        </th>
                      );
                    })}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((val, index, key) => {
                    return (
                      <tr
                        key={index}
                        className={`relative ${
                          tagName && tagName === val.tagName
                            ? "!bg-primary-100"
                            : ""
                        }`}
                      >
                        <td
                          className="cursor-pointer"
                          onClick={() =>
                            setSelectedData([...selectedData, val])
                          }
                          ref={
                            tagName && tagName === val.tagName ? matchRef : null
                          }
                        >
                          <input
                            type="checkbox"
                            checked={isChecked(val.id)}
                            onChange={() => onSingleselect(val)}
                            className={`cursor-pointer form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2`}
                          />
                        </td>
                        {Object.entries(data[index]).map(([key, value]) => {
                          return (
                            <Fragment key={value + Math.random()}>
                              <td
                                className={`cursor-pointer`}
                                onClick={() => {
                                  setIsCell(key);
                                  setIsIndex(index);
                                  handleShowTags(value);
                                }}
                              >
                                <span className="text-black">{value}</span>
                                {tagsArray?.length > 0 &&
                                  isCell === key &&
                                  isIndex === index && <TagList />}
                              </td>
                            </Fragment>
                          );
                        })}
                        <td className="fle flex-row items-center justify-around !borde">
                          {selectedData?.filter((item) => item.id === val.id)
                            .length > 0 ? null : (
                            <>
                              <FaEdit
                                size={20}
                                className="cursor-pointer !text-secondary"
                                onClick={() => {
                                  setEditingRecord([data[index]]);
                                  setIsEditing(true);
                                  setIsNew(false);
                                }}
                              />
                              <MdDelete
                                size={22}
                                className="cursor-pointer !text-secondary"
                                onClick={deleteRecordHandler([val])}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <MUIDataTable
              columnDefs={(tableRows?.tableColumns || [])?.map((val) => {
                return { field: val, flex: 1 };
              })}
              pagination="No"
              searchable="No"
              toolbar="No"
              showCount="No"
            />
          )}
        </div>
      </div>
      <div className="fixed bottom-0 w-full md:w-[calc(100vw-230px)]">
        <ul className="flex flex-row overflow-x-auto bg-white w-full  p-2 w-full">
          {dbTables?.data?.map((table, index) => {
            return (
              <div
                className="border-r-2 pr-2 flex items-center justify-center"
                key={index}
              >
                <li
                  className={`!mx-1 cursor-pointer capitalize w-auto whitespace-nowrap text-xs rounded-full !p-1 !px-2 ${
                    tableName === table.tableName
                      ? "bg-primary-100 text-white"
                      : ""
                  }
                  hover:bg-primary-100 hover:text-white`}
                  key={index}
                  onClick={() =>
                    navigate(`/industry_database/${dbName}/${table.tableName}`)
                  }
                  ref={tableName === table.tableName ? bottomTag : null}
                >
                  {table.tableName}
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default DatabaseRows;
