import React from "react";
import Button from "../../components/atoms/Button";
const CitiesModal = ({ onCancelForm, cities, state }) => {
  const modalRef = React.useRef(null);
  const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const filterStates = cities?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-slate-900 text-white h-[50px] mb-3 text-xl">
              {cities?.length} Cities of {state} State
            </header>
            <input
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              value={searchText}
            />
            <div className="table-responsive max-h-[60vh] mb-2">
              <table className="table table-striped table-bordered">
                <thead className="z-[1] sticky top-0 bg-white border-t border-[#ccc]">
                  <tr className='className="bg-white border-t border-[#ccc]'>
                    <th className="sticky">#</th>
                    <th className="sticky">Name</th>
                    <th className="sticky">Latitude</th>
                    <th className="sticky">Longitude</th>
                    <th className="sticky">Alias</th>
                  </tr>
                </thead>

                <tbody className=" max-h-[50vh]  mb-2 ">
                  {filterStates?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-b-[1px] border-slate-200 p-4  "
                      >
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.latitude}</td>
                        <td>{data.longitude}</td>
                        <td className="!max-w-[300px]">
                          {data.alias.replaceAll(",", ", ")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CitiesModal;
