import React, { useEffect, useState } from "react";
import { Button, DotsLoader, Loader } from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { get_cities, get_geo_states } from "../../features/GeoVendorsSlice";
import Select from "react-select";
import { MultiSelect } from "primereact/multiselect";
import { get_geo_location_detail } from "../../features/geoLocSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import {
  assign_vendor_indutries_location,
  update_vendor_indutries_location,
} from "../../features/vendorIndustrySlice";
import { HiLockClosed } from "react-icons/hi";
import {
  FaCaretDown,
  FaChevronDown,
  FaChevronUp,
  FaRegTrashAlt,
} from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { MdCancel } from "react-icons/md";
import IndustriesModal from "./IndustriesModal";
import JobsOnMap from "./MapView";
import { map_pin } from "../../images";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import CopyZipsModal from "./CopyZips";
import api from "../../services/api";
import { assignVendorIndustryLocations } from "../../services/vendorIndustryServices";
import * as XLSX from "xlsx";
const UpdateLocationModal = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  vendorDetail,
  allIndustries,
  industry_mongoId,
}) => {
  const { states, cities } = useSelector((state) => state.geoVendors);
  const { isLoading } = useSelector((state) => state.vendorIndustries);
  const [isMapView, setIsMapView] = useState(false);
  const [mapRecord, setMapRecord] = useState([]);
  const [domains, setDomains] = useState([1]);
  const [cityTags, setCityTags] = useState([]);
  const [invalidCity, setInvalidCity] = useState([]);
  const [copyFrom, setCopyFrom] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [singlePayload, setSinglePayload] = useState({
    type: "state",
    state_id: null,
    radiusInMeters: "",
    locations: [],
    industries: [],
  });
  const openMapHandler = (data) => {
    // return
    setMapRecord(data);
    setIsMapView(true);
  };
  const cancelFormHandler = () => {
    setMapRecord(null);
    setIsMapView(false);
  };
  const array = [
    { label: "State", value: "state" },
    { label: "City", value: "city" },
    { label: "City Alias", value: "city_alias" },
    { label: "Zip", value: "zip" },
  ];

  const dispatch = useDispatch();
  const [payloads, setPayload] = useState(
    editingRecord && editingRecord?.payloads?.length > 0
      ? editingRecord?.payloads
      : []
  );
  const [assignedLocations, setAssignedLocation] = useState(true);
  const [locationLoader, setIsloader] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState([]);
  const [industryModal, setIndustryModal] = useState(false);
  const [copyZipModal, setCopyZipModal] = useState(false);
  let initialValues = {
    vendor_id: "",
    industries: [],
    zips: [],
  };
  if (editingRecord) {
    const { vendor_id, industries, zips, id } = editingRecord;
    initialValues = {
      id,
      vendor_id: vendor_id,
      industries,
      zips: zips ? zips : [],
    };
  }
  function generateExcelForMissingZips(missingZips, industry) {
    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      missingZips.map((zip) => ({ Zip: zip })),
      {
        header: ["Zip"],
      }
    );

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "MissingZips");

    // Generate the Excel file
    XLSX.writeFile(wb, `${industry}_missingZips.xlsx`);
  }
  const temLocationSubmit = async () => {
    const newArray = [...new Set(singlePayload?.locations)];
    try {
      await assignVendorIndustryLocations({
        ...formik?.values,
        zips: [...newArray],
      });
    } catch (err) {
      const missingZips = err.response.data.missingZips;
      const newZips = newArray?.filter((zip) => !missingZips.includes(zip));
      await assignVendorIndustryLocations({
        ...formik?.values,
        zips: [...newZips],
      });
      const industry = allIndustries?.find(
        ({ mongo_id }) => mongo_id === editingRecord?.industries[0]
      )?.industry_name;
      generateExcelForMissingZips(missingZips, industry);
      console.log("🚀 ~ temLocationSubmit ~ err:", err);
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: UserSchema,
    onSubmit: async (values) => {
      const oldZips = newData?.reduce((acc, stateData) => {
        stateData?.cities?.forEach((cityData) => {
          acc.push(...cityData.zips);
        });
        return acc;
      }, []);
      // Create a new array with unique zip codes
      const newArray = [...new Set(oldZips)];
      const zipCodes = searchedLocation.flatMap((item) =>
        item.flatMap((obj) => obj.data.zips.map((zipObj) => zipObj.zip))
      );
      if (values?.id) {
        try {
          const res = await dispatch(
            assign_vendor_indutries_location({
              ...values,
              zips: [...newArray, ...zipCodes],
            })
          );
          if (res?.payload?.status === 201 || res?.payload?.status === 200) {
            toast.success("Assigned locations update successfully");
            window.location.reload();
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              // setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "Locations couldn't be updated"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );
          toast.error("Locations couldn't be updated");
        }
      } else {
        try {
          let payload = {
            ...values,
            // industries: allIndustries
            //   ?.filter(
            //     (industry) => industry?.mongo_id === editingRecord?.industries[0]
            //   )
            //   ?.map(({ mongo_id }) => mongo_id),
            zips: [...newArray, ...zipCodes],
          };

          const res = await dispatch(assign_vendor_indutries_location(payload));
          if (res?.payload?.status === 201) {
            toast.success("Locations assigned successfully");
            window.location.reload();
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              // setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "Locations couldn't be assigned"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );
          toast.error("Locations couldn't be assigned");
        }
      }
    },
  });
  const get_industry_zips = async () => {
    try {
      const res = await api.get(
        `/api/vendors/vendor/${editingRecord?.vendor_id}/${editingRecord?.industries[0]}`
      );
      if (res.status === 200) {
        // formik.setFieldValue("zips", res?.data);
        setNewData(groupDataByStateAndCity(res?.data));
      }
    } catch (err) {
      console.log("🚀 ~ constget_industry_zips=async ~ err:", err);
    }
  };
  useEffect(() => {
    dispatch(get_geo_states());
    dispatch(get_geo_industry());
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (singlePayload.state_id) {
      dispatch(get_cities({ state_id: singlePayload.state_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePayload.state_id]);

  useEffect(() => {
    if (editingRecord?.payloads?.length > 0) {
      getLocations();
    }
    if (editingRecord?.vendor_id && editingRecord?.industries[0]) {
      get_industry_zips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [miles, setMiles] = useState("");
  const handleMilesChange = (event) => {
    const value = event.target.value;
    setSinglePayload({
      ...singlePayload,
      radiusInMeters: Math.round(value * 1609),
    });
    setMiles(value);
  };
  const clearAll = () => {
    const c = window.confirm("Are you sure want to clear all search data?");
    if (c) {
      setSearchedLocation([]);
      setSinglePayload({
        type: "state",
        state_id: null,
        radiusInMeters: "",
        locations: [],
        industries: [],
      });
      setPayload([]);
      setMiles("");
    }
  };
  const handleValidate = (data) => {
    const citiesName = cities.map((obj) => obj.name);
    // Elements that exist in both arrays
    const intersection = data.filter((obj) => citiesName.includes(obj));
    // Elements that do not exist in both arrays
    const difference = data.filter((obj) => !citiesName.includes(obj));
    setSinglePayload({ ...singlePayload, locations: intersection });
    setInvalidCity(difference);
  };
  const FilterHandler = async () => {
    if (
      (singlePayload.type === "city" || singlePayload.type === "city_alias") &&
      !singlePayload.state_id
    ) {
      toast.error("Please select a state");
    } else {
      if (singlePayload?.locations?.length === 0) {
        toast.error("Please select the locations");
        return;
      }
      let newPayloads = [];
      singlePayload?.locations?.forEach(async (location) => {
        const newPayload = {
          ...singlePayload,
          locations: location.toString(),
        };
        newPayloads = [...newPayloads, newPayload];
      });
      const newPayloadss = newPayloads.filter((newPayload) => {
        return !payloads?.some((existingPayload) => {
          return (
            String(existingPayload?.payload?.locations)?.toLowerCase() ===
              String(newPayload?.locations)?.toLowerCase() &&
            existingPayload?.payload?.type === newPayload?.type &&
            existingPayload?.payload?.state_id === newPayload?.state_id &&
            existingPayload?.payload?.radiusInMeters ===
              newPayload?.radiusInMeters
          );
        });
      });
      if (newPayloadss?.length === singlePayload?.locations?.length) {
        // if (assignedLocations) {
        //   setAssignedLocation(false);
        // }
        try {
          // setIsloader(true);
          // let inValidLocations = [];
          // if (singlePayload.type === "city_alias") {
          //   const validate = await dispatch(
          //     get_valid_locations({
          //       type: singlePayload.type,
          //       locations: singlePayload?.locations?.toString(),
          //       state_id: singlePayload?.state_id,
          //     })
          //   );
          //   if (validate?.payload?.status) {
          //     if (singlePayload?.locations?.length > 0) {
          //       inValidLocations = singlePayload?.locations?.filter(
          //         (element) =>
          //           !validate?.payload?.data
          //             .map((valid) => valid.toLowerCase())
          //             .includes(element.toLowerCase())
          //       );
          //     }
          //     const validLocations = singlePayload?.locations?.filter((element) =>
          //       validate?.payload?.data
          //         ?.map((valid) => valid.toLowerCase())
          //         ?.includes(element.toLowerCase())
          //     );
          //     setInvalidCity(inValidLocations);
          //     setValidLoc(validLocations);
          //   }
          //   if (inValidLocations?.length) return;
          // }
          singlePayload?.locations?.forEach(async (location) => {
            setIsloader(true);
            const res = await dispatch(
              get_geo_location_detail({
                ...singlePayload,
                state_id:
                  singlePayload?.type === "city" ||
                  singlePayload?.type === "city_alias"
                    ? singlePayload?.state_id
                    : null,
                locations: location.toString(),
              })
            );
            if (res?.payload?.status === 200) {
              if (res.payload.data?.length > 0) {
                setPayload((prevArray) => [
                  ...prevArray,
                  {
                    industries: singlePayload?.industries,
                    payload: {
                      type: singlePayload?.type,
                      state_id:
                        singlePayload?.type === "city" ||
                        singlePayload?.type === "city_alias"
                          ? singlePayload?.state_id
                          : null,
                      radiusInMeters: singlePayload?.radiusInMeters,
                      locations: location.toString(),
                    },
                  },
                ]);
                setSearchedLocation((prevArray) => [
                  res.payload.data,
                  ...prevArray,
                ]);
              } else {
                toast.error(
                  `${`Search By: ${singlePayload?.type} ${
                    singlePayload?.state_id ||
                    singlePayload?.payload?.type === "state"
                      ? `| State: ${
                          singlePayload?.type === "city" ||
                          singlePayload?.type === "city_alias"
                            ? states?.find(
                                ({ id }) => id === singlePayload?.state_id
                              )?.name
                            : singlePayload?.type === "state"
                            ? location
                              ? location
                              : ""
                            : ""
                        }`
                      : ""
                  } ${
                    singlePayload?.radiusInMeters
                      ? `| Radius: ${
                          singlePayload?.radiusInMeters / 1609
                        } Miles`
                      : ""
                  } | Location: ${location ? location : ""} `} has no record`,
                  {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  }
                );
              }
            }
            setIsloader(false);
            setSinglePayload({ ...singlePayload, locations: [] });
            setCityTags([]);
            setInvalidCity([]);
          });
        } catch (err) {
          setIsloader(false);
          console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
        }
      } else {
        toast.error("Same locations are already exist in searched record");
      }
    }
  };

  const getLocations = async () => {
    setIsloader(true);
    try {
      payloads?.forEach(async ({ payload }) => {
        const res = await dispatch(
          get_geo_location_detail({
            ...payload,
            locations: payload?.locations?.toString(),
          })
        );
        if (res?.payload?.status === 200) {
          if (res.payload.data?.length > 0) {
            setSearchedLocation((prevArray) => [
              res.payload.data,
              ...prevArray,
            ]);
          } else {
            toast.error(
              `${`Search By: ${payload?.type} ${
                payload?.state_id || payload?.payload?.type === "state"
                  ? `| State: ${
                      payload?.type === "city" || payload?.type === "city_alias"
                        ? states?.find(({ id }) => id === payload?.state_id)
                            ?.name
                        : payload?.type === "state"
                        ? payload?.locations
                          ? payload?.locations
                          : ""
                        : ""
                    }`
                  : ""
              } ${
                singlePayload?.radiusInMeters
                  ? `| Radius: ${singlePayload?.radiusInMeters / 1609} Miles`
                  : ""
              } | Location: ${
                payload?.locations ? payload?.locations : ""
              } `} has no record`,
              {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          }
        }
        setIsloader(false);
      });
    } catch (err) {
      setIsloader(false);
      console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
    }
  };
  const matchedPayload = (data) => {
    const matchingPayloadIndex = searchedLocation?.findIndex((pay) => {
      const payload = pay[0];
      return (
        String(payload?.payload?.locations).toLowerCase() ===
          data?.payload?.locations?.toLowerCase() &&
        payload?.payload?.type === data?.payload?.type &&
        payload?.payload?.state_id === data?.payload?.state_id &&
        payload?.payload?.radiusInMeters === data?.payload?.radiusInMeters
      );
    });
    return matchingPayloadIndex;
  };
  const handleDeleteAtIndex = (data) => {
    const index = matchedPayload(data);
    setSearchedLocation((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array to avoid mutating it
      newArray.splice(index, 1); // Remove one element at the specified index
      return newArray;
    });
  };

  const groupDataByStateAndCity = (data) => {
    const groupedData = [];

    data?.forEach((item) => {
      const stateIndex = groupedData.findIndex(
        (stateData) => stateData.state === item?.state_id?.name
      );
      if (stateIndex !== -1) {
        // State already exists in groupedData
        const cityIndex = groupedData[stateIndex].cities.findIndex(
          (cityData) => cityData.name === item?.city_id?.name
        );
        if (cityIndex !== -1) {
          // City already exists in the state's cities array
          groupedData[stateIndex].cities[cityIndex].zips.push(item.zip);
          groupedData[stateIndex].cities[cityIndex].zip_latlng.push({
            zip: item.zip,
            lat: item?.latitude,
            lng: item?.longitude,
          });
        } else {
          // City doesn't exist in the state's cities array
          groupedData[stateIndex].cities.push({
            name: item?.city_id?.name,
            alias: item?.city_id.alias?.split(","),
            zips: [item.zip],
            zip_latlng: [
              {
                zip: item.zip,
                lat: item?.latitude,
                lng: item?.longitude,
              },
            ],
          });
        }
      } else {
        // State doesn't exist in groupedData
        groupedData.push({
          state: item?.state_id.name,
          cities: [
            {
              name: item?.city_id.name,
              alias: item?.city_id.alias?.split(","),
              zips: [item.zip],
              zip_latlng: [
                {
                  zip: item.zip,
                  lat: item?.latitude,
                  lng: item?.longitude,
                },
              ],
            },
          ],
        });
      }
    });
    return groupedData;
  };
  const getTotalZips = () => {
    let totalZips = 0;
    newData?.forEach((state) => {
      state.cities.forEach((city) => {
        if (Array.isArray(city.zips)) {
          totalZips += city.zips.length;
        } else {
          totalZips += 1;
        }
      });
    });
    return totalZips;
  };
  const getTotalAlias = () => {
    let totalAlias = 0;
    newData?.forEach((state) => {
      state.cities.forEach((city) => {
        if (Array.isArray(city?.alias)) {
          totalAlias += city?.alias?.length;
        } else {
          totalAlias += 1;
        }
      });
    });
    return totalAlias;
  };

  const getTotalCities = () => {
    let totalCities = 0;
    newData?.forEach((state) => {
      totalCities += state.cities.length;
    });
    return totalCities;
  };
  const subTotalofZips = (datat) => {
    const totalStateZips = datat.reduce(
      (acc, cityData) => acc + cityData.zips.length,
      0
    );
    return totalStateZips;
  };
  const subTotalofAlias = (data) => {
    const totalStateZips = data.reduce(
      (acc, cityData) => acc + cityData?.alias?.length,
      0
    );
    return totalStateZips;
  };
  const [newData, setNewData] = useState(
    groupDataByStateAndCity(editingRecord?.zips)
      ? groupDataByStateAndCity(editingRecord?.zips)
      : []
  );
  useEffect(() => {
    if (newData?.length) {
      checkDomains({ data: newData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);
  const handleRemoveZip2 = (zip) => {
    // Remove the zip code from array1
    const updatedData = newData?.map((state) => ({
      ...state,
      cities: state.cities.map((city) => ({
        ...city,
        zips: Array.isArray(city.zips)
          ? city.zips.filter((item) => item !== zip)
          : city.zips,
      })),
    }));
    const updateCities = updatedData.map((state) => ({
      ...state,
      cities: state.cities.filter((city) => city.zips?.length !== 0),
    }));
    const finalData = updateCities?.filter(
      (state) => state?.cities?.length !== 0
    );
    setNewData(finalData);
  };
  const handleRemoveCity = (cityId) => {
    const c = window.confirm("Are you sure you want to remove this city?");
    if (c) {
      const updatedData = newData.map((state) => ({
        ...state,
        cities: state.cities.filter((city) => city.name !== cityId),
      }));
      const finalData = updatedData?.filter(
        (state) => state?.cities?.length !== 0
      );
      setNewData(finalData);
    }
  };
  const handleRemoveState = (stateId) => {
    const c = window.confirm("Are you sure you want to remove this state?");
    if (c) {
      const updatedData = newData.filter((state) => state.state !== stateId);
      setNewData(updatedData);
    }
  };
  function copyInvalidData() {
    const dataToCopy = invalidCity.join("\n");
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        toast.success("All tags have been copied to the clipboard!");
      })
      .catch((error) => {
        toast.error("Copy to clipboard failed: " + error);
      });
  }
  function Breadcrumbs({ breadcrumb }) {
    const pathname = breadcrumb;
    const paths = pathname?.split("/").filter(Boolean);
    const breadcrumbs = paths?.map((path, i) => {
      let route = `/${paths.slice(0, i + 1).join("/")}`;
      return (
        <span key={i}>
          <Link
            to={i < paths.length - 1 ? route : ""}
            className={`text-base font-medium font-mont text-[#fff] capitalize hover:text-primary-100 text-md font-semibold !hover:text-primary-100`}
            onClick={i === paths.length - 1 ? onCancelForm : null}
          >
            {path.replaceAll("_", " ")}
          </Link>
          {i < paths.length - 1 && <span> {">"} </span>}
        </span>
      );
    });
    return <div>{breadcrumbs}</div>;
  }
  const industries_without = vendorDetail?.industries?.filter(
    (item) => !vendorDetail?.industries_has_locations?.includes(item.id)
  );
  const with_locations = vendorDetail?.industries?.filter((item) =>
    vendorDetail?.industries_has_locations?.includes(item.id)
  );
  const groupedOptions = [
    {
      label: "Industries without Locations",
      items: industries_without?.map((category) => ({
        label: allIndustries?.find(({ mongo_id }) => mongo_id === category.id)
          ?.industry_name,
        value: category?.id,
      })),
    },
    {
      label: "Industries with Locations",
      items: with_locations?.map((category) => ({
        label: allIndustries?.find(({ mongo_id }) => mongo_id === category.id)
          ?.industry_name,
        value: category?.id,
      })),
    },
  ];
  const handleChangeIndustry = (industry_id) => {
    if (with_locations?.filter(({ id }) => id === industry_id)?.length) {
      const zips = vendorDetail?.industries_locations?.find(({ industries }) =>
        industries?.includes(industry_id)
      );
      formik.setFieldValue("industries", [industry_id]);
      formik.setFieldValue("id", zips?.vendor_industries_locations_id);
      setNewData(
        groupDataByStateAndCity(zips?.zips)
          ? groupDataByStateAndCity(zips?.zips)
          : []
      );
      setDomains([]);
      setSelectedLocations([]);
    } else {
      formik.setFieldValue("industries", [industry_id]);
      formik.setFieldValue("id", null);
      setNewData([]);
      setSelectedLocations([]);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedLocations([...selectedLocations, data]);
    } else {
      setSelectedLocations(
        selectedLocations?.filter((domain) => domain?.state !== data?.state)
      );
    }
  };
  const handleCancelCopy = () => {
    setCopyFrom(null);
    setSelectedLocations([]);
  };
  const checkDomains = async ({ state, city, data }) => {
    const payload = data?.map((item) => ({
      state: item?.state,
      cities: [...new Set(item?.cities?.map(({ name }) => name))],
    }));
    const states = [...payload];
    let domains = [];
    for (const item of states) {
      try {
        const res = await api.post(
          "/api/domains/domain_list_for_city",
          {
            industry_id: allIndustries?.find(({mongo_id})=>mongo_id===editingRecord?.industries[0])?.mongo_id,
            state: item?.state,
            city: item?.cities,
          }
        );
        if (res.status === 200) {
          domains = [
            ...domains,
            { state: res.data.state, domains: res.data.records },
          ];
        } else {
          toast.error("Getting an error");
        }
      } catch (err) {
        toast.error("Getting an error");
        console.log("🚀 ~ checkDomains ~ err:", err);
      }
    }
    setDomains(domains);
  };

  // const findDuplicateAliases = () => {
  //   const allAliases = [];
  //   const duplicateAliases = {};

  //   newData.forEach((state) => {
  //     state.cities.forEach((city) => {
  //       const cityAliases = city.alias;

  //       // Check for duplicates in city aliases
  //       const duplicates = cityAliases.filter(
  //         (alias, index) => cityAliases.indexOf(alias) !== index
  //       );

  //       // Check for duplicates across the entire newData array
  //       const allAliasDuplicates = cityAliases.filter(
  //         (alias) => allAliases.indexOf(alias) !== -1
  //       );

  //       // Collect duplicates in the result object
  //       if (duplicates.length > 0 || allAliasDuplicates.length > 0) {
  //         const aliasDuplicates = duplicates.concat(allAliasDuplicates);
  //         aliasDuplicates.forEach((duplicate) => {
  //           if (!duplicateAliases[duplicate]) {
  //             duplicateAliases[duplicate] = [];
  //           }
  //           duplicateAliases[duplicate].push({ state: state.state, city: city.name });
  //         });
  //       }

  //       // Add city aliases to the allAliases array
  //       allAliases.push(...cityAliases);
  //     });
  //   });

  //   return duplicateAliases;
  // };

  // Call the function
  // const duplicate_alias = findDuplicateAliases();
  return (
    <>
      <div className="min-h-screen bg-[#f5f5f5] w-[calc(100%-225px)] z-[100] fixed top-0 left-[225px] ">
        {isMapView && (
          <JobsOnMap onCancelModal={cancelFormHandler} records={mapRecord} />
        )}
        {/* {domains?.totalItems ? (
          <ViewDomainModal
            domains={domains}
            onCloseModal={() => setDomains(null)}
          />
        ) : null} */}
        <div className="overflow-y-auto h-[calc(100vh-50px)] w-full">
          <div className="flex items-center justify-start w-full text-white py-2.5 px-2 bg-[#052A42] shadow-client overflow-y-aut">
            <div className="w-1/2">
              <Breadcrumbs
                breadcrumb={`/settings/vendors/${
                  vendorDetail?.first_name ? vendorDetail?.first_name : ""
                } ${vendorDetail?.last_name ? vendorDetail?.last_name : ""}`}
              />
            </div>
            <h4 className="text-cente w-1/2">{modalTitle}</h4>
          </div>
          {industryModal ? (
            <IndustriesModal
              onCloseModal={() => setIndustryModal(false)}
              vendorDetail={vendorDetail}
              formik={formik}
              allIndustries={allIndustries}
            />
          ) : null}
          {copyZipModal ? (
            <CopyZipsModal
              onCloseModal={() => setCopyZipModal(false)}
              assigedZips={selectedLocations}
              handleChangeIndustry={handleChangeIndustry}
              searchedLocation={searchedLocation}
              setSearchedLocation={setSearchedLocation}
              groupedOptions={groupedOptions}
            />
          ) : null}
          {locationLoader || isLoading ? <DotsLoader /> : null}
          <div className="flex">
            <div className="p-3 rounded-3xl shadow-[#D9D9D9] w-full">
              <h5 className=" mb-3 text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] font-semibold">
                Assign Locations to vendor{" "}
                <span className="text-primary-100 font-bold font-pop">{`${
                  vendorDetail?.first_name ? vendorDetail?.first_name : ""
                } ${
                  vendorDetail?.last_name ? vendorDetail?.last_name : ""
                }`}</span>{" "}
                and industry{" "}
                <span className="text-primary-100 font-bold font-pop">
                  {
                    allIndustries?.find(
                      ({ mongo_id }) => mongo_id === editingRecord?.industries[0]
                    )?.industry_name
                  }
                </span>
              </h5>
              <div className="flex justify-between items-center mb-2 bg-white shadow-md !p-3">
                {copyFrom ? (
                  <div className="flex w-1/2 ">
                    <h5 className="text-[#343434] font-semibold">
                      Copy Zip codes from "
                      <span className="text-primary-100 font-bold font-pop">
                        {
                          allIndustries?.find(({ id }) => id === copyFrom)
                            ?.industry_name
                        }
                      </span>
                      "
                    </h5>
                    {selectedLocations?.length ? (
                      <Button
                        text={"Copy Selected Zips"}
                        className="ml-2 !bg-[#052A42]"
                        onClick={() => setCopyZipModal(true)}
                        variant="btn_submit"
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="flex w-1/2">
                    {editingRecord?.id ? (
                      <Dropdown
                        value={formik.values?.industries[0]}
                        onChange={(e) => handleChangeIndustry(e.value)}
                        options={groupedOptions}
                        optionLabel="label"
                        optionValue="value"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        className="w-full md:w-1/2"
                        placeholder="Select an industry"
                      />
                    ) : null}
                  </div>
                )}

                {copyFrom ? (
                  <span
                    className="text-blue-600 hover:underline cursor-pointer font-semibold font-pop"
                    onClick={handleCancelCopy}
                  >
                    Cancel copy process?
                  </span>
                ) : (
                  <span
                    className="text-blue-600 hover:underline cursor-pointer font-semibold font-pop"
                    onClick={() => setCopyFrom(formik.values.industries[0])}
                  >
                    Do you want to copy these zip codes to another industry?
                  </span>
                )}
              </div>
              <div className="">
                <h5 className="text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] flex flex-row justify-between items-center">
                  Search Zip Codes
                  {/* <div
                    className="cursor-pointer"
                    // onClick={() => setSearchZips(!searchZips)}
                  >
                    {searchZips ? (
                      <FaChevronDown className="fas fa-times cursor-pointer" />
                    ) : (
                      <FaChevronDown className="fas fa-times cursor-pointer" />
                    )}
                  </div> */}
                </h5>
                <div className="flex flex-col w-full bg-white !p-5 shadow-md mb-3">
                  <div className="flex flex-row justify-between items-center mb-2">
                    <div className="w-[80%] grid grid-cols-3 gap-x-3 bg-white">
                      <div className="flex flex-row justify-between items-center w-full">
                        {array?.map((val, index) => {
                          return (
                            <button
                              className={`flex justify-center items-center w-20 h-[38px] font-pop font-medium rounded mr-1 text-sm ${
                                val.value === singlePayload.type
                                  ? "bg-primary-100 text-white"
                                  : "bg-[#d8d8d8] text-black"
                              }`}
                              key={index}
                              onClick={() => {
                                setSinglePayload({
                                  ...singlePayload,
                                  type: val.value,
                                  locations: [],
                                });
                                setCityTags([]);
                              }}
                            >
                              {val?.label}
                            </button>
                          );
                        })}
                      </div>

                      <div className="flex space-x-2">
                        <input
                          placeholder="Radius in Miles"
                          onChange={handleMilesChange}
                          className="placeholder:text-[#6c757d] placeholder:font-medium bg-white w-1/2 !border border-[#ced4da] rounded-md py-2 px-2 shadow-none sm:text-sm"
                          value={miles}
                        />
                        {singlePayload?.type === "city" ||
                        singlePayload?.type === "city_alias" ? (
                          <div className="w-full">
                            <Select
                              name="state"
                              placeholder="Select State"
                              onChange={(e) => {
                                setCityTags([]);
                                setSinglePayload({
                                  ...singlePayload,
                                  state_id: e.id,
                                  locations: [],
                                });
                              }}
                              options={states}
                              valueKey="value"
                              labelKey="label"
                              className="w-full"
                              menuPosition="fixed"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              value={
                                singlePayload?.state_id
                                  ? {
                                      id: singlePayload.state_id,
                                      name: states?.find(
                                        ({ id }) =>
                                          id === singlePayload.state_id
                                      )?.name,
                                    }
                                  : null
                              }
                            />
                          </div>
                        ) : null}
                      </div>

                      {singlePayload?.type === "city" ? (
                        <div className="w-full">
                          <MultiSelect
                            placeholder="Select Cities"
                            options={cities}
                            optionLabel="name"
                            optionValue="name"
                            filter
                            maxSelectedLabels={0}
                            value={singlePayload?.locations}
                            onChange={(e) => {
                              setCityTags([...e.value, ...invalidCity]);
                              setSinglePayload({
                                ...singlePayload,
                                locations: e.value,
                              });
                            }}
                            className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                          />
                        </div>
                      ) : singlePayload?.type === "state" ? (
                        <div className="w-full flex flex-col">
                          <MultiSelect
                            placeholder="Select States"
                            options={states}
                            optionLabel="name"
                            optionValue="name"
                            filter
                            maxSelectedLabels={0}
                            value={singlePayload?.locations}
                            onChange={(e) =>
                              setSinglePayload({
                                ...singlePayload,
                                locations: e.value,
                              })
                            }
                            className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="flex justify-end w-auto">
                      {searchedLocation?.length > 0 ? (
                        <button
                          className="flex items-center justify-center border rounded h-[38px] font-semibold bg-red-600 !bg-[#F86526] text-white p-2 ml-2"
                          onClick={clearAll}
                        >
                          Clear all
                        </button>
                      ) : null}
                      <button
                        className="flex items-center justify-center border rounded h-[38px] font-semibold bg-[#7CCB0A] text-white p-2 ml-2"
                        onClick={FilterHandler}
                      >
                        Search
                      </button>
                    </div>
                  </div>

                  {singlePayload?.type === "zip" ? (
                    <TagsInput
                      tags={
                        singlePayload?.type === "zip"
                          ? singlePayload?.locations
                          : []
                      }
                      setTags={(tag) => {
                        setSinglePayload({
                          ...singlePayload,
                          locations: tag,
                        });
                      }}
                      placeholder="Add new zip"
                      tagBg={"!bg-[#d8d8d8] !text-black"}
                    />
                  ) : null}
                  {singlePayload?.type === "city" ||
                  singlePayload?.type === "city_alias" ? (
                    <div className="flex flex-row gap-x-2">
                      <TagsInput
                        tags={
                          singlePayload?.type === "city_alias"
                            ? singlePayload?.locations
                            : cityTags
                        }
                        setTags={(tag) => {
                          if (singlePayload?.state_id) {
                            if (singlePayload?.type === "city") {
                              const newTags = [
                                ...new Set(
                                  tag?.map((item) => item?.toUpperCase())
                                ),
                              ];
                              setCityTags(
                                newTags?.map((item) => item?.toUpperCase())
                              );
                              handleValidate(
                                newTags?.map((item) => item?.toUpperCase())
                              );
                            } else if (singlePayload.type === "city_alias") {
                              setSinglePayload({
                                ...singlePayload,
                                locations: tag,
                              });
                            }
                          } else {
                            toast.error("Please select a state");
                          }
                        }}
                        placeholder={`Add new ${singlePayload?.type?.replace(
                          "_",
                          " "
                        )}`}
                        tagBg={"!bg-[#d8d8d8] !text-black"}
                      />
                    </div>
                  ) : null}
                  {singlePayload.type === "city" &&
                  (singlePayload?.locations?.length > 0 ||
                    invalidCity?.length > 0) ? (
                    <div className="flex flex-row">
                      <div className="flex flex-col w-full mt-2">
                        <small>
                          Valid Locations: {singlePayload?.locations?.length}
                        </small>
                        <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
                          {singlePayload?.locations?.map((val, index) => {
                            return (
                              <div
                                className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between !bg-[#7CCB0A] !text-white !mr-2 my-0.5 h-[26.39px]"
                                key={index}
                              >
                                {val}
                                <button className="border-0 bg-transparent ps-auto pe-0">
                                  <MdCancel
                                    size={20}
                                    className="ml-1 cursor-pointer text-white"
                                    onClick={() => {
                                      setSinglePayload({
                                        ...singlePayload,
                                        locations: [
                                          ...singlePayload.locations?.filter(
                                            (tag) => tag !== val
                                          ),
                                        ],
                                      });
                                      setCityTags([
                                        ...cityTags?.filter(
                                          (tag) => tag !== val
                                        ),
                                      ]);
                                    }}
                                  />
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col w-full mt-2 ml-2">
                        <div className="flex felx-row justify-between">
                          <small>
                            Invalid Locations: {invalidCity?.length}
                          </small>
                          <small>
                            <small
                              onClick={copyInvalidData}
                              className="cursor-pointer hover:underline text-blue-600"
                            >
                              Copy
                            </small>
                            <small
                              onClick={() => {
                                setCityTags(
                                  cityTags.filter(
                                    (obj) => !invalidCity.includes(obj)
                                  )
                                );
                                setInvalidCity([]);
                              }}
                              className="ml-2 cursor-pointer hover:underline text-blue-600"
                            >
                              Clear
                            </small>
                          </small>
                        </div>

                        <div className="form-control h-auto d-inline-flex flex-wrap min-h-[44.38px]">
                          {invalidCity?.map((val, index) => {
                            return (
                              <div
                                className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between !bg-[#F86526] !text-white !mr-2 my-0.5 h-[26.39px]"
                                key={index}
                              >
                                {val}
                                <button className="border-0 bg-transparent ps-auto pe-0">
                                  <MdCancel
                                    size={20}
                                    className="ml-1 cursor-pointer text-white"
                                    onClick={() => {
                                      setCityTags((prevState) =>
                                        prevState.filter((item) => item !== val)
                                      );
                                      setInvalidCity((prevState) =>
                                        prevState.filter((item) => item !== val)
                                      );
                                    }}
                                  />
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* {searchZips ? (
                  
                ) : null} */}
              </div>

              <div className="overflow-y-auto h-[calc(100vh-48vh)]">
                {newData?.length > 0 ? (
                  <div>
                    <h5 className="text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] flex flex-row justify-between items-center">
                      Already assigned Locations
                      <div
                        className="cursor-pointer"
                        onClick={() => setAssignedLocation(!assignedLocations)}
                      >
                        {assignedLocations ? (
                          <FaChevronDown className="fas fa-times cursor-pointer" />
                        ) : (
                          <FaChevronUp className="fas fa-times cursor-pointer" />
                        )}
                      </div>
                    </h5>
                    {assignedLocations ? (
                      <div className="mx-auto overflow-y-auto">
                        <div className="flex items-center justify-between bg-primary-100 w-[calc(100%-32px)] mb-2">
                          <span className="flex text-lg px-2 py-1 font-bold font-pop mb-2 bg-primary-100 text-white w-fit items-center">
                            {`Total Selected States: ${
                              newData?.length
                            } | Total Selected Cities: ${getTotalCities()} | Total Selected Zips: ${getTotalZips()} | Total Selected Alias: ${getTotalAlias()}`}{" "}
                          </span>
                          {copyFrom ? (
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                e.target.checked
                                  ? setSelectedLocations([...newData])
                                  : setSelectedLocations([])
                              }
                              className={`form-checkbox h-6 w-6 text-[#052A42] rounded-sm focus:ring-0 cursor-pointer mr-[4.5rem]`}
                              checked={
                                selectedLocations?.length === newData?.length
                              }
                            />
                          ) : null}
                        </div>

                        {newData?.map((state, state_index) => {
                          return (
                            <div className="flex flex-row justify-between gap-x-3">
                              <details
                                className="mb-3 bg-white w-full"
                                key={state_index}
                              >
                                <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between !border border-[#d8d8d8] rounded hover:cursor-pointer bg-[#f0f0f0] hover:bg-gray-300 py-2 px-1">
                                  {`${state_index + 1}. State: ${
                                    state.state
                                  } | Total Selected Cities: ${
                                    state?.cities?.length
                                  } | Total Selected Zips: ${subTotalofZips(
                                    state?.cities
                                  )} | Total Selected Alias: ${subTotalofAlias(
                                    state?.cities
                                  )}`}
                                  <div className="flex items-center">
                                    {copyFrom ? (
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          onSingleSelect({
                                            checked: e.target.checked,
                                            data: state,
                                          })
                                        }
                                        className={`form-checkbox h-6 w-6 text-[#052A42] rounded-sm focus:ring-0 cursor-pointer mr-2`}
                                        checked={selectedLocations?.find(
                                          (data) =>
                                            data?.state === state.state &&
                                            data?.cities?.length ===
                                              state?.cities?.length
                                        )}
                                      />
                                    ) : null}

                                    <button
                                      className="outline-none border-none bg-transparent p-1 text-menu hover:text-gray-600"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        openMapHandler(state);
                                      }}
                                    >
                                      <img
                                        src={map_pin}
                                        alt="map_icon"
                                        className="w-5"
                                      />
                                    </button>
                                    <span className="outline-none border-none bg-transparent p-1 text-menu hover:text-gray-600">
                                      <FaCaretDown size={20} />
                                    </span>
                                  </div>
                                </summary>
                                <div className="my-4 px-4">
                                  <div className="grid grid-cols-1">
                                    {state.cities.map((city, index) => (
                                      <div
                                        key={index}
                                        className="rounded-md mb-2"
                                      >
                                        <div className="px-2 bg-[#E9F3FB] rounded text-lg font-semibold font-pop flex justify-between border border-[#D2DBE1]">
                                          <span>
                                            {`${index + 1}. City: ${
                                              city.name
                                            } | Total Selected Zips: ${
                                              city?.zips?.length
                                            } | `}
                                            <span className="text-base font-medium">
                                              {domains
                                                ?.find(
                                                  (domain) =>
                                                    domain?.state ===
                                                    state?.state
                                                )
                                                ?.domains[city?.name]?.map(
                                                  (domain, index) => (
                                                    <a
                                                      className="items-center text-black cursor-pointer hover:underline no-underline"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      href={`https://${domain}`}
                                                      key={index}
                                                    >
                                                      {domain}
                                                      {index <
                                                        domains?.find(
                                                          (domain) =>
                                                            domain?.state ===
                                                            state?.state
                                                        )?.domains[city?.name]
                                                          ?.length -
                                                          1 && ", "}
                                                    </a>
                                                  )
                                                ) || (
                                                <span className="text-base font-medium text-red-600">
                                                  {domains?.length
                                                    ? "Not Purchased"
                                                    : "Loading..."}
                                                </span>
                                              )}
                                            </span>
                                          </span>
                                          <div>
                                            <button
                                              className="border-0 bg-transparent ps-auto pe-0"
                                              title="Remove city"
                                            >
                                              <MdCancel
                                                size={20}
                                                className="ml-1 cursor-pointer text-menu hover:text-gray-600 text-[#1C3D52]"
                                                onClick={() =>
                                                  handleRemoveCity(city.name)
                                                }
                                              />
                                            </button>
                                          </div>
                                        </div>

                                        <div className="p-2">
                                          <div className="flex flex-wrap">
                                            {Array.isArray(city.zips) ? (
                                              city.zips.map((zip, index) => (
                                                <div
                                                  className="badge flex justify-between items-center !bg-[#d8d8d8] !text-black !mr-2 my-0.5 h-[26.39px]"
                                                  key={index}
                                                >
                                                  {zip}
                                                  <button className="border-0 bg-transparent ps-auto pe-0">
                                                    <MdCancel
                                                      size={20}
                                                      className="ml-1 cursor-pointer"
                                                      onClick={() =>
                                                        handleRemoveZip2(zip)
                                                      }
                                                    />
                                                  </button>
                                                </div>
                                              ))
                                            ) : (
                                              <div className="badge me-1 pe-1 justify-content-between border-b me-1 pe-1 justify-content-between !bg-white !text-black !mr-2 my-0.5 h-[26.39px]">
                                                {city?.zips}
                                                <button className="border-0 bg-transparent ps-auto pe-0">
                                                  <RxCrossCircled
                                                    size={20}
                                                    className="ml-1 cursor-pointer text-black"
                                                  />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </details>
                              <FaRegTrashAlt
                                className="text-[#F86526] cursor-pointer"
                                size={20}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleRemoveState(state.state);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div>
                  <h5 className="text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] flex flex-row justify-between items-center">
                    New Searched Locations
                  </h5>
                  {searchedLocation?.length > 0 && !locationLoader ? (
                    <>
                      {searchedLocation?.map((data, index) => {
                        return data?.map((log, index2) => {
                          const record = log?.data;
                          const names = record?.cities
                            ?.map((item) => {
                              return item.name;
                            })
                            ?.join(", ");
                          const state = record?.states
                            ?.map((item) => item.name)
                            ?.join(", ");
                          const zips = record?.zips
                            ?.map((item) => item.zip)
                            ?.join(", ");
                          const alias = record?.cities
                            ?.map((item) => item.alias)
                            ?.join(", ");
                          return (
                            <div className="flex flex-row justify-between gap-x-3">
                              <details
                                className="mb-3 w-full"
                                // open={!collapseAll}
                                key={index}
                                id={index}
                              >
                                <summary
                                  className={`flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border border-[#d8d8d8] rounded hover:cursor-pointer bg-[#f0f0f0] hover:bg-gray-300 py-2 px-1`}
                                >
                                  <span className="text-xl font-bolder">
                                    <span className="mr-3">{index + 1}</span>
                                    {`Search By: ${
                                      array?.find(
                                        ({ value }) =>
                                          value === log?.payload?.type
                                      )?.label
                                    } ${
                                      log?.payload?.state_id ||
                                      log?.payload?.type === "state"
                                        ? `| State: ${
                                            log?.payload?.type === "city" ||
                                            log?.payload?.type === "city_alias"
                                              ? states?.find(
                                                  ({ id }) =>
                                                    id ===
                                                    log?.payload?.state_id
                                                )?.name
                                              : log?.payload?.type === "state"
                                              ? log?.payload
                                                ? log?.payload?.locations
                                                : ""
                                              : ""
                                          }`
                                        : ""
                                    } ${
                                      log?.payload?.radiusInMeters
                                        ? `| Radius: ${
                                            log?.payload?.radiusInMeters / 1609
                                          } Miles`
                                        : ""
                                    } | Location: ${
                                      log?.payload
                                        ? log?.payload?.locations
                                        : ""
                                    } `}
                                  </span>
                                  <div className="flex flex-row items-center">
                                    <span className="text-xl font-bolder">
                                      {`Zips: ${
                                        record?.zips?.length
                                          ? record?.zips?.length
                                          : 0
                                      } | States: ${
                                        record?.states?.length
                                          ? record?.states?.length
                                          : 0
                                      } | Cities: ${
                                        record?.cities?.length
                                          ? record?.cities?.length
                                          : 0
                                      } | Alias: ${
                                        record?.cities?.length
                                          ? Array?.from(
                                              new Set(
                                                record?.cities?.flatMap(
                                                  (item) =>
                                                    item?.alias?.split(",")
                                                )
                                              )
                                            )?.length
                                          : 0
                                      }`}
                                    </span>
                                    <div>
                                      {log?.is_locked ? (
                                        <button className="outline-none border-none bg-transparent p-1">
                                          <HiLockClosed
                                            className="my_navIcon"
                                            size={18}
                                          />
                                        </button>
                                      ) : (
                                        <span className="outline-none border-none bg-transparent p-1">
                                          <FaCaretDown
                                            size={20}
                                            className="ml-3"
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </summary>

                                <div className=" m-2 bg-slate !my-3 border rounded p-1">
                                  <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
                                    <div className="w-2/6 px-2">
                                      <textarea
                                        className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                                        value={zips}
                                        rows={3}
                                        readOnly
                                      />
                                    </div>
                                    <div className="w-2/6 px-2">
                                      <textarea
                                        className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                                        value={state}
                                        rows={3}
                                        readOnly
                                      />
                                    </div>
                                    <div className="w-2/6 px-2">
                                      <textarea
                                        className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                                        value={names}
                                        rows={3}
                                        readOnly
                                      />
                                    </div>
                                    <div className="w-2/6 px-2">
                                      <textarea
                                        className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                                        value={alias}
                                        rows={3}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </details>
                              <FaRegTrashAlt
                                className="text-[#F86526] cursor-pointer"
                                size={20}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteAtIndex(log);
                                }}
                              />
                            </div>
                          );
                        });
                      })}
                    </>
                  ) : locationLoader ? (
                    <div className="w-full flex justify-center items-center">
                      <Loader />
                    </div>
                  ) : (
                    <div className="w-full text-center">
                      <span className="font-semibold font-pop text-lg text-center text-[#F86526]">
                        No record found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="z-[10] w-full absolute flex justify-center bottom-0 p-2 bg-[#f5f5f5] border-t">
            <Button
              text="Cancel"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_cancel"
            />
            <Button
              text={"Submit"}
              className="mr-2"
              onClick={formik.handleSubmit}
              variant="btn_submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateLocationModal;
