import React, { useState } from "react";
import { Button, Loader } from "../../components";
import { logo_towingwiz, logo_logical, new_login_bg } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { login, reset, vendor_login } from "../../features/authSlice";
import { browserName, osName } from "react-device-detect";
import { NavLink } from "react-router-dom";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import LoginLogicalCRM from "./LoginLogicalCRM";
import LoginTowingWiz from "./LoginTowingWiz";

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, message, errors } = useSelector(
    (state) => state.auth
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState("user");
  const [showPassword, setShowPassword] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    const userData = { username, password };
    const header = {
      browser: browserName,
      os: osName,
      mac_address: "mac Address",
    };

    if (loginType === "user") {
      dispatch(login({ userData, header }));
    } else {
      dispatch(vendor_login({ userData, header }));
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const hostname = true;
  const hostname = window.location.hostname === "towingwiz.com";

  return hostname ? (
    <LoginTowingWiz
      InputField={InputField}
      Divider={Divider}
      setLoginType={setLoginType}
      submitHandler={submitHandler}
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      loginType={loginType}
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      reset={reset}
    />
  ) : (
    <LoginLogicalCRM
      InputField={InputField}
      Divider={Divider}
      setLoginType={setLoginType}
      submitHandler={submitHandler}
      username={username}
      setUsername={setUsername}
      password={password}
      setPassword={setPassword}
      loginType={loginType}
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      reset={reset}
    />
  );
};

const InputField = ({
  label,
  placeholder,
  type = "text",
  value,
  onChange,
  icon,
  errors,
}) => (
  <div className="mt-4 lg:mt-10">
    <label className="mb-1">{label}</label>
    <div className="flex items-center w-full bg-gray-100 rounded-md">
      <input
        placeholder={placeholder}
        type={type}
        className="flex-1 outline-none py-2 pl-4 bg-gray-100 rounded-md"
        value={value}
        onChange={onChange}
        name={label.toLowerCase()}
        errors={errors}
      />
      <div className="text-lg text-white bg-primary-100 py-2.5 px-3 rounded-md">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  </div>
);

const Divider = () => (
  <div className="my-5 flex justify-between text-center items-center text-gray-400 w-full gap-4">
    <span className="bg-gray-300 flex-1 h-[1px] lg:w-40"></span>
    OR
    <span className="bg-gray-300 flex-1 h-[1px] lg:w-40"></span>
  </div>
);

export default Login;
