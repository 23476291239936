import React, { useEffect } from "react";
import { Button } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { get_commission_report } from "../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import {
  get_crm_vendors_drd,
  get_users,
  get_users_drd,
} from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_roles } from "../../features/rolesSlice";
import { MultiSelect } from "primereact/multiselect";
import { get_job_status } from "../../features/jobStatusSlice";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_tags_drd } from "../../features/tagsSlice";
import { Dropdown } from "primereact/dropdown";
import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";
import { toast } from "react-toastify";
import { Button as MUIButton } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaPlus } from "react-icons/fa";
import CustomModal from "./Modal";
import { NumericFormat } from "react-number-format";
const CommissionReport = () => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = React.useState(false);
  const { CRMVendorsDrd } = useSelector((state) => state.users);
  const componentRef = React.useRef(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const { isLoading, commissionReport } = useSelector((state) => state.reports);
  const roles = useSelector((state) => state.roles);
  const jobStatus = useSelector((state) => state.jobStatus);
  const industry = useSelector((state) => state.industry);
  const tags = useSelector((state) => state.tags);
  const { usersDrd } = useSelector((state) => state.users);
  const [searchText, setSearchText] = React.useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const sortedJobStatus = [...(jobStatus?.record?.records || [])].sort((a, b) =>
    a?.name?.localeCompare(b?.name)
  );
  const currentDate = new Date();
  const initialFilters = {
    size: paginationModel.pageSize,
    page: 1,
    job_type: null,
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
    tech_user_id: null,
    job_status_ids: [],
    industry_id: null,
    payment_type: null,
    created_by: [],
    tags: [],
    report_by: "created",
    assigned_type: "all",
    invoice_generated: "",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  console.log(
    "🚀 ~ CommissionReport ~ filters:",
    typeof filters.invoice_generated,
    filters.invoice_generated
  );
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_roles());
    dispatch(get_users_drd());
    dispatch(get_commission_report(filters));
    dispatch(get_job_status({ page: 1, size: 1000, active: 1 }));
    dispatch(get_industry_drd());
    dispatch(get_tags_drd());
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag) => (
      <span
        key={tag._id}
        style={{
          backgroundColor: tag.color,
          margin: "2px",
          color: "#fff",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          fontWeight: 700,
          fontSize: "85%",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tag.name}
      </span>
    ));

    return (
      <div style={{ display: "flex", flexWrap: "wrap", width: "auto" }}>
        {tags}
      </div>
    );
  };
  function checkSameVendorId(array) {
    if (array.length === 0 || !array[0].vendor_id) {
      return false; // No objects in the array
    }

    const firstVendorId = array[0].vendor_id ? array[0].vendor_id._id : null; // Get _id of the first object's vendor_id if not null

    // Check if _id in vendor_id is the same for each object
    return array.every(
      (obj) => obj.vendor_id && obj.vendor_id._id === firstVendorId
    );
  }
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        if (selectedData?.length) {
          const firstVendorId = selectedData[0].vendor_id._id;
          if (firstVendorId !== data.vendor_id._id)
            return toast.error("Vendor are not the same as pre-selected jobs.");
        }
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (job) => job?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      if (!checkSameVendorId(commissionReport?.records))
        return toast.error("Vendors are not same for all jobs");
      const data = commissionReport?.records?.filter(
        ({ vendor_invoice_id }) => !vendor_invoice_id
      );
      const updatedArray = data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (job) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === job?._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            commissionReport?.records?.length &&
            selectedData?.length === commissionReport?.records?.length
          }
          disabled={!commissionReport?.records?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) =>
        params.row.records.vendor_id &&
        !params.row.records.vendor_invoice_id ? (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ) : null,
      disableColumnMenu: true,
    },
    {
      field: "Job #",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/update/${params.row._id}`}
          className="text-blue-700 hover:no-underline"
        >
          {params.row.job_number}
        </a>
      ),
      width: 60,
      sortable: true,
      valueGetter: (params) => params.row.job_number, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
    },
    {
      headerName: "Created By",
      field: "createdBy",
      flex: 1,
      minWidth: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
      sortable: true,
      valueGetter: (params) => params.row.createdAt, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => {
        // Convert the date strings to Date objects for comparison
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        // Compare the date objects for sorting
        return date1 - date2;
      },
    },
    { headerName: "Status", field: "job_status_id" },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      sortable: true,
      valueGetter: (params) => params.row.scheduled_on, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => {
        // Convert the date strings to Date objects for comparison
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        // Compare the date objects for sorting
        return date1 - date2;
      },
    },

    {
      headerName: "Tags",
      field: "tags",
      renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    { headerName: "Closed", field: "is_closed" },
    {
      headerName: "Closed At",
      field: "closed_date",
      flex: 1,
      minWidth: 120,
      cellClassName: "multiline-cell",
      sortable: false,
      valueGetter: (params) => params.row.closed_date, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => {
        // Convert the date strings to Date objects for comparison
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        // Compare the date objects for sorting
        return date1 - date2;
      },
    },
    { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },

    {
      headerName: "Client Type",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },

    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Tech",
      field: "tech_user_id",
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    { headerName: "Service Cost", field: "total", flex: 1, minWidth: 130 },
    {
      headerName: "Parts Charge",
      field: "parts_charge",
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: "Net Amount",
      field: "net_amount",
      flex: 1,
      minWidth: 130,
    },
    // { headerName: "GOA Price", field: "goa_price", flex: 1, minWidth: 100 },
    // { headerName: "Refund", field: "refund_amount", flex: 1, minWidth: 100 },
    { headerName: "Tech Share", field: "tech_fee", flex: 1, minWidth: 100 },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 130,
    },
  ];
  const jobTypeOptions = [
    { value: "closed", label: "Job Closed" },
    { value: "created", label: "Job Created" },
  ];
  const invoiceStatusOptions = [
    { value: 1, label: "Created" },
    { value: 0, label: "Not Created" },
  ];
  const paymentStatusOptions = [
    { value: 1, label: "Paid" },
    { value: 0, label: "Not Paid" },
  ];
  const assignedTypeOptions = [
    { value: "all", label: "All" },
    { value: "assigned", label: "Assigned" },
    { value: "not_assigned", label: "Not Assigned" },
  ];
  const clientTypeOptions = [
    { value: "COD", label: "C.O.D" },
    { value: "Insurance", label: "Insurance" },
  ];
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    if (roles?.record?.length > 0) {
      dispatch(get_crm_vendors_drd());
    }
    // eslint-disable-next-line
  }, [roles?.record?.length]);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_commission_report({
        ...filters,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags?.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  const getDataforExport = async () => {
    try {
      const res = await dispatch(get_commission_report(filters));
      if (res.payload?.status === 200) {
        const data = res.payload.data?.records;
        if (data?.length > 0) {
          if (res?.payload?.data?.totalItems > paginationModel?.pageSize) {
            const response = await dispatch(
              get_commission_report({
                ...filters,
                size: res?.payload?.data?.totalItems,
              })
            );
            if (response.payload?.status === 200) {
              exportToExcel(response.payload.data?.records);
            }
          } else {
            exportToExcel(data);
          }
        } else {
          return toast.error("No data found");
        }
      }
    } catch (err) {}
  };
  const exportToExcel = (data) => {
    try {
      const workbook = utils.book_new();
      const sheetName = "Commission Report";
      const headings = [
        "Sr.",
        "Job Number",
        "Created By",
        "Created At",
        "Status",
        "Scheduled Date",
        "Closed",
        "Closed At",
        "Industry",
        "Client Type",
        "Address",
        "Tech",
        "Total",
        "Tech Fee",
        "Goa Price",
        "Refund",
        "Tech Profit",
        "Company Profit",
      ];
      const formattedData = data?.map((item, index) => [
        index + 1,
        item?.job_number,
        item?.createdBy?.username,
        item?.createdAt
          ? dayjs(item?.createdAt).format("ddd, MMM D, YYYY h:mm A")
          : "",
        item?.job_status_id?.name,
        item?.scheduled_on
          ? dayjs(item?.scheduled_on).format("ddd, MMM D, YYYY h:mm A")
          : "",
        item?.is_closed ? "Yes" : "No",
        item?.closed_date
          ? dayjs(item?.closed_date).format("ddd, MMM D, YYYY h:mm A")
          : "",
        item?.industry_id?.name,
        item?.payment_type,
        item?.complete_pickup_address,
        item?.vendor_id?.first_name,
        item?.total ? item?.total : 0,
        item?.tech_fee ? item?.tech_fee : 0,
        item?.goa_price ? item?.goa_price : 0,
        item?.refund_amount ? item?.refund_amount : 0,
        item?.company_profit ? item?.company_profit : 0,
        // item.tags.map(tag => tag.name).join(', '),
      ]);

      const worksheetData = [headings, ...formattedData];
      const totalSum = data.reduce((sum, item) => sum + item.total, 0);
      const totalTechfee = data.reduce((sum, item) => sum + item.tech_fee, 0);
      const totalGOA = data.reduce((sum, item) => sum + item.goa_price, 0);
      const totalRefund = data.reduce(
        (sum, item) => sum + item.refund_amount,
        0
      );
      const totalTechPro = data.reduce((sum, item) => sum + item.tech_fee, 0);
      const totalCompPro = data.reduce(
        (sum, item) => sum + item.company_profit,
        0
      );

      // Add the sum row at the bottom
      const sumRow = [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        totalSum?.toLocaleString(),
        totalTechfee.toLocaleString(),
        totalGOA.toLocaleString(),
        totalRefund.toLocaleString(),
        totalTechPro.toLocaleString(),
        totalCompPro.toLocaleString(),
      ];
      worksheetData.push(sumRow);
      const worksheet = utils.aoa_to_sheet(worksheetData);
      utils.book_append_sheet(workbook, worksheet, sheetName);

      writeFile(workbook, "Commission Report.xlsx");
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:358 ~ exportToExcel ~ err:", err);
    }
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            {/* <span
              className="text-blue-600 hover:underline cursor-pointer"
              onClick={() => setShowSelectedData(true)}
            >
              Show
            </span> */}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        {selectedData?.length ? (
          <MUIButton
            variant="text"
            onClick={() => setIsModal(true)}
            startIcon={<FaPlus size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className="!text-[#042a42]"
          >
            Create Invoice
          </MUIButton>
        ) : null}
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <div className="container-fluid">
        {isModal ? (
          <CustomModal
            onClose={() => setIsModal(false)}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            filters={filters}
            paginationModel={paginationModel}
          />
        ) : null}
        <ReportHead route="Reports > Commission Report" heading="Login IP" />
        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-9">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
              <div className="md:col-span-4 !px-0.5">
                <Dropdown
                  value={filters.job_type}
                  onChange={(e) =>
                    setFilters({ ...filters, job_type: e.value })
                  }
                  options={jobTypeOptions}
                  optionLabel="label"
                  placeholder="Select Job Type"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  showClear
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.tech_user_id}
                  onChange={(e) =>
                    setFilters({ ...filters, tech_user_id: e.value })
                  }
                  options={CRMVendorsDrd ? CRMVendorsDrd : []}
                  optionLabel={(option) =>
                    `${option?.first_name || ""} ${option?.last_name || ""}`
                  }
                  optionValue="_id"
                  placeholder="Select Tech"
                  filter
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  showClear
                  showFilterClear
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  placeholder="Job Status"
                  options={sortedJobStatus}
                  optionLabel="name"
                  optionValue="_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.job_status_ids}
                  onChange={(e) =>
                    setFilters({ ...filters, job_status_ids: e.value })
                  }
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.industry_id}
                  onChange={(e) =>
                    setFilters({ ...filters, industry_id: e.value })
                  }
                  options={industry?.industryDrd}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Industry"
                  filter
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  showClear
                  showFilterClear
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.payment_type}
                  onChange={(e) =>
                    setFilters({ ...filters, payment_type: e.value })
                  }
                  options={clientTypeOptions}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Client Type"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  showClear
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  value={filters.created_by}
                  onChange={(e) =>
                    setFilters({ ...filters, created_by: e.value })
                  }
                  options={usersDrd}
                  optionLabel="first_name"
                  optionValue="_id"
                  placeholder="Created By"
                  filter
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <MultiSelect
                  placeholder="Tags"
                  options={tags?.tagsDrd}
                  optionLabel="name"
                  optionValue="_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.tags}
                  onChange={(e) => setFilters({ ...filters, tags: e.value })}
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.assigned_type}
                  onChange={(e) =>
                    setFilters({ ...filters, assigned_type: e.value })
                  }
                  options={assignedTypeOptions}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Tech"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>

              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.invoice_generated}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ CommissionReport ~ e:",
                      e.value,
                      typeof e.value
                    );
                    setFilters({ ...filters, invoice_generated: e.value });
                  }}
                  options={invoiceStatusOptions}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Invoice Status"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-4 !px-0.5 !mb-3">
                <Dropdown
                  value={filters.invoice_paid}
                  onChange={(e) =>
                    setFilters({ ...filters, invoice_paid: e.value })
                  }
                  options={paymentStatusOptions}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Select Payment Status"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="md:col-span-5 !px-0.5">
                <Button
                  text="Search"
                  variant="btn_submit"
                  onClick={() => {
                    console.log(
                      typeof filters?.invoice_generated,
                      filters.invoice_generated
                    );
                    setSelectedData([]);
                    dispatch(get_commission_report(filters));
                  }}
                />
                <Button
                  className="ml-2"
                  text="Reset"
                  variant="btn_cancel"
                  onClick={() => {
                    setFilters(initialFilters);
                    dispatch(get_commission_report(initialFilters));
                  }}
                />
                <button
                  className={`ml-2 btn btn-secondary bg-[#565e64] h-[36px] rounded-0`}
                  type="button"
                  onClick={getDataforExport}
                >
                  Export Filtered
                </button>
              </div>
            </div>
          </div>
          <div className="md:col-span-3 !px-0.5">
            <Picker
              filterData={filters}
              setFiltersData={setFilters}
              dropdown_menu={[
                { label: "Closed", value: "closed" },
                { label: "Created", value: "created" },
                { label: "Not Closed", value: "not_closed" },
              ]}
              dropdown_label="Report by"
              valueKey={"report_by"}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 gap-x-2 justify-betwee mx-0.5">
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Jobs</span>
            <span className="text-base font-medium font-pop">
              {commissionReport?.summary?.totalJobs
                ? commissionReport?.summary?.totalJobs
                : 0}
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Service Cost</span>
            <span className="text-base font-medium font-pop">
              <NumericFormat
                displayType="text"
                value={(
                  Math.round((commissionReport?.summary?.total || 0) * 100) /
                  100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Parts charge</span>
            <span className="text-base font-medium font-pop">
              <NumericFormat
                displayType="text"
                value={(
                  Math.round(
                    (commissionReport?.summary?.parts_charge || 0) * 100
                  ) / 100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Net Amount</span>
            <span className="text-base font-medium font-pop">
              <NumericFormat
                displayType="text"
                value={(
                  Math.round(
                    ((commissionReport?.summary?.total || 0) -
                      (commissionReport?.summary?.parts_charge || 0)) *
                      100
                  ) / 100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Tech Share </span>
            <span className="text-base font-medium font-pop">
              <NumericFormat
                displayType="text"
                value={(
                  Math.round((commissionReport?.summary?.tech_fee || 0) * 100) /
                  100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Company Profit</span>
            <span className="text-base font-medium font-pop">
              <NumericFormat
                displayType="text"
                value={(
                  Math.round(
                    ((commissionReport?.summary?.total || 0) -
                      (commissionReport?.summary?.parts_charge || 0) -
                      (commissionReport?.summary?.tech_fee || 0)) *
                      100
                  ) / 100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
            </span>
          </div>
        </div>
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            items={commissionReport?.records?.map((record, index) => {
              const {
                _id,
                job_number,
                complete_pickup_address,
                createdAt,
                createdBy,
                industry_id,
                is_closed,
                job_status_id,
                payment_type,
                refund_amount = 0,
                tags,
                tech_fee = 0,
                vendor_id,
                total = 0,
                goa_price = 0,
                closed_date,
                scheduled_on,
                parts_charge = 0,
              } = record;
              let parent_vendor = CRMVendorsDrd?.find(
                ({ _id }) => _id === vendor_id?.parent_id
              );
              return {
                _id,
                counter: index + 1,
                records: { ...record, _id: _id },
                job_number,
                createdBy: createdBy?.username,
                job_status_id: job_status_id?.name,
                createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
                industry_id: industry_id?.name,
                tech_user_id: `${
                  parent_vendor?.first_name ? parent_vendor?.first_name : ""
                } ${parent_vendor?.last_name ? parent_vendor?.last_name : ""} ${
                  parent_vendor ? " | " : ""
                } 
              ${vendor_id?.company_name ? vendor_id?.company_name : ""}
              ${vendor_id?.company_name ? " | " : ""}
              ${vendor_id?.first_name ? vendor_id?.first_name : ""} ${
                  vendor_id?.last_name ? vendor_id?.last_name : ""
                }`,
                complete_pickup_address,
                tags,
                company_profit: total - parts_charge - tech_fee?.toFixed(2),
                is_closed: is_closed ? "Yes" : "No",
                payment_type,
                refund_amount: refund_amount ? refund_amount : 0,
                tech_fee: tech_fee ? tech_fee : 0,
                total: total ? total : 0,
                goa_price: goa_price ? goa_price : 0,
                net_amount: total - parts_charge,
                parts_charge,
                closed_date: closed_date
                  ? dayjs(closed_date).format("ddd, MMM D, YYYY h:mm A")
                  : "",
                scheduled_on: scheduled_on
                  ? dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A")
                  : "",
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={commissionReport?.totalItems}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
            gridOptions={getRowHeight}
            CustomToolbar={CustomToolbar}
          />
        </div>
        <div className="d-none" />
      </div>
    </>
  );
};

export default CommissionReport;
