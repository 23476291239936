import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useFormik } from "formik";
import { FormInput, FormSelectInput, Modal } from "../../../components";
import errorMessage from "../../../util/errorMessage";
import {
  create_registrar_account,
  update_registrar_account,
} from "../../../features/registrarAccountsSlice";

function AddUpdateRegistrarAccounts({
  editingRecord,
  onCancelForm,
  modalTitle,
}) {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    auth_key: "",
    auth_email: "",
    is_available: "",
    account_id: "",
    token: "",
    email_password: "",
    cloudflare_password: "",
    email_phone: "",
    reconvery_email: "",
    note: "",
    ...(editingRecord && {
      id: editingRecord.id,
      auth_key: editingRecord.auth_key,
      auth_email: editingRecord.auth_email,
      is_available: editingRecord.is_available,
      account_id: editingRecord?.account_id,
      token: editingRecord.token,
      email_password: editingRecord.email_password,
      cloudflare_password: editingRecord.cloudflare_password,
      email_phone: editingRecord.email_phone,
      reconvery_email: editingRecord.reconvery_email,
      note: editingRecord.note,
    }),
  };

  const handleSubmit = async (values) => {
    const action = editingRecord
      ? update_registrar_account
      : create_registrar_account;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload.status === 200 || res.payload.status === 201) {
        toast.success(`Cloudflare ${message} Successfully`);
        onCancelForm();
      } else {
        toast.error(
          res.payload ? res.payload : `Cloudflare couldn't be ${message}`
        );
      }
    } catch (error) {
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "Cloudflare",
        msg: message,
      });
      console.error("~ onSubmit ~ error", error);
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
    >
      <div className="w-full grid grid-cols-2 gap-2">
        <FormInput
          errors={errors}
          name="auth_key"
          label="Auth Key"
          formik={formik}
        />

        <FormInput
          errors={errors}
          name="account_id"
          label="Account Id"
          formik={formik}
        />
        <FormSelectInput
          errors={errors}
          name="is_available"
          label="Availability"
          formik={formik}
          options={[
            { value: 1, label: "Active" },
            { value: 0, label: "Inactive" },
          ]}
          valueProp="value"
          labelProp="label"
        />
        <FormInput
          errors={errors}
          name="auth_email"
          label="Email"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="email_password"
          label="Email Password"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="cloudflare_password"
          label="Cloudflare Password"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="email_phone"
          label="Email Phone"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="recovery_email"
          label="Recovery Email"
          formik={formik}
        />
        <FormInput errors={errors} name="note" label="Note" formik={formik} />
      </div>
    </Modal>
  );
}

export default AddUpdateRegistrarAccounts;
