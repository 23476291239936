import { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../../components/molecules/PageHeader";
import AddCampaignsForm from "./AddCampaignsForm";
import {
  delete_campaigns,
  get_campaigns,
  campaignReset,
} from "../../../features/campaignSlice";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import userPermission from "../../../util/userPermission";
import errorMessage from "../../../util/errorMessage";
import React from "react";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import AddPauseCode from "./PauseCode";
const Campaigns = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.campaigns);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsAdding(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_campaigns(record_id));
        if (res.payload?.status === 200) {
          toast.success("Campaigns deleted");
          setNewRecord(!newRecord);
        } else {
          errorMessage({
            payload: res.payload,
            action: "Campaigns",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Campaigns", msg: "deleted" });
      }
    }
  };

  const openAddFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsAdding(true);
  };
  const openEditFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_campaigns());
    dispatch(get_inbound_group());
    return () => {
      dispatch(campaignReset());
    };
    // eslint-disable-next-line
  }, [newRecord]);
  const campaignFilter = record?.filter(
    (campaign) =>
      campaign?.campaign_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      campaign?.campaign_id?.toLowerCase().includes(searchText.toLowerCase()) ||
      campaign?.dial_method?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Campaign ID", field: "campaign_id", flex: 1 },
    { headerName: "Campaign Name", field: "campaign_name", flex: 1 },
    { headerName: "Dial Method", field: "dial_method", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active === "Y" ? (
          <div>
            {userPermission("Update Compaign") && (
              <FaEdit
                onClick={openEditFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Compaign"
              />
            )}

            {userPermission("Remove Compaign") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.campaign_id)}
                className="my_navIcon"
                title="Delete Campaign"
              />
            )}
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isAdding && (
        <AddCampaignsForm
          editingRecord={editingRecord}
          modalTitle="Add Campaigns"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      {isEditing && (
        <AddPauseCode
          editingRecord={editingRecord}
          modalTitle="Add Campaigns"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      <PageHeader
        route="Call Center > Campaigns"
        heading="Campaigns Listing"
        onClick={openAddFormHandler(0)}
        isAllowed={userPermission("Add Compaign")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={campaignFilter?.map((record, index) => {
            let counter = index + 1;
            let campaign_id = record.campaign_id;
            let campaign_name = record.campaign_name;
            let dial_method = record.dial_method;
            let status = record.active === "Y" ? "Active" : "InActive";
            return {
              counter,
              records: { ...record, _id: campaign_id },
              campaign_id,
              campaign_name,
              dial_method,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default Campaigns;
