import React, { useState, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import DotsLoader from "../../components/atoms/DotsLoader";
import {
  clearAll,
  deleteObject,
  get_geolocations_detail,
  get_geo_location_detail,
  get_valid_locations,
  removeFromValidLoc,
  resetLoc,
  resetValidLoc,
  save_search_logs,
  update_search_logs,
} from "../../features/geoLocSlice";
import "react-bootstrap-tagsinput/dist/bootstrap.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import {
  get_geo_states,
  get_geo_vendors,
  get_geo_vendors_drd,
} from "../../features/GeoVendorsSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { Button, Loader } from "../../components";
import Summary from "./Summary";
import dayjs from "dayjs";
import { HiLockClosed } from "react-icons/hi";
import AssignedDomains from "./AssignedDomains";
import { Dropdown } from "primereact/dropdown";
const GeoLocation = () => {
  const scrollToRecord = (recordId) => {
    const element = document.getElementById(recordId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const refTags = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const editingRecord = location?.state;
  const [assignedDomainModal, setAssignedDomainModal] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [vendorType, setVendorType] = useState("Own");
  const array = [
    { label: "State", value: "state" },
    { label: "City", value: "city" },
    { label: "Zip", value: "zip" },
    { label: "City Alias", value: "city_alias" },
  ];
  let initialValues = {
    vendor_id: null,
    industry_id: null,
    description: "",
    payload: [],
  };
  if (editingRecord) {
    const { id, vendor_id, industry_id, description, payload, summary_counts } =
      editingRecord;
    initialValues = {
      id,
      vendor_id: vendor_id?.id,
      industry_id: industry_id?.id,
      description,
      payload: payload ? JSON.parse(payload) : null,
      summary_counts: summary_counts ? JSON.parse(summary_counts) : null,
    };
  }
  const isUpdating = location.pathname.startsWith(
    "/update/geolocation/locations"
  );
  const [payloads, setPayload] = useState(initialValues);
  const getDetail = async () => {
    if (editingRecord) {
      try {
        const res = await dispatch(get_geolocations_detail(editingRecord?.id));
        if (res?.payload?.status === 200) {
          let pay = [];
          pay = res.payload.data?.results.flatMap((subArray) =>
            subArray.map((element) => ({
              ...pay,
              ...element.payload,
            }))
          );
          setPayload({
            ...payloads,
            payload: pay,
            summary_counts: res.payload.data?.search_log?.summary_counts,
          });
          setSearchedLocation(res.payload.data?.results);
          res.payload.data.forEach((dataGroup, index) => {
            const record = dataGroup[0];
            if (
              record.data &&
              record.data.zips &&
              record.data.zips.length === 0
            ) {
              toast.error(
                `There is no record found for ${`Search By: ${
                  record?.payload?.type
                } ${
                  record?.payload?.state_id ||
                  record?.payload?.payload?.type === "state"
                    ? `| State: ${
                        record?.payload?.type === "city" ||
                        record?.payload?.type === "city_alias"
                          ? vendors.states?.find(
                              ({ id }) => id === record?.payload?.state_id
                            )?.name
                          : record?.payload?.type === "state"
                          ? record?.payload?.location
                            ? record?.payload?.location
                            : ""
                          : ""
                      }`
                    : ""
                } ${
                  record?.payload?.radiusInMeters
                    ? `| Radius: ${
                        record?.payload?.radiusInMeters / 1609
                      } Miles`
                    : ""
                } | Location: ${
                  record?.payload?.locations ? record?.payload?.locations : ""
                } at position ${index + 1} `}`,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  onClick: () => scrollToRecord(index),
                }
              );
            }
          });
        }
      } catch (err) {
        console.error(
          "🚀 ~ file: GeoLocation.jsx:71 ~ React.useEffect ~ err:",
          err
        );
      }
    }
  };
  const cancelFormHandler = () => {
    setAssignedDomainModal(false);
    setDeletedId(null);
  };
  const openFormHandler = (id) => {
    setAssignedDomainModal(true);
    setDeletedId(id);
  };
  React.useEffect(() => {
    if (isUpdating) {
      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRecord?.id]);
  const [state, setState] = useState(
    initialValues?.payload?.locations
      ? initialValues?.payload?.locations?.split(",")
      : []
  );
  const { isLoading, loc, validLoc, locationDetails } = useSelector(
    (state) => state.geoLoc
  );
  const [searchedLocation, setSearchedLocation] = useState([]);
  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const [singlePayload, setSinglePayload] = useState({
    type: "state",
    state_id: null,
    radiusInMeters: "",
    locations: "",
  });
  const dispatch = useDispatch();
  const [editIndex, setEditIndex] = useState();
  const [editObject, setEditObject] = useState();
  const [isSaved, setIsSaved] = useState();
  const [loader, setLoader] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);
  const [miles, setMiles] = useState("");
  const [isSummary, setIsSummary] = useState(false);
  const handleSave = async () => {
    if (!payloads.industry_id) {
      toast.error("Please Select the industry");
    } else {
      setLoader(true);
      const uniquePayloads = [
        ...new Set(payloads?.payload?.map(JSON.stringify)),
      ].map(JSON.parse);
      let completePayload = {
        ...payloads,
        payload: uniquePayloads,
      };
      if (!isUpdating && !payloads?.id) {
        try {
          const res = await dispatch(save_search_logs(completePayload));
          if (res?.payload?.status === 201) {
            toast.success("Search Log add seccussfully");
            setIsSaved(res.payload.data[0]);
            setPayload({ ...payloads, id: res?.payload?.data[0]?.id });
            setLoader(false);
          }
        } catch (err) {
          console.error(
            "🚀 ~ file: GeoLocation.jsx:90 ~ handleSave ~ err:",
            err
          );
          setLoader(false);
        }
      } else {
        try {
          const res = await dispatch(
            update_search_logs({ ...completePayload, id: payloads?.id })
          );
          if (res?.payload?.status === 200) {
            toast.success("Search Log update seccussfully");
            setIsSaved(res.payload.data[0]);
            setPayload({ ...payloads, id: res?.payload?.data[0]?.id });
            setLoader(false);
            // navigate("/geolocation/locations_list");
          }
        } catch (err) {
          console.error(
            "🚀 ~ file: GeoLocation.jsx:90 ~ handleSave ~ err:",
            err
          );
          setLoader(false);
        }
      }
    }
  };

  const handleMilesChange = (event) => {
    const value = event.target.value;
    setSinglePayload({
      ...singlePayload,
      radiusInMeters: Math.round(value * 1609),
    });
    setMiles(value);
  };
  const FilterHandler = async () => {
    if (
      (singlePayload.type === "city" || singlePayload.type === "city_alias") &&
      !singlePayload.state_id
    ) {
      toast.error("Please select a state");
    } else {
      try {
        validLoc?.forEach(async (location) => {
          const res = await dispatch(
            get_geo_location_detail({
              ...singlePayload,
              locations: location.toString(),
            })
          );
          if (res?.payload?.status === 200) {
            if (res.payload.data?.length > 0) {
              const newPayloads = { ...payloads }; // create a copy of payloads state
              // Check if there's a match between any payload object and the object in state
              const matchingPayloadIndex = payloads.payload.findIndex(
                (payload) => {
                  return (
                    payload?.locations === editObject?.locations &&
                    payload?.type === editObject?.type &&
                    payload?.state_id === editObject?.state_id &&
                    payload?.radiusInMeters === editObject?.radiusInMeters
                  );
                }
              );
              // If there's a match, remove the object from the payload array
              if (
                matchingPayloadIndex !== -1 &&
                matchingPayloadIndex === editIndex
              ) {
                dispatch(deleteObject(editIndex));
                newPayloads.payload.splice(matchingPayloadIndex, 1);
                setEditIndex(null);
                setEditObject();
              }
              newPayloads.payload.push({
                ...singlePayload,
                locations: location.toString(),
              });
              setPayload(newPayloads);
              setSearchedLocation((prevArray) => [
                res.payload.data,
                ...prevArray,
              ]);
            } else {
              toast.error(
                `${`Search By: ${singlePayload?.type} ${
                  singlePayload?.state_id ||
                  singlePayload?.payload?.type === "state"
                    ? `| State: ${
                        singlePayload?.type === "city" ||
                        singlePayload?.type === "city_alias"
                          ? vendors.states?.find(
                              ({ id }) => id === singlePayload?.state_id
                            )?.name
                          : singlePayload?.type === "state"
                          ? location
                            ? location
                            : ""
                          : ""
                      }`
                    : ""
                } ${
                  singlePayload?.radiusInMeters
                    ? `| Radius: ${singlePayload?.radiusInMeters / 1609} Miles`
                    : ""
                } | Location: ${location ? location : ""} `} has no record`,
                {
                  position: "top-right",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                }
              );
            }
          }
        });
        dispatch(resetValidLoc());
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
      }
    }
  };
  useEffect(() => {
    if (loc?.length > 0) {
      dispatch(resetLoc());
    }
    dispatch(get_geo_states());
    dispatch(get_geo_vendors());
    dispatch(get_geo_industry());
    dispatch(clearAll());
    setState([]);
    setSearchedLocation([]);
    setPayload(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(get_geo_vendors_drd({ vendor_type: vendorType }));
    // eslint-disable-next-line
  }, [vendorType]);
  const locationStats = {
    cityCount: {},
    totalCitiesList: [],
    uniqueCitiesList: [],
    totalCities: payloads?.summary_counts?.total_cities || 0,
    uniqueCities: payloads?.summary_counts?.unique_cities || 0,
    duplicateCities: [],
    stateCount: {},
    totalStatesList: [],
    uniqueStatesList: [],
    totalStates: payloads?.summary_counts?.total_states || 0,
    uniqueStates: payloads?.summary_counts?.unique_states || 0,
    duplicateStates: [],
    totalZipsList: [],
    uniqueZipsList: [],
    zipCount: {},
    totalZips: payloads?.summary_counts?.total_zips || 0,
    uniqueZips: payloads?.summary_counts?.unique_zips || 0,
    duplicateZips: [],
    total_population: 0,
  };

  const [inValidData, setInValidData] = useState();
  const onValidate = async () => {
    let newPayloads = [];
    state?.forEach(async (location) => {
      const newPayload = {
        ...singlePayload,
        locations: location.toString(),
      };
      newPayloads = [...newPayloads, newPayload];
    });
    const newPayloadss = newPayloads.filter((newPayload) => {
      return !payloads?.payload?.some(
        (existingPayload) =>
          existingPayload.locations.toLowerCase() ===
            newPayload?.locations?.toLowerCase() &&
          existingPayload?.type === newPayload?.type &&
          existingPayload?.state_id === newPayload?.state_id &&
          existingPayload?.radiusInMeters === newPayload?.radiusInMeters
      );
    });
    if (newPayloadss?.length === state?.length) {
      let c;
      if (singlePayload.type === "city_alias") {
        c = window.confirm("Are you sure want to search with City Alias?");
      }
      if (singlePayload.type === "city_alias" ? c : !c) {
        if (
          (singlePayload.type === "city" ||
            singlePayload.type === "city_alias") &&
          !singlePayload.state_id
        ) {
          toast.error("Please select a state");
        } else {
          try {
            const validate = await dispatch(
              get_valid_locations({
                type: singlePayload.type,
                locations: [...state, ...validLoc]?.toString(),
                state_id: singlePayload?.state_id,
              })
            );
            if (validate?.payload?.status) {
              if (validate?.payload?.data?.length > 0 && state?.length > 0) {
                const array1Only = state.filter(
                  (element) =>
                    !validate?.payload?.data
                      .map((valid) => valid.toLowerCase())
                      .includes(element.toLowerCase())
                );
                setInValidData(array1Only);
                setState([]);
              }
            }
          } catch (err) {
            console.error(
              "🚀 ~ file: GeoLocation.jsx:317 ~ onValidate ~ err:",
              err
            );
          }
        }
      }
    } else {
      toast.error("Same locations are already exist in searched record");
    }
  };
  function copyInvalidData() {
    // Get all the badge elements that contain the invalid data
    const badges = document.querySelectorAll(".bg-danger");
    // Extract the text from each badge element and join them with a separator
    const dataToCopy = Array.from(badges)
      .map((badge) => badge.textContent)
      .join("\n");
    // Copy the text to the clipboard
    const textarea = document.createElement("textarea");
    textarea.value = dataToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("All tags have been copied to the clipboard!");
  }

  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] overflow-y-auto h-[calc(100vh-120px)]">
        {isLoading || loader ? <DotsLoader /> : null}
        {isSummary && (
          <Summary
            totalCitiesList={locationStats?.totalCitiesList}
            totalStatesList={locationStats?.totalStatesList}
            totalZipsList={locationStats?.totalZipsList}
            onClose={() => setIsSummary(false)}
            uniqueCitiesList={locationStats?.uniqueCitiesList}
            uniqueStatesList={locationStats?.uniqueStatesList}
            uniqueZipsList={locationStats?.uniqueZipsList}
            duplicateCities={locationStats?.duplicateCities}
            duplicateZips={locationStats?.duplicateZips}
            duplicateStates={locationStats?.duplicateStates}
            zipCount={locationStats?.zipCount}
          />
        )}
        {assignedDomainModal && (
          <AssignedDomains onClosemodal={cancelFormHandler} id={deletedId} />
        )}
        <div className="bg-white !my-3 border rounded">
          <div className="p-2">
            <div className="flex flex-row items-center m-2 bg-slate-50 !my-3 border rounded p-3">
              <div className="w-full">
                <label className="inputLabel" htmlFor="vendors">
                  {"Description"}
                </label>
                <input
                  placeholder="Description"
                  onChange={(e) =>
                    setPayload({ ...payloads, description: e.target.value })
                  }
                  className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  value={payloads?.description}
                />
              </div>
              <div className="w-full ml-2">
                <label className="inputLabel" htmlFor="vendors">
                  {"Vendors Type"}
                </label>
                <Dropdown
                  value={vendorType}
                  onChange={(e) => {
                    setVendorType(e.value);
                  }}
                  options={[
                    { label: "All", value: "All" },
                    { label: "Global", value: "Global" },
                    { label: "Own", value: "Own" },
                  ]}
                  optionLabel="label"
                  placeholder="Select Vendor Type"
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
              <div className="w-full ml-2">
                <label className="inputLabel" htmlFor="vendors">
                  {"Vendors"}
                </label>
                <Select
                  name="vendors"
                  placeholder="Select Vendors"
                  onChange={(e) => setPayload({ ...payloads, vendor_id: e.id })}
                  options={vendors?.geoVendorDrd}
                  valueKey="id"
                  labelKey="name"
                  className="w-full"
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={
                    payloads?.vendor_id
                      ? {
                          id: payloads.vendor_id,
                          name: vendors?.geoVendorDrd?.find(
                            ({ id }) => id === payloads?.vendor_id
                          )?.name,
                        }
                      : null
                  }
                />
              </div>
              <div className="w-full ml-2">
                <label className="inputLabel" htmlFor="industries">
                  {"Industries"}
                </label>
                <Select
                  name="industries"
                  placeholder="Select Industries"
                  onChange={(e) =>
                    setPayload({ ...payloads, industry_id: e.id })
                  }
                  options={industry?.record}
                  valueKey="id"
                  labelKey="name"
                  className="w-full"
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={
                    payloads?.industry_id
                      ? {
                          id: payloads.industry_id,
                          name: industry?.record?.find(
                            ({ id }) => id === payloads?.industry_id
                          )?.name,
                        }
                      : null
                  }
                />
              </div>
            </div>
            <div className="items-center m-2 bg-slate-50 !my-3 border rounded p-3">
              <div className="audio-greets-tabs my-2 h-[40px]">
                <ul className="!pl-0 flex items-center">
                  {array?.map((val, index) => {
                    return (
                      <li className="active tab-li no-underline">
                        <Link
                          onClick={() =>
                            setSinglePayload({
                              ...singlePayload,
                              type: val.value,
                            })
                          }
                          className={
                            val.value === singlePayload.type
                              ? "no-underline active"
                              : "no-underline"
                          }
                        >
                          {val.label}
                        </Link>
                      </li>
                    );
                  })}
                  {locationDetails?.length > 0 && (
                    <div className="flex justify-end w-[60%]">
                      <button
                        className="border rounded bg-red-600 text-white p-2 ml-2"
                        onClick={() => {
                          dispatch(clearAll());
                          setSinglePayload({
                            ...singlePayload,
                            radiusInMeters: "",
                          });
                          setMiles();
                          setPayload(initialValues);
                          setSearchedLocation([]);
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                  )}
                </ul>
              </div>
              <div className="flex flex-row mb-2">
                <div className="w-1/2">
                  <label className="inputLabel ml-2" htmlFor="miles">
                    {"Radius in Miles"}
                  </label>
                  <input
                    placeholder="Miles"
                    onChange={handleMilesChange}
                    className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    value={miles}
                  />
                </div>
                {singlePayload?.type === "city" ||
                singlePayload?.type === "city_alias" ? (
                  <div className="w-full ml-2">
                    <label className="inputLabel ml-2" htmlFor="location">
                      {"States"}
                    </label>
                    <Select
                      name="state"
                      placeholder="Select State"
                      onChange={(e) =>
                        setSinglePayload({ ...singlePayload, state_id: e.id })
                      }
                      options={vendors?.states}
                      valueKey="value"
                      labelKey="label"
                      className="w-1/2"
                      menuPosition="fixed"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={
                        singlePayload.state_id
                          ? {
                              id: singlePayload.state_id,
                              name: vendors?.states?.find(
                                ({ id }) => id === singlePayload.state_id
                              )?.name,
                            }
                          : null
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row" ref={refTags}>
                <div className="w-full">
                  {state?.length > 0 && (
                    <small>Total Locations: {state?.length}</small>
                  )}

                  <TagsInput tags={state} setTags={setState} />
                </div>
                {searchedLocation?.filter(({ status }) => status === "pending")
                  ?.length === 0 && (
                  <button
                    className={`ml-2 ${
                      state?.length > 0 ? "mt-4" : ""
                    } btn btn-outline-secondary h-[40.39px]`}
                    type="button"
                    data-testid="button-clearAll"
                    onClick={onValidate}
                  >
                    Validate
                  </button>
                )}
              </div>
              {validLoc?.length > 0 && (
                <div className="flex flex-row">
                  <div className="flex flex-col w-full mt-2">
                    <small>Valid Locations: {validLoc?.length}</small>
                    <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
                      {validLoc?.map((val, index) => {
                        return (
                          <div
                            className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-success !text-white !mr-2 my-0.5 h-[26.39px]"
                            key={index}
                          >
                            {val}
                            <button className="border-0 bg-transparent ps-auto pe-0">
                              <MdCancel
                                size={20}
                                className="ml-1 cursor-pointer text-white"
                                onClick={() => {
                                  dispatch(removeFromValidLoc(val));
                                }}
                              />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex flex-col w-full mt-2 ml-2">
                    <div className="flex felx-row justify-between">
                      <div>
                        <small>Invalid Locations: {inValidData?.length}</small>
                      </div>
                      <div>
                        <small
                          onClick={copyInvalidData}
                          className="cursor-pointer hover:underline text-blue-600"
                        >
                          Copy
                        </small>
                        <small
                          onClick={() => setInValidData([])}
                          className="ml-2 cursor-pointer hover:underline text-blue-600"
                        >
                          Clear
                        </small>
                      </div>
                    </div>

                    <div className="form-control h-auto d-inline-flex flex-wrap min-h-[44.38px]">
                      {inValidData?.map((val, index) => {
                        return (
                          <div
                            className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-danger !text-white !mr-2 my-0.5 h-[26.39px]"
                            key={index}
                          >
                            {val}
                            <button className="border-0 bg-transparent ps-auto pe-0">
                              <MdCancel
                                size={20}
                                className="ml-1 cursor-pointer text-white"
                                onClick={() =>
                                  setInValidData((prevState) =>
                                    prevState.filter((item) => item !== val)
                                  )
                                }
                              />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {validLoc?.length > 0 && (
                <div className="w-full mt-2 flex justify-between">
                  <div className="text-danger ml-1">
                    <span>NOTE: </span>You will get the data only for valid tags
                  </div>
                  <div>
                    <button
                      className="border rounded bg-primary-100 text-white p-2 ml-2"
                      onClick={FilterHandler}
                    >
                      Search
                    </button>
                    <button
                      className="border rounded bg-secondary text-white p-2 ml-2"
                      onClick={() => dispatch(resetValidLoc())}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {searchedLocation?.length > 0 && (
            <>
              <div className="flex flex-row justify-between mx-2">
                <div className="flex flex-row m-2">
                  <div className="font-semibold">
                    <span>
                      Total Records:{searchedLocation?.length}/
                      {payloads?.payload?.length}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row m-2">
                  <div className="font-semibold">
                    <span>Total Cities:{locationStats?.totalCities}</span>
                  </div>
                  <div className="font-semibold ml-3">
                    <span>Total Zip Codes:{locationStats?.totalZips}</span>
                  </div>
                </div>
                <div className="flex flex-row m-2">
                  <div className="font-semibold">
                    <span>Unique Cities:{locationStats?.uniqueCities}</span>
                  </div>
                  <div className="font-semibold ml-3">
                    <span>Unique Zip Codes:{locationStats?.uniqueZips}</span>
                  </div>
                </div>
                <div>
                  <button
                    className="ml-2 btn btn-outline-default h-[40.39px]"
                    type="button"
                    data-testid="button-clearAll"
                    onClick={() => setCollapseAll(!collapseAll)}
                  >
                    {collapseAll ? "Expand All" : "Collapse All"}
                  </button>
                  <button
                    className="ml-2 btn btn-outline-success h-[40.39px]"
                    type="button"
                    data-testid="button-clearAll"
                    onClick={() => setIsSummary(true)}
                  >
                    Show Summary
                  </button>
                </div>
              </div>
              <div className="text-danger ml-3">
                <span>NOTE: </span>After the record is saved summary will be
                updated on the backend and displayed on the list page when it
                will be ready.
              </div>
            </>
          )}

          {searchedLocation?.length > 0 && (
            <>
              {searchedLocation?.map((data, index) => {
                if (data?.status !== "success") {
                  return data?.map((log, index2) => {
                    const record = log?.data;
                    const names = record?.cities
                      ?.map((item) => {
                        return item.name;
                      })
                      .join(", ");
                    const states = record?.states
                      ?.map((item) => item.name)
                      .join(", ");
                    const zips = record?.zips
                      ?.map((item) => item.zip)
                      .join(", ");
                    return (
                      <details
                        className=" mt-3 mb-3 px-2"
                        open={!collapseAll}
                        key={index}
                        id={index}
                      >
                        <summary
                          className={`flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer ${
                            index === editIndex ? "bg-red-100" : "bg-gray-100"
                          } hover:bg-gray-300 py-2 px-1`}
                        >
                          <span className="text-xl font-bolder">
                            <span className="mr-3">{index + 1}</span>
                            {`Search By: ${
                              array.find(
                                ({ value }) => value === log?.payload?.type
                              )?.label
                            } ${
                              log?.payload?.state_id ||
                              log?.payload?.type === "state"
                                ? `| State: ${
                                    log?.payload?.type === "city" ||
                                    log?.payload?.type === "city_alias"
                                      ? vendors.states?.find(
                                          ({ id }) =>
                                            id === log?.payload?.state_id
                                        )?.name
                                      : log?.payload?.type === "state"
                                      ? log?.payload
                                        ? log?.payload?.locations
                                        : ""
                                      : ""
                                  }`
                                : ""
                            } ${
                              log?.payload?.radiusInMeters
                                ? `| Radius: ${
                                    log?.payload?.radiusInMeters / 1609
                                  } Miles`
                                : ""
                            } | Location: ${
                              log?.payload ? log?.payload?.locations : ""
                            } `}
                          </span>
                          <span className="text-xl font-bolder">
                            {`Zips: ${
                              record?.zips?.length ? record?.zips?.length : 0
                            } | States: ${
                              record?.states?.length
                                ? record?.states?.length
                                : 0
                            } | Cities: ${
                              record?.cities?.length
                                ? record?.cities?.length
                                : 0
                            }`}
                          </span>
                          <div>
                            {log?.is_locked ? (
                              <button className="outline-none border-none bg-transparent p-1">
                                <HiLockClosed
                                  className="my_navIcon"
                                  size={18}
                                />
                              </button>
                            ) : (
                              <button
                                className="outline-none border-none bg-transparent p-1"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  openFormHandler(log?.id);
                                }}
                              >
                                <FaTrash title="Delete Location"/>
                              </button>
                            )}
                          </div>
                        </summary>

                        <div className=" m-2 bg-slate !my-3 border rounded p-1">
                          <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
                            <div className="w-2/6 px-2">
                              <textarea
                                className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                                value={zips}
                                rows={3}
                              />
                            </div>
                            <div className="w-2/6 px-2">
                              <textarea
                                className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                                value={states}
                                rows={3}
                              />
                            </div>
                            <div className="w-2/6 px-2">
                              <textarea
                                className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                                value={names}
                                rows={3}
                              />
                            </div>
                          </div>
                        </div>
                      </details>
                    );
                  });
                } else {
                  return (
                    <details className=" mt-3 mb-3 px-2" open={!collapseAll}>
                      <summary
                        className={`flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer ${
                          index === editIndex ? "bg-red-100" : "bg-gray-100"
                        } hover:bg-gray-300 py-2 px-1`}
                      >
                        <span className="text-xl font-bolder">{`Search By: ${
                          array.find(({ value }) => value === data?.type)?.label
                        } ${
                          data?.radiusInMeters
                            ? `| Radius: ${data?.radiusInMeters / 1609} Miles`
                            : ""
                        } | Location: ${data ? data?.locations : ""} `}</span>
                        <span className="text-xl font-bolder"></span>
                      </summary>
                      <Loader />
                    </details>
                  );
                }
              })}
            </>
          )}
          {locationDetails?.length > 0 && (
            <div
              className={`flex flex-row px-2 ${
                isSaved?.id ? "justify-between" : "justify-center"
              }`}
            >
              {isSaved?.updated_at &&
                ` Last saved at: ${dayjs(isSaved?.updated_at).format(
                  "ddd, MMM D, YYYY h:mm A"
                )}`}

              <div className="flex flex-row">
                <div className="flex w-full justify-center mb-2">
                  <Button
                    text="Save"
                    variant="btn_submit"
                    onClick={handleSave}
                  />
                </div>
                {isSaved?.id && (
                  <div className="flex w-full justify-center mb-2 ml-2">
                    <Button
                      text="Close"
                      variant="btn_cancel"
                      onClick={() => navigate("/geolocation/locations_list")}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GeoLocation;
