import { Camera } from "@mui/icons-material";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function ImageForm() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("q");
  const handleFileChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    const newImages = Array.from(files).map((file) => ({
      file: file,
      preview: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleSave = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      images.forEach((image, index) => {
        formData.append(`file[${index}]`, image.file);
      });

      const url = `/api/agentless/upload_imgs_by_customer/${token}`;
      const response = await api.post(url, formData);
      if (response.status === 200 || response.status === 201) {
        toast.success("Images uploaded successfully:");
        setImages([]);
      } else {
        toast.error("Images couldn't be uploaded:");
      }
      console.log("Images uploaded successfully:", response.data);
    } catch (error) {
      toast.error("Images couldn't be uploaded:");
      console.error("Error uploading images:", error);
    }
    setIsLoading(false);
  };
  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex flex-col w-full max-w-screen-md mt-8">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div
              key={index}
              className="relative w-full h-96 mb-4 flex items-center justify-center bg-gray-300 rounded-md overflow-hidden"
            >
              <img
                title={`Uploaded image ${index + 1}`}
                src={image.preview}
                loading="lazy"
                alt=""
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              <button
                className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold py-1 px-2 rounded-md"
                onClick={() => removeImage(index)}
              >
                Remove
              </button>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-96 bg-black/30">
            <p className="text-white text-center">No images uploaded</p>
          </div>
        )}
        <div className="flex flex-row items-center justify-center !mt-5 gap-x-5">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            id="imageUpload"
            className="hidden"
            accept="image/*"
          />
          <label
            htmlFor="imageUpload"
            className="text-xs font-bold py-1 px-3 cursor-pointer rounded-m hover:bg-primary text-white bg-menu transition-all flex items-center gap-2"
          >
            Upload Images
            <Camera className="w-4" />
          </label>

          <button
            onClick={handleSave}
            className="text-sm font-bold py-1.5 !px-3 cursor-pointer rounded-m hover:bg-primary text-white bg-primary-100 transition-all"
          >
            Save
          </button>
        </div>
      </div>

      {/* <div className="flex items-center justify-end w-full max-w-screen-md mt-5">
        <button className="text-sm font-bold py-1.5 !px-3 cursor-pointer rounded-md hover:bg-primary hover:text-white bg-primary-100 transition-all">
          Save
        </button>
      </div> */}
    </div>
  );
}
