import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp, FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
} from "../../components";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import PageHeader from "../../components/molecules/PageHeader";
import {
  create_geo_vendors,
  delete_industies_locations,
  geoVendorsReset,
  get_geo_vendors_drd,
  get_geo_vendors_new_drd,
  get_vendor_industries_locations,
  update_geo_vendors,
} from "../../features/GeoVendorsSlice";
import { useFormik } from "formik";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import UpdateLocationModal from "./NewUpdateLocationModal";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import PhoneInput, { getCountries } from "react-phone-number-input";
import { MultiSelect } from "primereact/multiselect";
import DropdownWithGroupedCheckboxes from "./Dropdown";
import errorMessage from "../../util/errorMessage";
import { get_cms_job_category } from "../../features/jobCategorySlice";
import { get_countries } from "../../features/countriesSlice";
// import FinalUpdateLocationModal from "./FinalUpdateLocationModal";
function UpdateVendorsLocations() {
  const { vandorIndustryLoc, geoVendorDrd, isLoading, updateLoader } =
    useSelector((state) => state.geoVendors);
  const { cmsRecord } = useSelector((state) => state.jobCategory);
  const industry = useSelector((state) => state.geoIndustry);
  const { record } = useSelector((state) => state.geoIndustry);
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInfo, setIsInfo] = useState(true);
  const [isLocation, setIsLocation] = useState(true);

  const [errors, setErrors] = React.useState([]);
  const [isAssign, setIsAssign] = React.useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [editingRecord, setEditingRecord] = useState({});
  const [recipients, setRecipients] = useState([]);
  const { countries } = useSelector((state) => state.countries);
  function convertNullToEmptyArray(value) {
    return value === null ? [] : value;
  }
  let initialValues = {
    parent_id: null,
    company_name: "",
    unique_did: "",
    first_name: "",
    last_name: "",
    phone: "",
    alternate_phone: "",
    corporate_phone: "",
    email: "",
    tech_percentage: "",
    address: "",
    street: "",
    zip: "",
    address_latitude: "",
    address_longitude: "",
    active: true,
    client_phone_masking: "false",
    industries: [],
    country_short_code: "US",
    vendor_type: "",
  };

  if (id) {
    const {
      id,
      parent_id,
      unique_did,
      first_name,
      last_name,
      phone,
      alternate_phone,
      email,
      tech_percentage,
      address,
      street,
      zip,
      address_latitude,
      address_longitude,
      active,
      client_phone_masking,
      industries,
      company_name,
      corporate_phone,
      // sms_type,
      country_short_code,
      vendor_type,
    } = editingRecord;
    initialValues = {
      id,
      parent_id: String(parent_id) ? String(parent_id) : "",
      unique_did: unique_did ? unique_did : "",
      recipients: recipients ? recipients : "",
      first_name: first_name ? first_name : "",
      last_name: last_name ? last_name : "",
      phone: phone ? phone : "",
      alternate_phone: alternate_phone ? alternate_phone : "",
      corporate_phone: corporate_phone ? corporate_phone : "",
      email: email ? email : "",
      tech_percentage: tech_percentage ? tech_percentage : "",
      address: address ? address : "",
      street: street ? street : "",
      zip: zip ? zip : "",
      address_latitude: address_latitude ? address_latitude : "",
      address_longitude: address_longitude ? address_longitude : "",
      company_name: company_name ? company_name : "",
      // sms_type: sms_type ? sms_type : unique_did ? "Twilio" : "Inteliquent",
      country_short_code: country_short_code ? country_short_code : "US",
      active: Boolean(active),
      client_phone_masking: Boolean(client_phone_masking),
      vendor_type: vendor_type ? vendor_type : "",
      industries: Array.isArray(industries)
        ? industries.map((item) => ({
            id: item?.id,
            categories: convertNullToEmptyArray(item?.categories),
          }))
        : [],
    };
  }
  useEffect(() => {
    if (vandorIndustryLoc?.recipients) {
      setRecipients(vandorIndustryLoc?.recipients);
    }
  }, [vandorIndustryLoc?.recipients]);

  const validateEmailFormat = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  function validateForm(data) {
    const errors = [];
    if (!data?.phone) {
      errors.push({ phone: "phone is required" });
    }
    if (!data?.zip) {
      errors.push({ zip: '"zip" is required' });
    }
    if (!data?.first_name) {
      errors.push({ first_name: '"first_name" is required' });
    }
    if (!data?.vendor_type) {
      errors.push({ vendor_type: '"vendor_type" is required' });
    }
    if (data?.email) {
      if (!validateEmailFormat(data?.email)) {
        errors.push({ email: '"email" please enter a valid email' });
      }
    }
    return errors;
  }
  const formik = useFormik({
    initialValues: id
      ? {
          ...initialValues,
          ...vandorIndustryLoc,
          industries: vandorIndustryLoc?.industries
            ? vandorIndustryLoc?.industries
            : [],
          industries_has_locations: vandorIndustryLoc?.industries_has_locations
            ? vandorIndustryLoc?.industries_has_locations
            : [],
        }
      : initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      delete values.country_id;
      const errors = validateForm(formik.values);
      if (errors?.length === 0) {
        if (!id) {
          try {
            const res = await dispatch(
              create_geo_vendors({
                ...values,
                recipients: recipients,
              })
            );
            if (res?.payload?.status === 201) {
              toast.success("Vendor created");
              setErrors([]);
              navigate(`/settings/vendors/update/${res.payload?.data?._id}`, {
                state: res.payload?.data[0],
              });
            } else {
              if (Array.isArray(res.payload)) {
                setErrors(res.payload);
              } else {
                toast.error(
                  res.payload ? res.payload : "Vendor couldn't be created"
                );
              }
            }
          } catch (error) {
            console.error(
              "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
              error
            );
            toast.error("Error, Vendor couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(
              update_geo_vendors({
                ...values,
                recipients: recipients,
              })
            );
            if (res?.payload?.status === 200) {
              toast.success("Vendor updated");
              setErrors([]);
              dispatch(get_vendor_industries_locations(id));
            } else {
              if (Array.isArray(res.payload)) {
                setErrors(res.payload);
              } else {
                toast.error(
                  res.payload ? res.payload : "Vendor couldn't be updated"
                );
              }
            }
          } catch (error) {
            console.error(
              "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
              error
            );
            error?.forEach((error) => {
              const field = Object.keys(error)[0]; // get the field name from the error object
              formik.setErrors(field, error[field]); // set the error for the field
            });
          }
        }
      } else {
        errorMessage({
          payload: errors,
          setErrors: setErrors,
          action: "Vendor",
          msg: "created",
        });
      }
    },
  });
  useEffect(() => {
    dispatch(get_geo_vendors_new_drd({ vendor_type: "Own" }));
    dispatch(get_geo_industry());
    dispatch(get_cms_job_category());
    dispatch(get_countries());
    return () => {
      dispatch(geoVendorsReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(get_vendor_industries_locations(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // useEffect(() => {
  //   if (formik.values.unique_did && formik.values.sms_type === "Inteliquent") {
  //     formik.setFieldValue("sms_type", "Twilio");
  //   } else if (
  //     !formik.values.unique_did &&
  //     formik.values.sms_type === "Inteliquent"
  //   ) {
  //     formik.setFieldValue("sms_type", "Inteliquent");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.unique_did]);
  // useEffect(() => {
  //   if (formik.values.sms_type === "Inteliquent") {
  //     formik.setFieldValue("unique_did", "");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.sms_type]);
  const cancelFormHandler = () => {
    setEditingRecord({});
    setIsAssign(false);
  };
  const onIndustrySelect = (id) => {
    if (selectedData?.some((ids) => ids === id)) {
      setSelectedData((prevArray) => prevArray?.filter((item) => item !== id));
    } else {
      if (selectedData?.length > 0)
        return toast.error(
          "We can only add locations for 1 industry at a time."
        );
      setSelectedData([...selectedData, id]);
    }
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.industries && data?.industries.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.industries.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const handleDelete = async (loc_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_industies_locations(loc_id));
        if (res?.payload?.status === 200) {
          dispatch(get_vendor_industries_locations(id));
          toast.success("Locations delete successfully");
        }
      } catch (err) {
        toast.error("Locations delete");
        console.error(
          "🚀 ~ file: GeoLocation.jsx:331 ~ handleDelete ~ err:",
          err
        );
      }
    }
  };
  const columnDefs = [
    {
      headerName: "Sr.",
      field: "counter",
      width: 60,
    },
    {
      headerName: "Industries",
      field: "industry_name",
      // renderCell: TagsCellRenderer,
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Total State",
      field: "uniqueStates",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Total Cities",
      field: "uniqueCities",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Total Zip Codes",
      field: "uniqueZips",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <>
          <div>
            <FaEdit
              onClick={() => {
                handleEditData(params.row.records);
              }}
              className="my_navIcon"
              title="Update Vendor Location"
            />
          </div>
          <div>
            <FaTrash
              onClick={() => {
                handleDelete({
                  industry_id: params.row.industry_id,
                  vendor_id: id,
                });
              }}
              className="my_navIcon"
              title="Remove Locations"
            />
          </div>
        </>
      ),
      width: 80,
    },
  ];
  const handleAddData = (data) => {
    if (selectedData?.length > 1)
      return toast.error("We can only add locations for 1 industry at a time.");
    setEditingRecord({
      zips: [],
      industries: data?.industries,
      vendor_id: id,
      id: null,
    });
    setIsAssign(true);
  };
  const handleEditData = (data) => {
    setEditingRecord({
      zips: [],
      industries: [data?.industry_id],
      vendor_id: id,
      id: 123456,
    });
    setIsAssign(true);
  };
  const industries_without = vandorIndustryLoc?.industries?.filter(
    (item) => !vandorIndustryLoc?.industries_has_locations?.includes(item.id)
  );
  const with_locations = vandorIndustryLoc?.industries?.filter((item) =>
    vandorIndustryLoc?.industries_has_locations?.includes(item.id)
  );
  function findPhoneValue() {
    for (let i = 0; i < errors.length; i++) {
      if ("phone" in errors[i]) {
        return errors[i].phone;
      }
    }
    return null; // Return null if "phone" property is not found in any object
  }
  const phoneErrorValue = findPhoneValue();
  const handleIndustrySelect = (data) => {
    const industries = formik.values.industries;
    const newId = data?.selectedOption?.mongo_id;
    // check user select all industries or not
    const isSelectAll = Array.isArray(data?.selectedOption);
    if (isSelectAll) {
      // if select all or deselect all
      if (data?.selectedOption?.length === industry?.record?.length) {
        // if select all
        const allIndustries = data?.selectedOption
          ?.filter((id) => !industries.some((item) => item.id === id))
          ?.map((id) => ({ id: id, categories: [] }));
        formik.setFieldValue("industries", [...industries, ...allIndustries]);
      } else if (
        data?.selectedOption?.length === 0 &&
        industries?.length === industry?.record?.length
      ) {
        // deselect all
        formik.setFieldValue("industries", []);
      }
    } else {
      // Check if the length of industry is not 0
      if (formik.values.industries?.length > 0) {
        // check industry already exist or not
        const isIndustryExist = industries?.some((item) => item.id === newId);

        if (isIndustryExist) {
          // Remove industry if it exist
          const updateData = industries?.filter(({ id }) => id !== newId);
          // set updated data in formik
          formik.setFieldValue("industries", updateData);
        } else {
          // set new data in formik if industry is not exist
          formik.setFieldValue("industries", [
            ...industries,
            { id: newId, categories: [] },
          ]);
        }
      } else {
        // set new data in formik if industry array is empty
        formik.setFieldValue("industries", [
          ...industries,
          { id: newId, categories: [] },
        ]);
      }
    }
  };
  const groupedCategories = formik.values?.industries?.reduce(
    (accumulator, data) => {
      const industryName = industry?.record?.find(
        ({ mongo_id }) => mongo_id === data.id
      )?.industry_name;
      const categories = cmsRecord.filter(
        (cat) => cat.industry_mongo_id === data.id
      );
      accumulator.push({
        industry_mongo_id: data.id,
        name: industryName,
        categories: categories ? categories : [],
      });
      return accumulator;
    },
    []
  );
  const onSelectCategory = ({
    industry_mongo_id,
    type,
    isChecked,
    category_id,
  }) => {
    const selectedGroupValue = industry_mongo_id;
    const selectedIndustries = formik.values?.industries;
    const updatedCategory = [...selectedIndustries];
    const industryIndex = selectedIndustries?.findIndex(
      ({ id }) => id === selectedGroupValue
    );
    if (industryIndex !== -1) {
      if (type === "industry") {
        const groupCategories = groupedCategories.find(
          (group) => group?.industry_mongo_id === selectedGroupValue
        );
        if (isChecked) {
          updatedCategory[industryIndex] = {
            ...updatedCategory[industryIndex],
            categories:
              groupCategories?.categories?.map((item) => item?.crm_id) || [],
          };
          formik.setFieldValue("industries", updatedCategory);
        } else {
          updatedCategory[industryIndex] = {
            ...updatedCategory[industryIndex],
            categories: [],
          };
          formik.setFieldValue("industries", updatedCategory);
        }
      } else if (type === "category") {
        if (isChecked) {
          updatedCategory[industryIndex] = {
            ...updatedCategory[industryIndex],
            categories: [
              ...updatedCategory[industryIndex].categories,
              category_id,
            ],
          };
          formik.setFieldValue("industries", updatedCategory);
        } else {
          updatedCategory[industryIndex] = {
            ...updatedCategory[industryIndex],
            categories: updatedCategory[industryIndex].categories?.filter(
              (catId) => catId !== category_id
            ),
          };
          formik.setFieldValue("industries", updatedCategory);
        }
      }
    }
  };
  const sortedData = [...geoVendorDrd].sort((a, b) =>
    a?.first_name.localeCompare(b?.first_name)
  );
  return (
    <>
      <div>
        <PageHeader
          route="Leads"
          heading="Update Vendor Locations"
          isHead="No"
        />
        {isLoading || updateLoader ? <DotsLoader /> : null}
        {isAssign && (
          <UpdateLocationModal
            editingRecord={editingRecord}
            modalTitle={
              editingRecord?.id ? "Update Locations" : "Add Locations"
            }
            onCancelForm={cancelFormHandler}
            vendorDetail={vandorIndustryLoc}
            allIndustries={record}
          />
        )}
        <div className="py-3 px-6 bg-white rounded-3xl">
          <h5 className="text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] flex flex-row justify-between items-center">
            Basic Information
            <div className="cursor-pointer " onClick={() => setIsInfo(!isInfo)}>
              {isInfo ? (
                <FaChevronUp className="fas fa-times cursor-pointer" />
              ) : (
                <FaChevronDown className="fas fa-times cursor-pointer" />
              )}
            </div>
          </h5>
          {isInfo ? (
            <div className="border border-[#D9D9D9] rounded-md bg-[#f5f5f5] p-3 mt-3">
              <div className="grid md:grid-cols-5 gap-x-5">
                <div>
                  <FormSelectInput
                    errors={errors}
                    name="parent_id"
                    label="Parent Vendor"
                    formik={formik}
                    options={sortedData}
                    valueProp="_id"
                    labelProp="first_name"
                    labelProp2="phone"
                  />
                </div>
                <div>
                  <FormInput
                    errors={errors}
                    name="company_name"
                    label="Company Name"
                    formik={formik}
                  />
                </div>
                <div>
                  <FormInput
                    errors={errors}
                    name="first_name"
                    label="First Name"
                    formik={formik}
                  />
                </div>
                <div>
                  <FormInput
                    errors={errors}
                    name="last_name"
                    label="Last Name"
                    formik={formik}
                  />
                </div>
                <div className="relative">
                  <label
                    htmlFor={"phone"}
                    className={[
                      `small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] -translate-y-[13px] scale-75 px-1`,
                    ].join(" ")}
                    style={{
                      background:
                        "linear-gradient(0deg, white 50%, transparent 50%)",
                    }}
                  >
                    {"Job Notification Phone"}
                  </label>
                  <PhoneInput
                    defaultCountry="US"
                    className={[
                      " appearance-none !border !border-[#a9a9a9] bg-white rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                      `rounded-l-none`,
                      formik.errors.phone && formik.touched.phone
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onChange={(e) => formik.setFieldValue("phone", e ? e : "")}
                    value={formik.values.phone}
                    placeholder={"Phone"}
                    initialValueFormat="national"
                    onCountryChange={(e) =>
                      formik.setFieldValue("country_short_code", e ? e : "")
                    }
                    countries={getCountries()} // Provide the list of countries for the dropdown
                  />
                  {phoneErrorValue ? (
                    <small
                      style={{
                        fontSize: 12,
                        marginTop: -5,
                        paddingLeft: 16,
                        color: "#D32F2F",
                      }}
                    >
                      {phoneErrorValue
                        ?.replaceAll(`"phone"`, "Phone")
                        ?.replaceAll(`phone`, "Phone")}
                    </small>
                  ) : null}
                </div>
                <div className="!mt-5 relative">
                  <label
                    htmlFor={"alternate_phone"}
                    className={[
                      `small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] scale-75 px-1`,
                    ].join(" ")}
                    style={{
                      background:
                        "linear-gradient(0deg, white 50%, transparent 50%)",
                    }}
                  >
                    {"Job Notification Phone 2"}
                  </label>
                  <PhoneInput
                    defaultCountry="US"
                    className={[
                      " appearance-none !border !border-[#a9a9a9] bg-white rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                      `rounded-l-none`,
                      formik.errors.phone && formik.touched.phone
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onChange={(e) =>
                      formik.setFieldValue("alternate_phone", e ? e : "")
                    }
                    value={formik.values.alternate_phone}
                    placeholder={"Phone 2"}
                    initialValueFormat="national"
                  />
                </div>
                <div className="!mt-5 relative">
                  <label
                    htmlFor={"corporate_phone"}
                    className={[
                      `small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] scale-75 px-1`,
                    ].join(" ")}
                    style={{
                      background:
                        "linear-gradient(0deg, white 50%, transparent 50%)",
                    }}
                  >
                    {"Corporate Phone"}
                  </label>
                  <PhoneInput
                    defaultCountry="US"
                    className={[
                      " appearance-none !border !border-[#a9a9a9] bg-white rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                      `rounded-l-none`,
                      formik.errors.phone && formik.touched.phone
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onChange={(e) =>
                      formik.setFieldValue("corporate_phone", e ? e : "")
                    }
                    value={formik.values.corporate_phone}
                    placeholder={"Corporate Phone"}
                    initialValueFormat="national"
                  />
                </div>
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="email"
                    label="Job Notification Email"
                    formik={formik}
                    type={"email"}
                  />
                </div>
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="tech_percentage"
                    label="Tech Percentage(%)"
                    formik={formik}
                    type={"number"}
                  />
                </div>
                {id ? (
                  <div className="!mt-5">
                    <FormSelectInput
                      errors={errors}
                      name="active"
                      label="Active"
                      formik={formik}
                      options={[
                        { label: "Yes", value: "true" },
                        { label: "No", value: "false" },
                      ]}
                      convertor={(value) => value === "true"}
                      valueProp="value"
                      labelProp="label"
                    />
                  </div>
                ) : null}
              </div>
              <div className="grid md:grid-cols-5 gap-x-5">
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="address"
                    label="Address"
                    formik={formik}
                  />
                </div>
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="city"
                    label="City"
                    formik={formik}
                    readOnly
                  />
                </div>
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="state"
                    label="State"
                    formik={formik}
                    readOnly
                  />
                </div>
                <div className="!mt-5">
                  <FormInput
                    errors={errors}
                    name="zip"
                    label="Zip"
                    formik={formik}
                  />
                </div>
                <div className="!mt-5">
                  <FormSelectInput
                    errors={errors}
                    name="country_id"
                    label="Country"
                    formik={formik}
                    options={countries}
                    valueProp="_id"
                    labelProp="name"
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-5 !mt-5 gap-x-5">
                {/* <div className="w-full mb-3">
                  <FormSelectInput
                    name="sms_type"
                    placeholder="SMS Type"
                    formik={formik}
                    options={[
                      { value: "Inteliquent", label: "Inteliquent" },
                      { value: "Twilio", label: "Twilio" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                    label="SMS Type"
                  />
                </div> */}
                <div className="relative">
                  <label
                    htmlFor={"phone"}
                    className={[
                      `small text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] scale-75 px-1`,
                    ].join(" ")}
                    style={{
                      background:
                        "linear-gradient(0deg, white 50%, transparent 50%)",
                    }}
                  >
                    {"Unique SMS DID"}
                  </label>
                  <PhoneInput
                    defaultCountry="US"
                    className={[
                      " appearance-none !border !border-[#a9a9a9] bg-white rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                      `rounded-l-none`,
                      formik.errors.unique_did && formik.touched.unique_did
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onChange={(e) => {
                      formik.setFieldValue("unique_did", e ? e : "");
                    }}
                    value={formik.values.unique_did}
                    placeholder={"DID"}
                    initialValueFormat="national"
                    onCountryChange={(e) =>
                      formik.setFieldValue("country_short_code", e ? e : "")
                    }
                    countries={getCountries()} // Provide the list of countries for the dropdown
                    // disabled={formik.values.sms_type === "Inteliquent"}
                  />
                  {/* {phoneErrorValue ? (
                    <small
                      style={{
                        fontSize: 12,
                        marginTop: -5,
                        paddingLeft: 16,
                        color: "#D32F2F",
                      }}
                    >
                      {phoneErrorValue
                        ?.replaceAll(`"phone"`, "Phone")
                        ?.replaceAll(`phone`, "Phone")}
                    </small>
                  ) : null} */}
                </div>
                <div className="col-span-2">
                  <TagsInput
                    tags={recipients}
                    setTags={setRecipients}
                    placeholder="Add Phone Number"
                    phoneInput={true}
                    label="SMS Group Recipients Phone Number"
                    isFloat
                  />
                </div>
                <div className="relative">
                  <FormRadioGroup
                    errors={errors}
                    name="client_phone_masking"
                    label="Client Phone Masking"
                    isOnChange={(e) =>
                      formik?.setFieldValue("client_phone_masking", e)
                    }
                    labelProp="label"
                    valueProp="value"
                    options={[
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" },
                    ]}
                    convertor={(value) => value === "true"}
                    isCheck={formik.values.client_phone_masking}
                    isHorizontal
                    isBorder
                    isFloat
                    className={"bg-white"}
                  />
                </div>
                <div className="w-full">
                  <FormSelectInput
                    name="vendor_type"
                    placeholder="Vendor Type"
                    formik={formik}
                    options={[
                      { value: "InNet", label: "In Net" },
                      { value: "OutNet", label: "Out Net" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                    label="Vendor Type"
                    errors={errors}
                  />
                </div>
                {/* <FormSelectInput
                    name="sms_type"
                    placeholder="SMS Type"
                    formik={formik}
                    options={[
                      { value: "Inteliquent", label: "Inteliquent" },
                      { value: "Twilio", label: "Twilio" },
                    ]}
                    valueProp="value"
                    labelProp="label"
                    label="SMS Type"
                  /> */}
              </div>
            </div>
          ) : null}
        </div>

        <div className="py-3 px-6 bg-white rounded-3xl">
          <h5 className="text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] flex flex-row justify-between items-center">
            Location Information
            <div
              className="cursor-pointer"
              onClick={() => setIsLocation(!isLocation)}
            >
              {isLocation ? (
                <FaChevronUp className="fas fa-times cursor-pointer" />
              ) : (
                <FaChevronDown className="fas fa-times cursor-pointer" />
              )}
            </div>
          </h5>
          {isLocation ? (
            <div className="bg-[#f5f5f5] p-3 mt-3 rounded-md border border-[#D9D9D9]">
              <div className="grid md:grid-cols-5 gap-x-5">
                <div className="!mb-5">
                  <MultiSelect
                    placeholder="Select Industries"
                    options={industry?.record}
                    optionLabel="industry_name"
                    optionValue="mongo_id"
                    filter
                    maxSelectedLabels={0}
                    value={formik.values.industries?.map((item) => item.id)}
                    onChange={(e) => handleIndustrySelect(e)}
                    className="h-[40px] w-full !rounded-[4px] focus:!shadow-none border-[#a9a9a9] hover:border-[#ced4da]"
                  />
                </div>
                <div className="!mb-5">
                  <DropdownWithGroupedCheckboxes
                    groupedCategories={groupedCategories}
                    onSelectCategory={(data) => onSelectCategory(data)}
                    selectedCategories={formik.values.industries}
                  />
                </div>
                <div className="flex justify-start w-full !mb-5">
                  <Button
                    text={id ? "Update Info" : "Save Info"}
                    variant="btn_submit"
                    onClick={formik.handleSubmit}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-2">
                <div className="p-3 bg-white rounded !mt-2 border">
                  <h5 className="bg-[#f0f0f0] text-[#343434] p-1 w-full border border-[#d8d8d8] flex flex-row justify-between items-center">
                    <span>Locations Not Assigned</span>
                    <span className="bg-[#343434] w-5 h-5 flex justify-center items-center rounded-full">
                      <small className="text-white text-sm">
                        {industries_without?.length
                          ? industries_without?.length
                          : 0}
                      </small>
                    </span>
                  </h5>
                  <div className="flex flex-wrap">
                    {industries_without?.map((category) => (
                      <div key={category.id}>
                        <div
                          className={`flex items-center !py-1.5 !px-2.5`}
                          onClick={(e) => onIndustrySelect(category?.id)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedData?.some(
                              (id) => id === category.id
                            )}
                            className={`form-checkbox h-5 w-5 text-primary-100 rounded focus:ring-0 cursor-pointer mr-2`}
                            value={category.id}
                            readOnly
                          />
                          <label
                            className={`text-base text-[#495057] cursor-pointer`}
                          >
                            {
                              record?.find(
                                ({ mongo_id }) => mongo_id === category.id
                              )?.industry_name
                            }
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center">
                    {selectedData?.length > 0 ? (
                      <span
                        className="cursor-pointer text-blue-600 hover:underline"
                        onClick={() =>
                          handleAddData({ industries: selectedData })
                        }
                      >
                        Add Locations
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="p-3 bg-white rounded !mt-2 border">
                  <h5 className="bg-[#f0f0f0] text-[#343434] p-1 w-full border border-[#d8d8d8] flex flex-row justify-between items-center">
                    Locations Assigned
                    <span className="bg-[#343434] w-5 h-5 flex justify-center items-center rounded-full">
                      <small className="text-white text-sm">
                        {with_locations?.length ? with_locations?.length : 0}
                      </small>
                    </span>
                  </h5>
                  <div className="flex flex-wrap">
                    {with_locations?.map((category) => {
                      return (
                        <div key={category.id}>
                          <div className={`flex items-center !py-1.5 !px-2.5 `}>
                            <input
                              type="checkbox"
                              checked={vandorIndustryLoc?.industries_has_locations?.some(
                                (id) => id === category.id
                              )}
                              className={`form-checkbox h-5 w-5 text-primary-100 rounded focus:ring-0 cursor-pointer mr-2`}
                              value={category.id}
                              readOnly
                            />
                            <label
                              className={`text-base text-[#495057] cursor-pointer`}
                            >
                              {
                                record?.find(
                                  ({ mongo_id }) => mongo_id === category.id
                                )?.industry_name
                              }
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
                <MUIDataTable
                  columnDefs={columnDefs}
                  items={vandorIndustryLoc?.industries_with_unique_locations?.map(
                    (record, index) => {
                      const {
                        industry_name,
                        uniqueZips,
                        uniqueStates,
                        uniqueCities,
                        industry_id,
                      } = record;
                      return {
                        counter: index + 1,
                        records: { ...record, _id: index + 1 },
                        industry_name,
                        uniqueZips,
                        uniqueStates,
                        uniqueCities,
                        industry_id,
                      };
                    }
                  )}
                  searchable="No"
                  isLoading={isLoading || updateLoader}
                  gridOptions={getRowHeight}
                  pagination="No"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default UpdateVendorsLocations;
