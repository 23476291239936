import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import {
  delete_job_category,
  get_cms_job_category,
  get_job_category,
} from "../../features/jobCategorySlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_geo_industry } from "../../features/GeoIndustrySlice";

const JobCategory = () => {
  const dispatch = useDispatch();
  const { isLoading, cmsRecord } = useSelector((state) => state.jobCategory);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  // eslint-disable-next-line

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_job_category(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Industry Category deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Industry Category",
            msg: "deleted",
          });
        }
      } catch (error) {
        console.error("🚀 ~ file: index.jsx:57 ~ deleteRecordHandler ~ error:", error)
        errorMessage({
          payload: error,
          action: "Industry Category",
          msg: "deleted",
        });
      }
    }
  };
  useEffect(() => {
    dispatch(get_cms_job_category());
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  const categories = cmsRecord?.filter(
    (industry) =>
      industry?.industry_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      industry?.name?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "INDUSTRY", field: "industry_name", flex: 1 },
    { headerName: "JOB CATEGORY", field: "name", flex: 1 },
    { headerName: "DROP LOCATION", field: "drop_location_required", flex: 1 },
    { headerName: "PRIORITY", field: "priority", flex: 1 },
    { headerName: "STATUS", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        !params.row.records?.is_deleted ? (
          <div>
            {userPermission("Update Job Category") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Job Category"
              />
            )}
            {userPermission("Delete Job Category") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Job Category"
              />
            )}
          </div>
        ) : null,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_job_category({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord?"Update Industry Category":"Add Industry Category"}
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Job Category"
        heading="Industry Categories Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Job Category")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={categories?.map((record, index) => {
            let counter = index + 1;
            let industry_name = record?.industry?.name;
            let name = record?.name;
            let drop_location_required = record?.drop_location_required
              ? "Yes"
              : "No";
            let priority = record?.priority;

            let status = record.active ? "Active" : "InActive";

            return {
              records: {...record,_id:record?.id},
              counter,
              name,
              industry_name,
              drop_location_required,
              priority,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={cmsRecord?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default JobCategory;
