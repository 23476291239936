import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_job_category,
  update_job_category,
} from "../../features/jobCategorySlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.jobCategory);
  const industry = useSelector((state) => state.geoIndustry);
  const [errors, setErrors] = React.useState([]);

  let initialValues = {
    industry_id: "",
    name: "",
    drop_location_required: 0,
    priority: 0,
    dark_img: "",
    light_img: "",
    active:1,
  };
  if (editingRecord) {
    const {
      _id,
      industry,
      name,
      priority,
      dark_img,
      light_img,
      drop_location_required,
      active
    } = editingRecord;
    initialValues = {
      id: _id,
      industry_id: industry?.id,
      name,
      drop_location_required,
      priority,
      dark_img: dark_img ? dark_img : "",
      light_img: light_img ? light_img : "",
      active
    };
  }
  function validateForm(data) {
    const errors = [];
    if (!data.industry_id) {
      errors.push({ industry_id: "Industry is required" });
    }
    if (!data.name) {
      errors.push({ name: '"name" is required' });
    }
    // if (!(+data.drop_location_required===0||+data.drop_location_required===1)||data.drop_location_required==="") {
    //   errors.push({ drop_location_required: '"drop_location_required" is required' });
    // }
    if (!data.priority&&!data.priority===0) {
      errors.push({ priority: '"priority" is required' });
    }
    // if (!+data.active===0||!+data.active===1) {
    //   errors.push({ active: '"active" is required' });
    // }
    // if (!data.address_latitude) {
    //   errors.push({ address: "Please select the address" });
    // }
    // if (!data.address_longitude) {
    //   errors.push({ address: "Please select the address" });
    // }

    return errors;
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: JobCategorySchema,

    onSubmit: async (values) => {
      const errors = validateForm(formik.values);
      if (errors?.length === 0) {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_job_category(values));
          if (res?.payload?.status === 201) {
            toast.success("Industry Category created");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Industry Category", msg:'created'})
          }
        } catch (error) {
          console.error("🚀 ~ file: AddUpdateForm.jsx:102 ~ onSubmit: ~ error:", error)
          toast.error("Industry Category couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_job_category(values));
          if (res?.payload?.status === 200) {
            toast.success("Industry Category updated");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Industry Category", msg:'updated'})
          }
        } catch (error) {
          console.error("🚀 ~ file: AddUpdateForm.jsx:86 ~ onSubmit: ~ error:", error)
          toast.error("Industry Category couldn't be updated");
        }
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Industry Category",
        msg: `${editingRecord?"updated":"created"}`,
      });
    }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="mb-3 mt-4">
            <FormSelectInput errors={errors}
              name="industry_id"
              label="Industry"
              formik={formik}
              options={industry?.record}
              valueProp="id"
              labelProp="industry_name"
            />
          </div>

          <div className="mb-3">
            <FormInput errors={errors} name="name" label="Name" formik={formik} />
          </div>
          
          <div className="mb-3">
            <FormSelectInput errors={errors}
              name="drop_location_required"
              label="Drop Location Required"
              formik={formik}
              nullOption="No"
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              valueProp="value"
              labelProp="label"
              // convertor={(value) => value === "true"}
            />
          </div>

          <div className="mb-3">
            <FormInput errors={errors}
              name="priority"
              label="Priority"
              formik={formik}
              type="number"
            />
          </div>
          <div className="mb-3">
            <FormInput errors={errors} name="dark_img" label="Dark Image" formik={formik} />
          </div>
          <div className="mb-3">
            <FormInput errors={errors} name="light_img" label="Light Image" formik={formik} />
          </div>
          <div className="">
            <FormSelectInput errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              valueProp="value"
              labelProp="label"
              nullOption="No"
              // convertor={(value) => value === "true"}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
