import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, FormInput, Loader } from "../../components";
const QuotingModal = ({ onClose, invoiceDetail, reloadReport }) => {
  console.log("👊 ~ QuotingModal ~ invoiceDetail:", invoiceDetail);
  const [isLoader, setIsLoader] = useState(false);
  const [amount, setAmount] = useState(invoiceDetail?.service_cost || 0);
  const handleConfirm = async (status) => {
    setIsLoader(true);
    try {
      const res = await api.post(
        `/api/leads/${invoiceDetail?._id}/update_quoting_price`,
        {
          status: status,
          price: amount,
        }
      );
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        reloadReport();
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[80%]">
        <h2 className="text-lg font-semibold mb-4">Quoting Request</h2>
        <div className="relative flex flex-col">
          <div>
            <label htmlFor="phone" className="text-client-50">
              Industry:{" "}
            </label>{" "}
            <span id="phone">{invoiceDetail.industry_id?.name}</span>
          </div>
          {invoiceDetail?.job_category_id ? (
            <div>
              <label htmlFor="phone" className="text-client-50">
                Job Category:{" "}
              </label>{" "}
              <span id="phone">{invoiceDetail.job_category_id?.name}</span>
            </div>
          ) : null}

          <div>
            <label htmlFor="phone" className="text-client-50">
              Pickup Address:{" "}
            </label>{" "}
            <span id="phone">{invoiceDetail.complete_pickup_address}</span>
          </div>
          {invoiceDetail?.complete_drop_address ? (
            <div>
              <label htmlFor="phone" className="text-client-50">
                Drop Address:{" "}
              </label>{" "}
              <span id="phone">{invoiceDetail.complete_drop_address}</span>
            </div>
          ) : null}

          <FormInput
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            name="amount"
            placeholder={"Service Cost"}
            label="Service Cost"
            className={"my-3"}
            type={"number"}
          />
        </div>
        <div className="flex justify- !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <div className="flex justify-between w-full">
              <Button text="Close" variant="btn_cancel" onClick={onClose} />
              <div className="flex gap-x-2">
                <Button
                  text="Reject"
                  variant="btn_danger"
                  onClick={() => handleConfirm("Rejected")}
                />
                <Button
                  text="Accept"
                  variant="btn_submit"
                  onClick={() => handleConfirm("Quoted")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotingModal;
