import { MultiSelect } from "primereact/multiselect";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, DotsLoader } from "../../../components";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { create_batch } from "../../../features/domainCenterSlice";
import { get_tld } from "../../../features/TldSlice";

const BatchSaveModal = (props) => {
  const { industry_id, search_details, locations, onCancelForm, title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, record } = useSelector((state) => state.tld);
  const initialFilters = {
    industry_id,
    search_details,
    locations,
    tlds: [],
    keywords: [],
    note: "",
  };
  const [keywords, setKeywords] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [filterData, setfilterData] = useState(initialFilters);
  React.useEffect(() => {
    dispatch(get_tld());
    // eslint-disable-next-line
  }, []);
  const handleSave = async () => {
    if (filterData?.note !== "") {
      try {
        let payload = {
          ...filterData,
          keywords: keywords,
        };
        setIsLoader(true);
        const res = await dispatch(create_batch(payload));
        if (res?.payload?.status === 200 || res?.payload?.status === 201) {
          onCancelForm();
          setIsLoader(false);
          toast.success("Batch created successfully.");
          navigate(`/domain_center/batch_report/${res.payload?.data?._id}`);
        }else{
          toast.error("Coludn't be added");
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        console.error("🚀 ~ file: saveBatch.jsx:62 ~ handleSave ~ err:", err);
        toast.error("Coludn't be added");
      }
    } else {
      toast.error("Note is required");
    }
  };
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      {isLoading || isLoader ? <DotsLoader /> : null}
      <div className="relative">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelForm}
            className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="z-10 w-auto w-full md:max-w-[1024px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
          <div className="pt-0 max-w-[994px] h-full px-3.5 m-auto">
            <header className="bg-primary-100 h-[60px] flex items-center justify-between shadow-lg">
              <h1 className=" text-2xl text-white m-4 font-medium">{title}</h1>
              <h1 className=" text-2xl text-white m-4 font-medium">
                {`Selected Records: ${
                  filterData?.batch_for_all
                    ? props?.totalItems
                    : locations?.length
                }`}
              </h1>
            </header>
            <div className="md:p-4">
              <div className="grid grid-cols-3 gap-x-2 items-center mb-2">
                <div className="span-col-1">
                  <label className="inputLabel" htmlFor="logs">
                    {"TLDs"}
                  </label>
                  <MultiSelect
                    placeholder="Select TLDs"
                    options={record}
                    optionLabel="name"
                    optionValue="name"
                    filter
                    maxSelectedLabels={2}
                    value={filterData?.tlds}
                    onChange={(e) => {
                      setfilterData({ ...filterData, tlds: e.value });
                    }}
                    className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  />
                </div>
                <div className="span-col-1 ">
                  <label className="inputLabel" htmlFor="industries">
                    {"Note"}
                  </label>
                  <input
                    className="form-control"
                    placeholder="Note"
                    onChange={(e) =>
                      setfilterData({
                        ...filterData,
                        note: e.target.value,
                      })
                    }
                    value={filterData?.note}
                  />
                </div>
              </div>
              <TagsInput
                tags={keywords}
                setTags={setKeywords}
                placeholder="Add keywords"
              />
            </div>

            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={onCancelForm}
                variant="btn_cancel"
              />
              <Button
                text={"Submit"}
                className=""
                onClick={handleSave}
                isLoading={isLoading || isLoader}
                variant="btn_submit"
                disabled={keywords?.length > 0 ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchSaveModal;
