import React, { useEffect, useState } from "react";
import { Button, DotsLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import { clearCities, clearStates, get_cities, get_geo_states } from "../../features/GeoVendorsSlice";
import CitiesModal from "./Cities";
const GeoStates = () => {
  const dispatch = useDispatch();
  const { isLoading, states, cities } = useSelector(
    (state) => state.geoVendors
  );
  const [searchState, setSearchState] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    dispatch(get_geo_states())
    dispatch(clearCities())
    // eslint-disable-next-line
  }, []);
  const filterStates = states?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchState?.toLowerCase())
    );
  });
  return (
    <>
      {cities?.length>0 && (
        <CitiesModal
          cities={cities}
          modalTitle="Add Allowed IP"
          onCancelForm={()=>dispatch(clearCities())}
          state={selectedState}
        />
      )}
      <PageHeader
        route="Setting > Allowed IP"
        heading="State Listing"
        isAllowed={userPermission("Geo Locatio")}
      />
      {
        isLoading&&
        <DotsLoader/>
      }
      <div className="flex flex-row items-center w-1/2 mt-3">
            <div className="w-full">
              <input
                  placeholder="Description"
                  onChange={(e) =>
                    setSearchState(e.target.value)
                  }
                  className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  value={searchState}
                />
            </div>
              <Button
                text={"Search"}
                className="py-1.5 px-3 align-middle mx-2 bg-primary-100 text-white"
                onClick={()=>dispatch(get_geo_states({search:searchState}))}
              />
              <Button
                text="Clear"
                className="py-1.5 px-3 align-middle bg-menu text-white"
                onClick={() => {
                  dispatch(clearStates())
                }}
              />
            </div>
           <span className="ml-2 mt-3 text-end font_pop">
        Total Records: {states?.length}
      </span>
      <div className="bg-white my-3 border rounded">
      <div className="table-responsive max-h-[75vh] mb-2">
        <table className="table table-striped table-bordered">
          <thead className="sticky top-0 bg-white border-t border-[#ccc]">
            <tr className='className="bg-white border-t border-[#ccc]'>
              <th className="sticky">#</th>
              <th className="sticky">Name</th>
              <th className="sticky">Distance in Miles</th>
              <th className="sticky">Latitude</th>
              <th className="sticky">Longitude</th>
              <th className="sticky">Actions</th>
            </tr>
          </thead>
          <tbody className=" max-h-[65vh]  mb-2 ">
            {filterStates?.map((data, index) => {
              return (
                <tr
                  key={index}
                  className="border-b-[1px] border-slate-200 p-4  "
                >
                  <td>{index + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.short_name}</td>
                  <td>{data.latitude}</td>
                  <td>{data.longitude}</td>
                  <td><span 
                  className="text-blue-600 cursor-pointer hover:underline"
                  onClick={()=>{
                    dispatch(get_cities({state_id:data.id}))
                  setSelectedState(data?.name)
                  }
                    }
                    >View Cities</span></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      </div>
    </>
  );
};

export default GeoStates;
