import api from "./api";

export const getCMSJobCategory = async (data) => {
  return await api.get(`/api/vendors/domain_prime_time/industry_category`,{params:data});
};
export const getJobCategory = async (data) => {
  return await api.get(`/api/job_categories`,{params:data});
};
export const createJobCategory = async (data) => {
  return await api.post("https://api.cmsdeployer.com/api/industry_category", data);
};
export const updateJobCategory = async (data) => {
  return await api.put(`https://api.cmsdeployer.com/api/industry_category/${data?.id}`, data);
};
export const deleteJobCategory = async (id) => {
  return await api.delete(`https://api.cmsdeployer.com/api/industry_category/${id}`);
};
