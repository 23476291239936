import React, { useRef } from "react";
import {
  FaBan,
  FaCheck,
  FaLevelDownAlt,
  FaPause,
  FaRegClock,
  FaUsers,
} from "react-icons/fa";
import { TbPhoneCall, TbPhoneCalling } from "react-icons/tb";
import { RiLoginBoxFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { call_monitor, get_realtime_data } from "../../features/dialerSlice";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { BiSupport, BiUserVoice } from "react-icons/bi";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { toast } from "react-toastify";
function RealTime() {
  const dispatch = useDispatch();
  const dialer = useSelector((state) => state.dialer);
  const { realtime } = dialer;
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  React.useEffect(() => {
    if(user?.dialer_data?.dialer_user){
      dispatch(get_realtime_data());
      const timerID = setInterval(() => {
        dispatch(get_realtime_data());
      }, 5000);
      return () => {
        clearInterval(timerID);
      };
    }else{
      toast.error("You are not authorized for this report.")
    }
    
    
    // eslint-disable-next-line
  }, []);
  const iframeUseRef = useRef();
  const sendPostMessage = () => {
    if (!iframeUseRef?.current) return;
    const iframe = iframeUseRef.current.querySelector("iframe");
    console.warn("--------- sendMessage ------", iframe);
    iframe?.contentWindow?.postMessage({ action: "close_session" }, "*");
  };
  const columnDefs = [
    { headerName: "EXTEN", field: "extension", width: 100 },
    { headerName: "Call Type", field: "call_type", width: 80 },
    { headerName: "AGENT", field: "full_name", flex: 1, minWidth: 120 },
    { headerName: "STATUS", field: "status", width: 100 },
    { headerName: "PAUSE CODE", field: "pause_code" },
    {
      headerName: "CUSTOMER/PHONE",
      field: "customer_name",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "TIME", field: "call_time_ms", width: 100 },
    {
      headerName: "INBOUND GROUP",
      field: "inbound_group",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "SITE NAME",
      field: "did_description",
      flex: 1,
      minWidth: 200,
    },
    { headerName: "CAMPAIGN", field: "campaign_name", flex: 1, minWidth: 100 },
    { headerName: "CALLS", field: "calls_today", width: 100 },
    {
      headerName: "Action",
      renderCell: (params) => (
        <div className="flex flex-row justify-between">
          {user?.barge && (
            <BiSupport
              title="Barge"
              size={24}
              className="cursor-pointer"
              onClick={() => {
                if (dialer?.status === "") {
                  sendPostMessage();
                  dispatch(
                    call_monitor({
                      session_id: params?.row?.conf_exten,
                      server_ip: params?.row?.server_ip,
                      type: "BARGE",
                    })
                  );
                } else {
                  toast.error("Please logout your dialer for this action");
                }
              }}
            />
          )}
          {user?.monitor && (
            <MdOutlineScreenshotMonitor
              title="Monitor"
              size={24}
              className="mx-2 cursor-pointer"
              onClick={() => {
                if (dialer?.status === "") {
                  sendPostMessage();
                  dispatch(
                    call_monitor({
                      session_id: params?.row?.conf_exten,
                      server_ip: params?.row?.server_ip,
                      type: "MONITOR",
                    })
                  );
                } else {
                  toast.error("Please logout your dialer for this action");
                }
              }}
            />
          )}
          {user?.whisper && (
            <BiUserVoice
              title="Whisper"
              size={24}
              className="cursor-pointer"
              onClick={() => {
                if (dialer?.status === "") {
                  sendPostMessage();
                  dispatch(
                    call_monitor({
                      session_id: params?.row?.conf_exten,
                      server_ip: params?.row?.server_ip,
                      type: "WHISPER",
                    })
                  );
                } else {
                  toast.error("Please logout your dialer for this action");
                }
              }}
            />
          )}
        </div>
      ),
      flex: 1,
      minWidth: 150,
    },
  ];
  const columnDefs2 = [
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "campaign_id",
      headerName: "In Group",
      renderCell: (params) => (
        <div className="flex flex-row">
          {`${params?.row?.campaign_id} - ${params?.row?.campaign_name}`}
        </div>
      ),
      flex: 1,
      minWidth: 200,
    },
    { headerName: "Customer Phone", field: "phone_number", flex: 1 },
    { headerName: "Queue Time", field: "call_time", flex: 1 },
    { headerName: "Queue Priority", field: "queue_priority", flex: 1 },
    { headerName: "Call Type", field: "call_type" },
  ];
  const getRowClass = (params) => {
    return params?.row?.bgcolor;
  };
  const storedIframe = localStorage.getItem("iframe");
  return (
    <div className="container-fluid">
      {storedIframe && (
        <div
          ref={iframeUseRef}
          dangerouslySetInnerHTML={{ __html: storedIframe }}
        />
      )}
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-8 gap-3.5 mt-4">
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.loggedin}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Available
              </span>
            </div>
            <FaCheck
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.loggedin}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Logged In
              </span>
            </div>
            <RiLoginBoxFill
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.paused}
              </span>
              <span className="font-pop text-[13px] font-semibold">PAUSE</span>
            </div>
            <FaPause
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.wait}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                WAITING
              </span>
            </div>
            <FaRegClock
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.dead}
              </span>
              <span className="font-pop text-[13px] font-semibold">DEAD</span>
            </div>
            <FaBan
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.dispo}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                DISPOSITION
              </span>
            </div>
            <FaLevelDownAlt
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.ringing}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                Ringing
              </span>
            </div>
            <TbPhoneCalling
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>

        <div className="bg-white p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-col" style={{ lineHeight: "20px" }}>
              <span className="font-pop text-[13px] font-semibold">
                {realtime?.agent_stats?.incall}
              </span>
              <span className="font-pop text-[13px] font-semibold">
                In Call
              </span>
            </div>
            <TbPhoneCall
              size={40}
              style={{ fontWeight: "bolder" }}
              color={"#cccccc"}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
        <div className="bg-white p-2">
          <div className="ag-theme-alpine">
            <div className="w-full p-2 pt-0 flex items-center text-base font-normal font-mont text-heading ">
              <FaUsers className="mr-1" />
              Calls in Queue
            </div>
            <DataGridPro
              rows={
                realtime?.realtime_stats?.call_waiting
                  ? realtime?.realtime_stats?.call_waiting
                  : {}
              }
              columns={columnDefs2}
              autoHeight
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showColumnSelector: false,
                },
              }}
              getRowId={(row) => row?.id}
              classes={{
                columnHeader: "bg-[#f8f8f8] shadow-sm",
                columnHeaderTitle:
                  "tableHead font-bold text-md capitalize px-0",
                row: "text-md",
              }}
              rowHeight={32}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded mh-70-vh overflow-y-auto">
        <div className="bg-white p-2">
          <div className="ag-theme-alpine">
            <div className="w-full p-2 flex items-center text-base font-normal font-mont text-heading">
              <FaUsers className="mr-1" />
              Agent
            </div>
            <DataGridPro
              rows={realtime?.agent_realtime ? realtime?.agent_realtime : []}
              columns={columnDefs}
              components={{
                Toolbar: GridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  showColumnSelector: false,
                },
              }}
              getRowId={(row) => row?.id}
              classes={{
                columnHeader: "bg-[#f8f8f8] shadow-sm",
                columnHeaderTitle:
                  "tableHead font-bold text-md capitalize px-0",
                row: "text-md",
              }}
              getRowClassName={getRowClass}
              autoHeight
              rowHeight={32}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealTime;
