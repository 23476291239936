import React from "react";
import { logo_logical, new_login_bg } from "../../images";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import { faEnvelope, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Loader } from "../../components";

export default function LoginLogicalCRM({
  InputField,
  Divider,
  setLoginType,
  submitHandler,
  username,
  setUsername,
  password,
  setPassword,
  loginType,
  showPassword,
  toggleShowPassword,
  reset,
}) {
  const dispatch = useDispatch();

  const { isLoading, isError, message, errors } = useSelector(
    (state) => state.auth
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-login">
      <div
        className="bg-login bg-cover bg-center"
        style={{ backgroundImage: `url(${new_login_bg})` }}
      ></div>

      <div className="bg-white p-10 min-h-screen overflow-y-scroll">
        <img src={logo_logical} alt="login logo" className="my-5 h-16" />
        <h1 className="mt-10 text-3xl font-bold">Hi, Welcome Back</h1>
        <p>Customer Relation Management Solution</p>
        <div className="my-10">
          <p className="border-b text-gray-500 mt-10">Select Login Type</p>
          <FormRadioGroup
            className="mt-2 text-lg font-medium"
            name="loginType"
            isOnChange={(e) => setLoginType(e)}
            labelProp="label"
            valueProp="value"
            options={[
              { label: "User Login", value: "user" },
              { label: "Vendor Login", value: "vendor" },
            ]}
            isCheck={loginType}
            isHorizontal
          />
        </div>

        <form onSubmit={submitHandler} className="mt-10">
          <InputField
            label="Username"
            placeholder="Username"
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
            icon={faEnvelope}
            errors={errors}
          />

          <InputField
            label="Password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            icon={faShieldHalved}
            errors={errors}
          />

          {isError && !errors && (
            <div className="text-red-500 text-sm mb-3.7 mt-1.5">{message}</div>
          )}

          <div className="text-black mb-6 flex justify-between mt-3 px-2.5">
            <label
              htmlFor="showpass"
              className="flex items-center gap-2 cursor-pointer text-gray-500"
            >
              <input
                type="checkbox"
                id="showpass"
                name="showpass"
                checked={showPassword}
                className="h-4 w-4"
                onChange={toggleShowPassword}
              />
              Show Password
            </label>

            <NavLink
              to={{
                pathname: "/forgot_password",
                search: `?userType=${loginType}`,
              }}
              className="text-primary-100 text-base underline"
              onClick={() => {
                dispatch(reset());
              }}
            >
              Forgot Password?
            </NavLink>
          </div>

          <div className="mt-5 mb-4">
            {!isLoading ? (
              <Button
                text="Login now"
                type="submit"
                className="btnPrimary w-full !rounded-md"
              />
            ) : (
              <Loader />
            )}
          </div>

          {/* <Divider />

          <div className="mt-4">
            {!isLoading ? (
              <Button
                text="Sign Up now"
                type="button"
                className="btnPrimary bg-white text-primary-100 w-full !rounded-md border !border-primary-100"
              />
            ) : (
              <Loader />
            )}
          </div> */}
        </form>
      </div>
    </div>
  );
}
