import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import {
  get_cities,
  get_geo_states,
  get_geo_vendors_drd,
  get_zips,
} from "../../features/GeoVendorsSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import { Dropdown } from "primereact/dropdown";
import { Button, DotsLoader } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";

import { RxCrossCircled } from "react-icons/rx";
import { MultiSelect } from "primereact/multiselect";
import {
  create_assigned_service_zips,
  update_assigned_service_zips,
} from "../../features/vendorIndustrySlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
const UpdateVendorsIndustry = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [newData, setNewData] = useState(data?.service_area_zips?data?.service_area_zips:[]);
  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  let initialFilters = {
    vendor_id: "",
    industry_id: "",
  };
  if (data) {
    const { id, industry_id, vendor_id } = data;
    initialFilters = {
    id,
      industry_id,
      vendor_id,
    };
  }
  const [selectedData, setSelectedData] = useState({
    state_id: "",
    city_id: "",
  });
  const [filters, setFilters] = React.useState(initialFilters);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_geo_vendors_drd());
    dispatch(get_geo_industry());
    dispatch(get_geo_states());
    // eslint-disable-next-line
  }, []);

  const zipsArray = data?.service_area_zips?.reduce((result, state) => {
    state.cities.forEach((city) => {
      const cityZips = Array.isArray(city.zips) ? city.zips : [city.zips];
      result.push(...cityZips);
    });
    return result;
  }, []);
  const [zips, setZips] = useState(zipsArray?zipsArray:[]);

  const handleRemoveZip2 = (zip) => {
    // Remove the zip code from the zips array
    setZips((prevZips) => prevZips.filter((item) => item !== zip));
    // Remove the zip code from array1
    const updatedData = newData?.map((state) => ({
      ...state,
      cities: state.cities.map((city) => ({
        ...city,
        zips: Array.isArray(city.zips)
          ? city.zips.filter((item) => item !== zip)
          : city.zips,
      })),
    }));
    const updateCities = updatedData.map((state) => ({
      ...state,
      cities: state.cities.filter((city) => city.zips?.length !== 0),
    }));
    const finalData = updateCities?.filter(
      (state) => state?.cities?.length !== 0
    );
    setNewData(finalData);
  };

  const handleRemoveZip = ({ state_id, city_id, zip }) => {
    setNewData((prevData) => {
      let oldZipsArray = [];
      const newData = prevData.map((state) => ({
        ...state,
        cities: state.cities.map((city) => ({
          ...city,
          zips: Array.isArray(city.zips)
            ? city.zips.filter((item) => item !== zip)
            : city.zips,
        })),
      }));

      const existingStateIndex = newData.findIndex(
        (state) => state.state_id === state_id
      );
      if (existingStateIndex !== -1) {
        const existingCityIndex = newData[existingStateIndex].cities.findIndex(
          (city) => city.city_id === city_id
        );

        if (existingCityIndex !== -1) {
          oldZipsArray =
            newData[existingStateIndex].cities[existingCityIndex].zips;
          if (Array.isArray(zip)) {
            // If zip is an array, replace the entire city.zips array
            newData[existingStateIndex].cities[existingCityIndex].zips = zip;
          } else {
            // If zip is a single value, add or remove it from the city.zips array
            newData[existingStateIndex].cities[existingCityIndex].zips =
              Array.isArray(
                newData[existingStateIndex].cities[existingCityIndex].zips
              )
                ? newData[existingStateIndex].cities[
                    existingCityIndex
                  ].zips.includes(zip)
                  ? newData[existingStateIndex].cities[
                      existingCityIndex
                    ].zips.filter((item) => item !== zip)
                  : [
                      ...newData[existingStateIndex].cities[existingCityIndex]
                        .zips,
                      zip,
                    ]
                : [zip];
          }

          // If the zips array is empty or has no values, delete the entire city object from cities array
          if (
            !newData[existingStateIndex].cities[existingCityIndex].zips ||
            newData[existingStateIndex].cities[existingCityIndex].zips
              .length === 0
          ) {
            newData[existingStateIndex].cities.splice(existingCityIndex, 1);
          }
        } else {
          // If city_id doesn't exist, add the city and zip to the state
          newData[existingStateIndex].cities.unshift({
            city_id,
            city_name: vendors?.cities?.find(({ id }) => id === city_id)?.name,
            zips: Array.isArray(zip) ? zip : [zip],
          });
        }

        // If the cities array is empty or has no values, delete the entire state object from newData
        if (
          !newData[existingStateIndex].cities ||
          newData[existingStateIndex].cities.length === 0
        ) {
          newData.splice(existingStateIndex, 1);
        }
      } else {
        // If state_id doesn't exist, add the state, city, and zip to the newData
        newData.unshift({
          state_id,
          state_name: vendors?.states?.find(({ id }) => id === state_id)?.name,
          cities: [
            {
              city_id,
              city_name: vendors?.cities?.find(({ id }) => id === city_id)
                ?.name,
              zips: Array.isArray(zip) ? zip : [...zips, zip],
            },
          ],
        });
      }
      setZips((prevZips) => {
        const zipArray = Array.isArray(zip) ? zip : [zip];
        return prevZips
          ?.filter((item) => !oldZipsArray.includes(item))
          ?.concat(zipArray);
      });
      return newData;
    });
  };

  const handleRemoveCity = (cityId) => {
    const c = window.confirm("Are you sure you want to remove this city?");
    if (c) {
      setZips((prevZips) =>
        prevZips.filter(
          (zip) =>
            !newData.find((state) =>
              state.cities.find(
                (city) => city.city_id === cityId && city.zips.includes(zip)
              )
            )
        )
      );
      const updatedData = newData.map((state) => ({
        ...state,
        cities: state.cities.filter((city) => city.city_id !== cityId),
      }));
      const finalData = updatedData?.filter(
        (state) => state?.cities?.length !== 0
      );
      setNewData(finalData);
    }
  };
  const handleRemoveState = (stateId) => {
    const c = window.confirm("Are you sure you want to remove this state?");
    if (c) {
      setZips((prevZips) =>
        prevZips.filter(
          (zip) =>
            !newData.find(
              (state) =>
                state.state_id === stateId &&
                state.cities.find((city) => city.zips.includes(zip))
            )
        )
      );

      const updatedData = newData.filter((state) => state.state_id !== stateId);
      setNewData(updatedData);
    }
  };
  React.useEffect(() => {
    if (selectedData.state_id) {
      dispatch(get_cities({ state_id: selectedData.state_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData.state_id]);
  React.useEffect(() => {
    if (selectedData.city_id) {
      dispatch(get_zips(selectedData.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData.city_id]);
  const [errors, setErrors] = React.useState([]);
  const handleSubmit = async () => {
    if (filters.industry_id && filters.vendor_id) {
      const action = data ? "updated" : "created";
      const apiFunction = data
        ? update_assigned_service_zips
        : create_assigned_service_zips;
      const successMessage = data
        ? "Service zips updated successfully"
        : "Service zips created successfully";
      try {
        const res = await dispatch(
          apiFunction({ ...filters, service_area_zips: zips?.toString() })
        );
        if (res?.payload?.status === 201 || res?.payload?.status === 200) {
          toast.success(successMessage);
          navigate("/settings/vendors_industry",{state:{industry_id:filters?.industry_id,vendor_id:filters.vendor_id}})
        //   window.location.reload()
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Service zips",
            msg: action,
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Service zips",
          msg: action,
        });
      }
    } else {
      toast.error("Vendor and Industry are required fields");
    }
  };
  const getSubTotalCities = (stateId) => {
    const state = newData.find((state) => state.state_id === stateId);
    return state ? state.cities.length : 0;
  };
  const getSubTotalZips = (stateId) => {
    const state = newData?.find((state) => state.state_id === stateId);
    let totalZips = 0;
    if (state) {
      state.cities.forEach((city) => {
        if (Array.isArray(city.zips)) {
          totalZips += city.zips.length;
        } else {
          totalZips += 1;
        }
      });
    }
    return totalZips;
  };
  const getTotalZips = () => {
    let totalZips = 0;
    newData.forEach((state) => {
      state.cities.forEach((city) => {
        if (Array.isArray(city.zips)) {
          totalZips += city.zips.length;
        } else {
          totalZips += 1;
        }
      });
    });
    return totalZips;
  };
  const getTotalCities = () => {
    let totalCities = 0;
    newData.forEach((state) => {
      totalCities += state.cities.length;
    });
    return totalCities;
  };
  return (
    <>
      <PageHeader
        route="Setting > Users"
        heading="Map Industries with Vendor"
        // onClick={openFormHandler(0)}
        // isAllowed={userPermission("Geo Location")}
      />
      {vendors?.isLoading ? <DotsLoader /> : null}
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="grid grid-cols-5 gap-2 items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors"}
            </label>
            <Dropdown
              value={filters.vendor_id}
              onChange={(e) => setFilters({ ...filters, vendor_id: e.value })}
              options={vendors?.geoVendorDrd}
              optionValue="id"
              optionLabel={(option) =>
                `${option?.first_name} ${
                  option?.last_name ? option?.last_name : ""
                }`
              }
              placeholder={vendors?.isLoading ?"Loading...":"Select Vendor"}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
            {errors[0]?.vendor_id && (
              <small
                style={{
                  fontSize: 12,
                  marginTop: 0,
                  paddingLeft: 16,
                  color: "#D32F2F",
                  background: "transparent",
                }}
              >
                {errors[0]?.vendor_id
                  .replaceAll(`"vendor_id"`, "Vendor")
                  .replaceAll(`vendor_id`, "Vendor")}
              </small>
            )}
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <Dropdown
              value={filters.industry_id}
              onChange={(e) => setFilters({ ...filters, industry_id: e.value })}
              options={industry?.record}
              optionLabel="industry_name"
              optionValue="id"
              placeholder="Select Industry"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
            {errors[0]?.industry_name && (
              <small
                style={{
                  fontSize: 12,
                  marginTop: 0,
                  paddingLeft: 16,
                  color: "#D32F2F",
                  background: "transparent",
                }}
              >
                {errors[0]?.industry_name
                  .replaceAll(`"industry_name"`, "Industry")
                  .replaceAll(`industry_name`, "Industry")}
              </small>
            )}
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"State"}
            </label>
            <Dropdown
              value={selectedData.state_id}
              onChange={(e) =>
                setSelectedData({ ...selectedData, state_id: e.value })
              }
              options={vendors?.states}
              optionValue="id"
              optionLabel="name"
              placeholder="Select State"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"City"}
            </label>
            <Dropdown
              value={selectedData.city_id}
              onChange={(e) =>
                setSelectedData({ ...selectedData, city_id: e.value })
              }
              options={vendors?.cities}
              optionValue="id"
              optionLabel="name"
              placeholder="Select City"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"Zips"}
            </label>
            <MultiSelect
              placeholder="Select Zip"
              options={vendors?.zips}
              optionLabel="zip"
              optionValue="zip"
              filter
              maxSelectedLabels={0}
              value={
                newData
                  ?.find(({ state_id }) => state_id === selectedData?.state_id)
                  ?.cities?.find(
                    ({ city_id }) => city_id === selectedData?.city_id
                  )?.zips
              }
              onChange={(e) =>
                handleRemoveZip({ ...selectedData, zip: e.value })
              }
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
        </div>
      </div>
      <div className=" mx-auto p-2 h-[62vh] overflow-y-auto">
        <span className="flex text-lg text-red-600 px-2 font-semibold font-pop mb-2">{`Total Selected States: ${newData?.length} | Total Selected Cities: ${getTotalCities()} | Total Selected Zips: ${getTotalZips()}`} </span>
        {newData?.map((state, state_index) => (
          <details className="mb-1" key={state_index}>
            <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer  bg-gray-300 hover:bg-gray-400 py-2 px-1">
              {`${state_index + 1}. State: ${state.state_name} | Total Selected Cities: ${getSubTotalCities(state?.state_id)} | Total Selected Zips: ${getSubTotalZips(state?.state_id)}`}
              <button
                className="outline-none border-none bg-transparent p-1 text-menu hover:text-red-600"
                onClick={(event) => {
                  event.stopPropagation();
                  handleRemoveState(state.state_id);
                }}
              >
                <MdCancel size={20}/>
              </button>
            </summary>
            <div className="my-4">
              <div className="grid gap-2 grid-cols-2">
                {state.cities.map((city, index) => (
                  <div key={index} className="rounded-md shadow-sm">
                    <div className="px-2 bg-gray-200 text-lg font-semibold mb-2 font-pop flex justify-between border-b-2">
                      {`${index + 1}. City: ${city.city_name} | Total Selected Zips: ${city?.zips?.length}`}
                      <button
                        className="border-0 bg-transparent ps-auto pe-0"
                        title="Remove city"
                      >
                        <MdCancel
                          size={20}
                          className="ml-1 cursor-pointer text-menu hover:text-gray-600"
                          onClick={() => handleRemoveCity(city.city_id)}
                        />
                      </button>
                    </div>

                    <div className="p-2">
                      <div className="flex flex-wrap">
                        {Array.isArray(city.zips) ? (
                          city.zips.map((zip, index) => (
                            <div
                              className="badge me-1 pe-1 justify-content-between border-b me-1 pe-1 justify-content-between !bg-white !text-black !mr-2 my-0.5 h-[26.39px]"
                              key={index}
                            >
                              {zip}
                              <button className="border-0 bg-transparent ps-auto pe-0">
                                <RxCrossCircled
                                  size={20}
                                  className="ml-1 cursor-pointer text-black"
                                  onClick={() => handleRemoveZip2(zip)}
                                  //   onClick={() => handleRemoveZip({state_id:state?.state_id,city_id:city?.city_id,zip:zip.toString()})}
                                />
                              </button>
                            </div>
                          ))
                        ) : (
                          <div className="badge me-1 pe-1 justify-content-between border-b me-1 pe-1 justify-content-between !bg-white !text-black !mr-2 my-0.5 h-[26.39px]">
                            {city?.zips}
                            <button className="border-0 bg-transparent ps-auto pe-0">
                              <RxCrossCircled
                                size={20}
                                className="ml-1 cursor-pointer text-black"
                                onClick={() => handleRemoveCity(city.city_id)}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </details>
        ))}
      </div>
      <div className="flex justify-center mt-1">
        <Button text="Submit" variant="btn_submit" onClick={handleSubmit} />
      </div>
    </>
  );
};

export default UpdateVendorsIndustry;
