import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_decline_reasons,
  update_decline_reasons,
} from "../../features/declineReasonSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.declineReason);
  const [errors, setErrors] = React.useState([]);

  const type = [
    { value: "Lead", label: "Lead" },
    { value: "Quote", label: "Quote" },
    { value: "Retention Status", label: "Retention Status" },
  ];
  let initialValues = {
    reason: "",
    type: "",
  };

  if (editingRecord) {
    const { _id, reason, type } = editingRecord;
    initialValues = {
      id: _id,
      reason,
      type,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_decline_reasons(values));
          if (res?.payload?.status === 200) {
            toast.success("Decline Reason created");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Decline Reason", msg:'created'})
          }
        } catch (error) {
          toast.error("Decline Reason couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_decline_reasons(values));
          if (res?.payload?.status === 200) {
            toast.success("Decline Reason updated");
            onCancelForm();
          } else {
            errorMessage({payload:res.payload, setErrors:setErrors, action:"Decline Reason", msg:'update'})
          }
        } catch (error) {
          toast.error("Decline Reason couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="mb-3 mt-4">
          <FormInput errors={errors} name="reason" label="Name" formik={formik} />

          </div>
          <div>
          <FormSelectInput errors={errors}
            name="type"
            label="Type"
            formik={formik}
            options={type}
            valueProp="value"
            labelProp="label"
          />
          </div>
          
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
