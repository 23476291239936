import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../components";
import {
  delete_location,
  get_assigned_domain,
} from "../../features/geoLocSlice";

const AssignedDomains = ({ id, onClosemodal }) => {
  const [assignedDomains, setAssignedDomains] = React.useState();
  const dispatch = useDispatch();
  const close = (e) => {
    if (e.keyCode === 27) {
      onClosemodal();
    }
  };
  window.addEventListener("keydown", close);
  const getAssignedDomains = async () => {
    try {
      const domain = await dispatch(get_assigned_domain(id));
      if (domain?.payload?.status === 200) {
        setAssignedDomains(domain.payload.data);
      }
    } catch (err) {
      console.error(
        "🚀 ~ file: AssignedDomains.jsx:20 ~ getAssignedDomains ~ err:",
        err
      );
    }
  };
  React.useEffect(() => {
    getAssignedDomains();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDelete = async () => {
    try {
      const res = await dispatch(delete_location(id));
      if (res?.payload?.status === 200) {
        window.location.reload();
        toast.success("Location delete successfully");
      }
    } catch (err) {
      toast.error("Location delete");
      console.error("🚀 ~ file: GeoLocation.jsx:331 ~ handleDelete ~ err:", err);
    }
  };
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
      <div className="container-fluid mt-2 text-center ">
        <div className="inline-block bg-white w-2/4 my-0 mx-auto !p-5 bg-red-600 rounded max-h-[87vh]">
          <div className="flex items-center justify-center  text-red-600 h-[50px] mb-2 text-normal">
            <span className="text-lg font-semibold">Note: </span>This location
            have these domains, are you sure want to delete this location?
          </div>

          <div className="pb-3 font-medium text-base text-right ">
            Total {assignedDomains?.length} Records
          </div>

          <ul className="overflow-y-auto max-h-[55vh]  mb-2">
            {assignedDomains?.map(({ domain }, index) => {
              return (
                <li
                  key={index}
                  className="border-b-[1px] border-slate-200 p-3 list-decimal text-left"
                >
                  {domain}
                </li>
              );
            })}
          </ul>

          <Button
            text="Cancel"
            variant="btn_cancel"
            className="ml-3"
            onClick={onClosemodal}
          />
          <Button
            text="Delete"
            variant="btn_submit"
            className="ml-3"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignedDomains;
