import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  delete_inbound_group,
  get_inbound_group,
  inboundReset,
} from "../../../features/inboundGroupSlice";
import PageHeader from "../../../components/molecules/PageHeader";
import userPermission from "../../../util/userPermission";
import errorMessage from "../../../util/errorMessage";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
const InboundGroup = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.inbound);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_inbound_group(record_id));
        if (res.payload?.status === 200) {
          toast.success("Ring Group deleted");
          setNewRecord(!newRecord);
        } else {
          errorMessage({
            payload: res.payload,
            action: "Ring Group",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Ring Group",
          msg: "deleted",
        });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_inbound_group());
    return () => {
      dispatch(inboundReset());
    };
    // eslint-disable-next-line
  }, [newRecord]);
  const inboundFilter = record?.filter(
    (inbound) =>
      inbound?.group_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      inbound?.group_id?.toLowerCase().includes(searchText.toLowerCase())
  );
  const columnDefs = [
    { headerName: "#", field: "counter" },
    { headerName: "Group Id", field: "group_id", flex: 1 },
    { headerName: "Group Name", field: "group_name", flex: 1 },
    {
      headerName: "Group Color",
      field: "group_color",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div
          className="w-9 h-6 rounded"
          style={{ backgroundColor: `${params.row.group_color}` }}
        />
      ),
    },
    { headerName: "Queue Priority", field: "queue_priority", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records.active &&
        !params.row.records?.group_id.includes(
          "AGENTDIRECT" || "AGENTDIRECT_CHAT"
        ) ? (
          <div>
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Ingroup"
            />
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records.group_id)}
              className="my_navIcon"
              title="Delete Ingroup"
            />
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Ring Group"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
        />
      )}
      <PageHeader
        heading="Ring Group Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Inbound")}
        breadcrumb="/callcenter/ring_groups"
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={inboundFilter?.map((record, index) => {
            let counter = index + 1;
            let status = record.active === "Y" ? "Active" : "InActive";
            const { group_color, group_id, group_name, queue_priority } =
              record;
            return {
              counter,
              records: { ...record, _id: group_id },
              group_id,
              group_name,
              queue_priority,
              status,
              group_color,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="client"
        />
      </div>
    </>
  );
};

export default InboundGroup;
