import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { Calendar } from "react-calendar";

function PaymentDetail(props) {
  const { formik } = props;
  const calendarRef = useRef(null);
  const [date, setDate] = useState(false);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setDate(false);
    }
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setDate(false);
    }
  };

  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <>
      <h5 className="!mb-2">Payment Detail</h5>
      <div className=" grid-view overflow-scroll">
        <div className="!contents w-full table-responsive">
          <table className="items table table-striped table-bordered w-full">
            <thead>
              <tr>
                <th className="border">Tech Paid</th>
                <th className="border">Paid On</th>
                <th className="border">Vendor Payment ($)</th>
                <th className="border">Sub Total ($)</th>
                <th className="border">Refund Amount</th>
                <th className="border">Discount ($)</th>
                <th className="border">Client Paid ($)</th>
                <th className="border">Cr. Card Fee ($)</th>
                <th className="border">Net Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="relative">
                <td>
                  <input
                    formik={formik}
                    name="tech_fee"
                    style={{
                      width: 100,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    value={formik.values.tech_fee}
                    placeholder="0.00"
                    onChange={formik.handleChange}
                    type="text"
                    autoComplete="off"
                    readOnly
                  />
                </td>

                <td onClick={() => setDate(true)} style={{ cursor: "pointer" }}>
                  {formik.values.tech_paid_on
                    ? dayjs(formik.values.tech_paid_on).format("MMM D, YYYY")
                    : "Paid on"}
                  {date && (
                    <div
                      style={{
                        // top: 40,
                        position: "absolute",
                        zIndex: 18,
                        marginLeft: -120,
                      }}
                      // className="absolute"
                      ref={calendarRef}
                    >
                      <Calendar
                        onChange={(date) => {
                          formik.setFieldValue("tech_paid_on", date);
                          setDate(false);
                        }}
                      />
                    </div>
                  )}
                </td>
                <td>
                  <span>
                    {formik.values.vendor_pay
                      ? typeof formik.values.vendor_pay === "number"
                        ? formik.values.vendor_pay.toFixed(2)
                        : formik.values.vendor_pay
                      : 0}
                  </span>
                </td>
                <td id="cTechFeeT">
                  {+formik.values.tech_fee ? formik.values.tech_fee : 0.0}
                </td>
                <td id="cTechFeeT">
                  {+formik.values.refund_amount
                    ? formik.values.refund_amount
                    : 0.0}
                </td>
                <td className="cAmtClass">
                  <input
                    formik={formik}
                    name="discount"
                    style={{
                      width: 100,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    value={formik.values.discount ? formik.values.discount : ""}
                    placeholder="0.00"
                    onChange={formik.handleChange}
                    type="text"
                    autoComplete="off"
                  />
                </td>
                <td className="cAmtClass">
                  {(
                    +formik.values.service_cost - formik.values.tech_fee
                  )?.toFixed(2)}
                </td>
                <td id="cTax">
                  <input
                    formik={formik}
                    name="cc_fee"
                    style={{
                      width: 100,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    value={formik.values.cc_fee ? formik.values.cc_fee : ""}
                    placeholder="0.00"
                    onChange={formik.handleChange}
                    type="text"
                    autoComplete="off"
                    readOnly
                  />
                </td>
                <td id="cTax">{formik.values.tax ? formik.values.tax : 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default PaymentDetail;
