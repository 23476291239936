import React from "react";
import { FormInput, FormSelectInput, Modal } from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_inbound_group,
  update_inbound_group,
} from "../../../features/inboundGroupSlice";
import { toast } from "react-toastify";
function AddUpdateForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
}) {
  const { isLoading } = useSelector((state) => state.inbound);
  const [queuePriority, setQueuePriority] = React.useState();
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    group_name: "",
    group_color: "Green",
    active: "Y",
    queue_priority: "0",
    drop_call_seconds: "3600",
    icbq_call_time_id: "24hours",
    ...(editingRecord && {
      id: editingRecord.group_id,
      group_name: editingRecord.group_name,
      group_color: editingRecord?.group_color?.toLowerCase()==="green"?"#008000":editingRecord.group_color,
      active: editingRecord.active,
      queue_priority: editingRecord.queue_priority,
      drop_call_seconds: editingRecord.drop_call_seconds,
      icbq_call_time_id: editingRecord.icbq_call_time_id,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_inbound_group : create_inbound_group;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Inbound Group successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Inbound Group couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Inbound Group couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  React.useEffect(() => {
    const priority = [];
    for (let i = 99; i >= -99; i--) {
      const label =
        i === 0
          ? `${i} - Even`
          : i > 0
          ? `${i} - Higher`
          : i < 0
          ? `${i} - Lower`
          : "";
      priority.push({ label, value: i });
    }
    setQueuePriority(priority);
  }, []);

  return (
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        <form className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="group_name"
              label="Group Name"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="group_color"
              label="Group Color"
              formik={formik}
              type="color"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={[
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="queue_priority"
              label="Queue Priority"
              options={queuePriority}
              formik={formik}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-3">
            <FormInput
              errors={errors}
              name="drop_call_seconds"
              label="Drop Call Seconds"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
  );
}

export default AddUpdateForm;
