import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddUpdateDomainRunners from "./AddUpdateDomainRunners";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../services/api";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_domain_runners } from "../../features/domainCenterSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import PageHeader from "../../components/molecules/PageHeader";
import { DotsLoader } from "../../components";

function DomainRunner() {
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchInput, setSearchInput] = React.useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const { isLoading, domainRunner } = useSelector(
    (state) => state.domainCenter
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  useEffect(() => {
    dispatch(get_industry_drd());
    dispatch(
      get_domain_runners({
        size: paginationModel.pageSize,
        page: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const setup_runners = async (id) => {
    const c = window.confirm(`Are you sure want to setup the runner?`);
    if (!c) return;
    setIsloading(true);
    try {
      const res = await api.post(
        `/api/domain_runners/runner_setup_process/${id}`
      );
      if (res.status === 200) {
        toast.success("Runner setup successfully");
      } else {
        toast.error(`Runner couldn't be setup`);
      }
      setIsloading(false);
    } catch (err) {
      console.log(
        "🚀 ~ file: index.jsx:42 ~ constsetup_runners=async ~ err:",
        err
      );
      setIsloading(false);
      toast.error(`Runner couldn't be setup`);
    }
  };
  const columnDefs = [
    // Define your columns based on the structure of your domain runners
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "IP", field: "ip", flex: 1 },
    { headerName: "Runner Name", field: "runner_name", flex: 1 },
    {
      field: "industry_id",
      column: "industry_id",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params.row?.industry_id,
      valueFormatter: (params) => {
        const industry = industryDrd.find((ind) => ind._id === params.value);
        return industry ? industry.name : params.value;
      },
    },
    { headerName: "PhpMyAdmin Path", field: "phpmyadmin_path", flex: 1 },
    { headerName: "Runner Path", field: "runner_path", flex: 1 },
    { headerName: "Table Name", field: "table_name", flex: 1 },
    { headerName: "LB Server File", field: "lb_server_file", flex: 1 },
    {
      headerName: "Runner Setup",
      field: "setup_status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row?.setup_status === "Pending" ? (
          <span
            onClick={() => setup_runners(params.row.records?.id)}
            className="underline text-blue-600 cursor-pointer"
          >
            Setup
          </span>
        ) : null,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon cursor-pointer"
            size={20}
          />
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 10,
      },
    },
  ];
  const data = domainRunner?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_domain_runners({
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  return (
    <>
      {isEditing && (
        <AddUpdateDomainRunners
          editingRecord={editingRecord}
          modalTitle="Add Domain Runner"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoading || loading ? <DotsLoader text="Please Wait" /> : null}
      <PageHeader
        heading="Domain Runners"
        onClick={openFormHandler(0)}
        isAllowed={true}
      />
      {!isLoading && (
        <div className="bg-white mt-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={
              data
                ? data.map((runner, index) => ({
                    ...runner,
                    records: { ...runner },
                    counter: offset + index + 1,
                    // Additional formatting if needed
                  }))
                : []
            }
            isLoading={isLoading}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            pagination="Yes"
            totalItems={domainRunner?.totalItems}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </>
  );
}

export default DomainRunner;
