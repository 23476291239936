import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import GoogleConsoleForm from "./GoogleConsoleForm";
import userPermission from "../../util/userPermission";
import {
  delete_gsc_email_accounts,
  get_gsc_email_accounts,
  server_deployment,
} from "../../features/googleEmailSlice";
import { BsThreeDotsVertical } from "react-icons/bs";

const GoogleEmailAccounts = () => {
  const dispatch = useDispatch();
  const { isLoading, emailAccounts } = useSelector(
    (state) => state.googleEmails
  );
  const optionsRef = React.useRef();
  const [searchText, setSearchText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_gsc_email_accounts());
    // eslint-disable-next-line
  }, []);
  const googleConsoles = emailAccounts?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_gsc_email_accounts(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Server deleted successfully");
          dispatch(get_gsc_email_accounts());
        } else {
          errorMessage({
            payload: res.payload,
            action: "Server",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Google Search Console Account",
          msg: "deleted",
        });
      }
    }
  };
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Refresh Token URL copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const handleServerDeployment = async ({ type, server_id }) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setShowMenu(false);
    setIsLoader(true);
    try {
      const res = await dispatch(server_deployment({ type, server_id }));
      if (res?.payload?.status === 200) {
        toast.success("Process completed successfully");
      } else {
        toast.error(res?.payload || "Process couldn't be completed");
      }
    } catch (err) {
      toast.error(err.payload || "Process couldn't be completed");
      console.log("👊 ~ handleServerDeployment ~ err:", err);
    } finally {
      setIsLoader(false);
    }
  };
  const testMenu = [
    {
      label: "Edit server",
      action: (records) => openFormHandler(records),
      getIsHide: (records) => false,
    },
    {
      label: "Delete server",
      action: (records) => deleteRecordHandler(records?._id),
      getIsHide: (records) => false,
    },
    {
      label: "Copy Refresh Token URL",
      action: (records) => handleCopy(records?.refresh_token_url),
      getIsHide: (records) => false,
    },
    {
      label: "Prepare Server",
      action: (records) =>
        handleServerDeployment({
          server_id: records._id,
          type: "prepare_server",
        }),
      getIsHide: (records) => records?.server_ready,
    },
    {
      label: "Deploy App",
      action: (records) =>
        handleServerDeployment({ server_id: records._id, type: "deploy_app" }),
      getIsHide: (records) => !records?.server_ready,
    },
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "Server IP", field: "server_ip", flex: 1, minWidth: 120 },
    { headerName: "App URL", field: "app_url", flex: 1, minWidth: 120 },
    { headerName: "Email", field: "email", flex: 1, minWidth: 120 },

    {
      headerName: "Domain Count",
      field: "domain_count",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Domain Limit",
      field: "domain_limit",
      flex: 1,
      minWidth: 120,
    },

    { headerName: "Client ID", field: "client_id", flex: 1, minWidth: 120 },
    {
      headerName: "Client Secret",
      field: "client_secret",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Refresh Token",
      field: "refresh_token",
      flex: 1,
      minWidth: 120,
    },

    // { headerName: "Password", field: "email_password", flex: 1, minWidth: 100 },
    // { headerName: "Server User", field: "server_user", flex: 1, minWidth: 120 },
    // {
    //   headerName: "Server Ready",
    //   field: "server_ready",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "App Deployed",
    //   field: "app_deployed",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Email User",
    //   field: "email_user_name",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Proxy User",
    //   field: "proxy_user",
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Mobile Number",
    //   field: "mobile_num",
    //   flex: 1,
    //   minWidth: 120,
    // },
    // {
    //   headerName: "Mobile Carrier",
    //   field: "mobile_carrier",
    //   flex: 1,
    //   minWidth: 120,
    // },

    {
      headerName: "Action",
      field: "client_sec",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          />
          {showMenu === params.row.records?._id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  const hide = option?.getIsHide(params.row.records);
                  return !hide ? (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : null}
          {/* <div className="flex flex-row">
                <FaEdit
                  onClick={openFormHandler(params.row.records)}
                  className="my_navIcon"
                  title="Update Server"
                />
                <FaTrashAlt
                  onClick={deleteRecordHandler(params.row.records._id)}
                  className="my_navIcon"
                  title="Delete Server"
                />
                <FaCopy
                  onClick={() =>
                    handleCopy(params?.row?.records?.refresh_token_url)
                  }
                  className="my_navIcon"
                  title="Copy Refresh Token URL"
                />
              </div> */}
        </div>
      ),
    },
  ];
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  return (
    <>
      <PageHeader
        heading="Server List"
        onClick={() => openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
      />
      {isEditing && (
        <GoogleConsoleForm
          editingRecord={editingRecord}
          modalTitle="Add Server"
          onCancelForm={cancelFormHandler}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={googleConsoles?.map((record, index) => {
            let counter = index + 1;
            const {
              server_ip,
              app_url,
              email,
              email_password,
              server_user,
              proxy_user,
              mobile_num,
              mobile_carrier,
              email_user_name,
              app_deployed,
              server_ready,
              domain_count,
              domain_limit,
              client_id,
              client_secret,
              refresh_token,
            } = record;
            return {
              counter,
              records: { ...record },
              server_ip,
              app_url,
              email,
              email_password,
              server_user,
              proxy_user,
              mobile_num,
              mobile_carrier,
              email_user_name,
              app_deployed: app_deployed ? "Yes" : "No",
              server_ready: server_ready ? "Yes" : "No",
              domain_count,
              domain_limit,
              client_id,
              client_secret,
              refresh_token,
            };
          })}
          totalItems={emailAccounts?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading || isLoader}
        />
      </div>
    </>
  );
};

export default GoogleEmailAccounts;
