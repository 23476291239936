import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createPurchaseRegistrarAccount,
  createRegistrarAccount,
  deletePurchaseRegistrarAccount,
  getPurchaseRegistrarAccounts,
  getRegistrarAccounts,
  updatePurchaseRegistrarAccount,
  updateRegistrarAccount,
} from "../services/purcahseRegistrarAccountsService";
const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  record: [],
  registrarAccount: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Industry Data
export const get_purcahse_registrar_account = createAsyncThunk(
  "get_purcahse_registrar_account",
  async (thunkAPI) => {
    try {
      return await getPurchaseRegistrarAccounts();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_purcahse_registrar_account = createAsyncThunk(
  "create_purcahse_registrar_account",
  async (data, thunkAPI) => {
    try {
      return await createPurchaseRegistrarAccount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_purcahse_registrar_account = createAsyncThunk(
  "update_purcahse_registrar_account",
  async (data, thunkAPI) => {
    try {
      return await updatePurchaseRegistrarAccount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Industry
export const delete_purcahse_registrar_account = createAsyncThunk(
  "delete_purcahse_registrar_account",
  async (id, thunkAPI) => {
    try {
      return await deletePurchaseRegistrarAccount(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Industry Data
export const get_registrar_account = createAsyncThunk(
  "get_registrar_account",
  async (thunkAPI) => {
    try {
      return await getRegistrarAccounts();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Industry
export const create_registrar_account = createAsyncThunk(
  "create_registrar_account",
  async (data, thunkAPI) => {
    try {
      return await createRegistrarAccount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Industry
export const update_registrar_account = createAsyncThunk(
  "update_registrar_account",
  async (data, thunkAPI) => {
    try {
      return await updateRegistrarAccount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registrarAccountsSlice = createSlice({
  name: "registrarAccounts",
  initialState,
  reducers: {
    registrarResetAccount: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_purcahse_registrar_account.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_purcahse_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_purcahse_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_purcahse_registrar_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_purcahse_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_purcahse_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_purcahse_registrar_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_purcahse_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_purcahse_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_purcahse_registrar_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_purcahse_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_purcahse_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_registrar_account.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrarAccount = action.payload?.data;
      })
      .addCase(get_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })

      .addCase(create_registrar_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        state.registrarAccount.push(action.payload.data[0]);
      })
      .addCase(create_registrar_account.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_registrar_account.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_registrar_account.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.registrarAccount.findIndex(
          (account) => account.id === action?.payload?.data[0]?.id
        );
        state.registrarAccount[result] = {
          ...state.registrarAccount[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_registrar_account.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { registrarResetAccount } = registrarAccountsSlice.actions;
export default registrarAccountsSlice.reducer;
