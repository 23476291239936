import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { clearDistance, get_distance } from "../../features/geoLocSlice";
import Select from "react-select";
function DistanceTable() {
  const { distance } = useSelector((state) => state.geoLoc);
  const vendors = useSelector((state) => state.geoVendors);
  const refTags = React.useRef();
  const array = [
    { label: "State", value: "state" },
    { label: "City", value: "city" },
    { label: "Zip", value: "zip" },
    { label: "City Alias", value: "city_alias" },
  ];
  const array2 = [
    { label: "Find Distance", value: "isDistance" },
    { label: "Find Difference", value: "isDifference" },
  ];
  const initialCheckDistance = {
    from_type: "",
    from_state_id: "",
    to_type: "",
    to_state_id: "",
    from_locations: "",
  };
  const dispatch = useDispatch();
  const [toLocations, setToLocations] = useState([]);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [notInList1, setNotInList1] = useState([]);
  const [notInList2, setNotInList2] = useState([]);
  const [checkDataPayload, setCheckDataPayload] =
    useState(initialCheckDistance);
  const [checkData, setCheckData] = useState("isDistance");
  const onGetDistance = async () => {
    if (
      ((checkDataPayload.from_type === "city" ||
        checkDataPayload.from_type === "city_alias") &&
        !checkDataPayload.from_state_id) ||
      ((checkDataPayload.to_type === "city" ||
        checkDataPayload.to_type === "city_alias") &&
        !checkDataPayload.to_type)
    ) {
      toast.error("Please select a state");
    } else {
      try {
        const validate = await dispatch(
          get_distance({ ...checkDataPayload, to_locations: toLocations })
        );
        if (validate?.payload?.status !== 200)
          toast.error(validate?.payload ? validate?.payload : "Error found");
      } catch (err) {
        console.error(
          "🚀 ~ file: GeoLocation.jsx:317 ~ onValidate ~ err:",
          err
        );
      }
    }
  };
  const onGetDifference = async () => {
    const data1Lower = list1.map((str) => str.toLowerCase());
    const data2Lower = list2.map((str) => str.toLowerCase());
    const notInData1 = data2Lower
      .filter((item) => !data1Lower.includes(item))
      .map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });

    const notInData2 = data1Lower
      .filter((item) => !data2Lower.includes(item))
      .map((str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      });
    if (notInData1?.length === 0 && notInData2?.length === 0) {
      toast.info("All locations are same...");
    }
    setNotInList1(notInData1);
    setNotInList2(notInData2);
  };
  return (
    <div className="container-fluid mt-2">
      <div className="items-center m-2 bg-slate-50 !my-3 border rounded p-3">
        <div className="audio-greets-tabs my-2 h-[40px]">
          <ul className="!pl-0 flex items-center">
            {array2?.map((val, index) => {
              return (
                <li className="active tab-li no-underline">
                  <Link
                    onClick={() => setCheckData(val.value)}
                    className={
                      val.value === checkData
                        ? "no-underline active"
                        : "no-underline"
                    }
                  >
                    {val.label}
                  </Link>
                </li>
              );
            })}
            {toLocations?.length > 0 && checkData === "isDistance" && (
              <div className="flex justify-end w-[60%]">
                <button
                  className="border rounded bg-red-600 text-white p-2 ml-2"
                  onClick={() => {
                    setCheckDataPayload(initialCheckDistance);
                    setToLocations([]);
                    dispatch(clearDistance());
                  }}
                >
                  Clear Filter
                </button>
              </div>
            )}
            {(list1?.length > 0 || list2.length > 0) &&
            checkData === "isDifference" ? (
              <div className="flex justify-end w-[60%]">
                <button
                  className="border rounded bg-red-600 text-white p-2 ml-2"
                  onClick={() => {
                    setList1([]);
                    setNotInList1([]);
                    setList2([]);
                    setNotInList2([]);
                  }}
                >
                  Clear
                </button>
              </div>
            ) : null}
          </ul>
        </div>
        {checkData === "isDistance" ? (
          <>
            <div className="flex flex-row mb-2">
              <div className="w-full">
                <label className="inputLabel ml-2" htmlFor="location">
                  {"From Type"}
                </label>
                <Select
                  name="type"
                  placeholder="Select Type"
                  onChange={(e) =>
                    setCheckDataPayload({
                      ...checkDataPayload,
                      from_type: e.value,
                    })
                  }
                  options={array}
                  valueKey="value"
                  labelKey="label"
                  className="w-full"
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={
                    checkDataPayload.from_type
                      ? {
                          value: checkDataPayload.from_type,
                          label: array?.find(
                            ({ value }) => value === checkDataPayload.from_type
                          )?.label,
                        }
                      : null
                  }
                />
              </div>
              {checkDataPayload?.from_type === "city" ||
              checkDataPayload?.from_type === "city_alias" ? (
                <div className="w-full ml-2">
                  <label className="inputLabel ml-2" htmlFor="location">
                    {"From States"}
                  </label>
                  <Select
                    name="state"
                    placeholder="Select State"
                    onChange={(e) =>
                      setCheckDataPayload({
                        ...checkDataPayload,
                        from_state_id: e.id,
                      })
                    }
                    options={vendors?.states}
                    valueKey="value"
                    labelKey="label"
                    className="w-full"
                    menuPosition="fixed"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={
                      checkDataPayload.from_state_id
                        ? {
                            id: checkDataPayload.from_state_id,
                            name: vendors?.states?.find(
                              ({ id }) => id === checkDataPayload.from_state_id
                            )?.name,
                          }
                        : null
                    }
                  />
                </div>
              ) : null}
              <div className="w-full ml-2">
                <label className="inputLabel ml-2" htmlFor="location">
                  {"To Type"}
                </label>
                <Select
                  name="type"
                  placeholder="Select Type"
                  onChange={(e) =>
                    setCheckDataPayload({
                      ...checkDataPayload,
                      to_type: e.value,
                    })
                  }
                  options={array}
                  valueKey="value"
                  labelKey="label"
                  className="w-full"
                  menuPosition="fixed"
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={
                    checkDataPayload.to_type
                      ? {
                          value: checkDataPayload.to_type,
                          label: array?.find(
                            ({ value }) => value === checkDataPayload.to_type
                          )?.label,
                        }
                      : null
                  }
                />
              </div>
              {checkDataPayload?.to_type === "city" ||
              checkDataPayload?.to_type === "city_alias" ? (
                <div className="w-full ml-2">
                  <label className="inputLabel ml-2" htmlFor="location">
                    {"To States"}
                  </label>
                  <Select
                    name="state"
                    placeholder="Select State"
                    onChange={(e) =>
                      setCheckDataPayload({
                        ...checkDataPayload,
                        to_state_id: e.id,
                      })
                    }
                    options={vendors?.states}
                    valueKey="value"
                    labelKey="label"
                    className="w-full"
                    menuPosition="fixed"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={
                      checkDataPayload.to_state_id
                        ? {
                            id: checkDataPayload.to_state_id,
                            name: vendors?.states?.find(
                              ({ id }) => id === checkDataPayload.to_state_id
                            )?.name,
                          }
                        : null
                    }
                  />
                </div>
              ) : null}
              <div className="w-full ml-2">
                <label className="inputLabel ml-2" htmlFor="miles">
                  {"From Location"}
                </label>
                <input
                  placeholder="Location"
                  onChange={(e) => {
                    setCheckDataPayload({
                      ...checkDataPayload,
                      from_locations: e.target.value,
                    });
                  }}
                  className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  value={checkDataPayload?.from_locations}
                />
              </div>
            </div>
            <div className="flex flex-row" ref={refTags}>
              <TagsInput
                tags={toLocations}
                setTags={setToLocations}
                placeholder={"To locations"}
              />
              <button
                className="ml-2 btn btn-outline-secondary h-[40.39px]"
                type="button"
                data-testid="button-clearAll"
                onClick={onGetDistance}
              >
                {checkData === "isDistance" ? "Get Distance" : "Get Difference"}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row">
              <TagsInput tags={list1} setTags={setList1} />
              <TagsInput tags={list2} setTags={setList2} className="ml-2" />
            </div>
            {notInList2?.length > 0 || notInList1?.length > 0 ? (
              <div className="flex flex-row">
                <div className="w-1/2">
                  <span className="font-semibold ml-3">
                    These locations are not in second list{" "}
                  </span>
                  <span className="font-normal ">
                    {`(${notInList2?.length} found)`}{" "}
                  </span>
                  <textarea
                    className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                    value={notInList2}
                    rows={4}
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <span className="font-semibold ml-3">
                    These locations are not in first list{" "}
                  </span>
                  <span className="font-normal ">
                    {`(${notInList1?.length} found)`}{" "}
                  </span>
                  <textarea
                    className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                    value={notInList1}
                    rows={4}
                  />
                </div>
              </div>
            ) : null}

            <div className="flex w-full justify-end">
              <button
                className="mt-2 btn btn-outline-secondary h-[40.39px]"
                type="button"
                data-testid="button-clearAll"
                onClick={onGetDifference}
              >
                {checkData === "isDistance" ? "Get Distance" : "Get Difference"}
              </button>
            </div>
          </>
        )}
      </div>
      {distance?.length > 0 && checkData === "isDistance" && (
        <div className="table-responsive max-h-[60vh] mb-2">
          <table className="table table-striped table-bordered">
            <thead className="sticky top-0 bg-white border-t border-[#ccc]">
              <tr className='className="bg-white border-t border-[#ccc]'>
                <th className="sticky">#</th>
                <th className="sticky">Name</th>
                <th className="sticky">Distance in Miles</th>
                <th className="sticky">Latitude</th>
                <th className="sticky">Longitude</th>
                <th className="sticky">Alias</th>
              </tr>
            </thead>

            <tbody className=" max-h-[50vh]  mb-2 ">
              {distance?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b-[1px] border-slate-200 p-4  "
                  >
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>
                      {Math.round(data.distanceInMeters * 0.000621371192)}
                    </td>
                    <td>{data.latitude}</td>
                    <td>{data.longitude}</td>
                    <td>{data.alias}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default DistanceTable;
