import React, { memo, useMemo, useState } from "react";

import { DotsLoader, FormSelectInput } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import { get_industry, industryReset } from "../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import LeadJobListModal from "./LeadJobList";
import { TbMailForward } from "react-icons/tb";
import {
  MdLock,
  MdLockOpen,
  MdLockOutline,
  MdOutlineDelete,
  MdOutlineDownload,
  MdOutlinePayments,
} from "react-icons/md";
import { toast } from "react-toastify";
import CustomModal from "./Modal";
import { get_crm_vendors_drd, get_users_drd } from "../../features/usersSlice";
import SendEmailModal from "./SendEmailModal";
import Picker from "../Components/Picker";
import LockedInvoiceModal from "./ToLockInvoiceModal";
import { NumericFormat } from "react-number-format";
import VendorBalance from "./VendorBalance";
function InvoiceReport() {
  const { usersDrd } = useSelector((state) => state.users);
  const { CRMVendorsDrd } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [isModal, setIsModal] = React.useState(null);
  const [isLocked, setIsLocked] = React.useState(null);

  const [sendInvoiceModal, setSendInvoiceModal] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [jobRecord, setJobRecords] = useState([]);

  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  // Function to get ISO week number for a date
  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };
  const [selectedWeek, setSelectedWeek] = useState(getISOWeek(new Date()));
  // Function to get date range for a week
  const getDateRangeForWeek = (year, week) => {
    const startDate = new Date(year, 0, (week - 1) * 7 + 1);
    const endDate = new Date(year, 0, week * 7, 23, 59, 59, 59);
    return { startDate, endDate };
  };

  // Function to generate weeks of the year along with date ranges
  const generateWeeks = (year) => {
    const weeks = [];
    for (let week = 1; week <= 53; week++) {
      const { startDate, endDate } = getDateRangeForWeek(year, week);
      weeks.push({
        weekName: `Week ${week}: ${startDate.toDateString()} - ${endDate.toDateString()}`,
        weekNumber: week,
        startDate,
        endDate,
      });
    }
    return weeks;
  };
  const currentYear = new Date().getFullYear();
  // Generate weeks for the current year
  const weeksOfYear = generateWeeks(currentYear);
  const selectedWeekData = weeksOfYear.find(
    (week) => week.weekNumber === selectedWeek
  );
  const [dateRange, setDateRange] = React.useState({
    // from_date: dayjs()
    //   .subtract(6, "month")
    //   .set("date", dayjs().date())
    //   .toDate(),
    // to_date: new Date(),

    // from_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
    // to_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
    end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    // to_date: null,
    // from_date: null,
  });

  const [dateFilter, setDateFilter] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "createdAt",
        op: "gte",
        data: dateRange?.start_date,
      },
      {
        field: "createdAt",
        op: "lte",
        data: dateRange?.end_date,
      },
    ],
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPReport = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("api/reports/invoice_report", filter);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: {
        ...queryOptions,
        rules: [...queryOptions?.rules, ...dateFilter?.rules],
      },
    });
    get_all_jobs({
      size: paginationModel.pageSize,
      page: 1,
      ...dateRange,
    });
    dispatch(get_users_drd());
    dispatch(get_crm_vendors_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reloadReport = () => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
  };
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const paymentStatus = [
    { value: "Created", label: "Created" },
    { value: "Sent", label: "Sent" },
    { value: "Partially Paid", label: "Partially Paid" },
    { value: "Paid", label: "Paid" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const saveBlobAsPDFFile = (blobData, fileName) => {
    const downloadLink = window.URL.createObjectURL(
      new Blob([blobData], { type: "application/pdf" })
    );

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `${fileName}.pdf`); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const downloadInvoice = async (data) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        "/api/jobs/download_invoice",
        {
          invoice_id: data?._id,
        },
        {
          responseType: "blob", // Specify the response type as Blob
        }
      );
      if (res.status === 200) {
        const blobData = res.data;
        saveBlobAsPDFFile(blobData, data?.invoice_number);
        toast.success("Invoice downloaded successfully");
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be download");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be download"
      );
      console.log("🚀 ~ sendInvoice ~ err:", err);
    }
  };
  const deleteInvoice = async (id) => {
    const c = window.confirm("Are you sure want to delete this invoice?");
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.post("/api/jobs/delete_invoice", {
        invoice_id: id,
      });
      if (res.status === 200) {
        getSERPReport({
          ...paginationModel,
          ...sortingModel,
          size: paginationModel.pageSize,
          filters: queryOptions,
        });
        toast.success("Invoice deleted successfully");
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be deleted");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be deleted"
      );
      console.log("🚀 ~ sendInvoice ~ err:", err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Invoice No.",
      field: "invoice_number",
      flex: 1,
      renderCell: (params) => (
        <>
        <span
          onClick={() => downloadInvoice(params.row.records)}
          className="cursor-pointer hover:underline text-blue-600"
        >
          {params?.row?.invoice_number}
        </span>
        <span className="text-xs ml-1">{`(${params?.row?.status})`}</span>
        </>
      ),
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: "vendor_id",
      headerName: "Tech",
      type: "singleSelect",
      flex: 1,
      minWidth: 180,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: CRMVendorsDrd,
      renderCell: (params) => params.value?.vendor_id,
      valueGetter: (params) => params.row?.vendor_id,
      valueFormatter: (params) => params.value?.vendor_id,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      headerName: "Jobs",
      field: "leads",
      renderCell: (params) =>
        params?.row?.leads?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => setLeads(params?.row?.leads)}
          >
            {params?.row?.leads?.length}
          </span>
        ) : (
          <span>0</span>
        ),
      disableColumnMenu: true,
      minWidth: 80,
    },
    {
      headerName: "Service Cost",
      field: "total_service_cost",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Parts Charge",
      field: "total_parts_charge",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Net Amount",
      field: "net_amount",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Tech fee",
      field: "total_tech_fee",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      headerName: "Paid Amount",
      field: "paid_amount",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Balance",
      field: "balance",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
      renderCell: (params) => (
        <span>
          {(
            Math.round(
              params.row.total_payable_amount * 100 -
                params.row.paid_amount * 100
            ) / 100
          )?.toFixed(2)}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: paymentStatus,
      renderCell: (params) => params.value?.status,
      valueGetter: (params) => params.row?.status,
      valueFormatter: (params) => params.value?.status,
    },
    // {
    //   headerName: "Status",
    //   field: "status",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   minWidth: 120,
    // },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: usersDrd,
      renderCell: (params) => params.value?.createdBy,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 200,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      field: "actions",
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-row">
          <TbMailForward
            size={18}
            onClick={() => setSendInvoiceModal(params.row.records)}
            className="my_navIcon"
            title="Send Invoice"
          />
          {params?.row.status !== "Paid" ? (
            <MdOutlinePayments
              size={18}
              onClick={() => setIsModal(params.row.records)}
              className="my_navIcon"
              title="Make Payment"
            />
          ) : null}
          <MdOutlineDownload
            size={18}
            onClick={() => downloadInvoice(params.row.records)}
            className="my_navIcon"
            title="Download PDF Invoice"
          />
          <MdOutlineDelete
            size={18}
            onClick={() => deleteInvoice(params.row.records?._id)}
            className="my_navIcon"
            title="Delete Invoice"
          />
          {params.row.status !== "Locked" ? (
            <MdLockOpen
              size={18}
              onClick={() => setIsLocked(params.row.records)}
              className="my_navIcon"
              title="Lock this Invoice: Once the invoice is locked job amount cannot be changed."
            />
          ) : (
            <MdLock
              size={18}
              className="text-menu"
              title="Lock this Invoice: Once the invoice is locked job amount cannot be changed."
            />
          )}
        </div>
      ),
      filterable: false,
    },
  ];

  React.useEffect(() => {
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };

  function calculateSumsAndCounts(invoices) {
    if (memo.hasOwnProperty("sumsAndCounts") && memo.invoices === invoices) {
      return memo.sumsAndCounts;
    }
    let allInvoiceAmountSum = 0;
    let allInvoiceCount = 0;

    let totalPayableAmountSum = 0;
    let payableCount = 0;

    let paidAmountSum = 0;
    let paidCount = 0;
    let cancelledAndPartiallyPaidCount = 0;

    invoices?.forEach((invoice) => {
      allInvoiceAmountSum +=
        Math.round(invoice?.total_payable_amount * 100) / 100;
      allInvoiceCount++;

      if (invoice.status !== "Paid" && invoice.status !== "Cancelled") {
        totalPayableAmountSum +=
          Math.round(
            invoice?.total_payable_amount * 100 - invoice?.paid_amount * 100
          ) / 100;
        payableCount++;
      }
      if (invoice.status === "Paid") {
        paidAmountSum += Math.round(invoice?.paid_amount * 100) / 100;
        paidCount++;
      }
      if (invoice.status === "Cancelled") {
        cancelledAndPartiallyPaidCount++;
      }
    });

    // Store results in memo object
    memo.invoices = invoices;
    memo.sumsAndCounts = {
      allInvoiceAmountSum,
      allInvoiceCount,
      totalPayableAmountSum,
      payableCount,
      paidAmountSum,
      paidCount,
      cancelledAndPartiallyPaidCount,
    };

    return memo.sumsAndCounts;
  }
  const sumsAndCounts = useMemo(
    () => calculateSumsAndCounts(record?.data),
    [record?.data]
  );
  const handleFilterData = (status) => {
    let query = null;
    if (status === "All") {
      query = {
        groupOp: "AND",
        rules: [],
      };
      setSelectedFilter("All");
    } else if (status === "Unpaid") {
      query = {
        groupOp: "AND",
        rules: [
          {
            field: "status",
            op: "not",
            data: "Paid",
          },
          {
            field: "status",
            op: "not",
            data: "Cancelled",
          },
        ],
      };
      setSelectedFilter("Unpaid");
    } else if (status === "total_jobs") {
      return setSelectedFilter(status);
    } else {
      query = {
        groupOp: "AND",
        rules: [
          {
            field: "status",
            op: "eq",
            data: status,
          },
        ],
      };
      setSelectedFilter(status);
    }

    setQueryOptions(query);
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPReport({
      ...sortingModel,
      filters: query,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const handleWeekChange = (e) => {
    const weekNumber = parseInt(e);
    setSelectedWeek(weekNumber);
    const selectedWeekData = weeksOfYear.find(
      (week) => week.weekNumber === weekNumber
    );
    setDateRange({
      // from_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      // to_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "createdAt",
            op: "gte",
            data: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
          },
          {
            field: "createdAt",
            op: "lte",
            data: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
          },
        ],
      },
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    if (selectedFilter !== "total_jobs") {
      get_all_jobs({
        size: paginationModel.pageSize,
        page: 1,
        start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
        end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
      });
    }
  };
  const get_all_jobs = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/reports/vendor_balance_report", {
        ...filter,
      });
      if (res.status === 200) {
        const dataArray = Object.entries(
          res.data?.data ? res.data?.data : {}
        ).map(([key, value]) => ({
          id: key, // Assuming you want to keep the original keys as IDs
          ...value,
        }));
        const sortedDataArray = [...(dataArray || [])]?.sort((a, b) =>
          a?.vendor?.first_name.localeCompare(b?.vendor?.first_name)
        );
        const rows = sortedDataArray?.flatMap((entry) => {
          const vendorName = `${entry.vendor?.first_name || ""} ${
            entry.vendor?.last_name || ""
          }`;
          const vendorId = entry.vendor._id;
          const summary = entry.summary;

          const vendorRow = {
            id: vendorId,
            path: [vendorName],
            ...entry.vendor, // Include all vendor details
            ...summary,
          };
          const jobs = entry.jobs.map((job) => ({
            id: job._id,
            path: [vendorName, job.job_number],
            ...job, // Include all job details
            summary: { ...summary },
          }));
          return [vendorRow, ...jobs];
        });

        const total_job_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.job_amount,
          0
        );
        const total_jobs = dataArray.reduce(
          (sum, item) => sum + item?.jobs?.length,
          0
        );
        setJobRecords({
          data: rows,
          totalItems: res.data.totalItems,
          summary: {
            total_jobs,
            total_job_amount,
          },
        });
      } else {
        toast.error(res.data.message || "Record couldn't be loaded");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || "Record couldn't be loaded");
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  const LineOne = () => {
    return (
      <div>
        <FormSelectInput
          name="industry_id"
          label="Select a week"
          value={selectedWeek}
          options={weeksOfYear}
          onChange={handleWeekChange}
          valueProp="weekNumber"
          labelProp="weekName"
        />
      </div>
    );
  };
  return (
    <>
      {sendInvoiceModal ? (
        <SendEmailModal
          invoiceDetail={sendInvoiceModal}
          onClose={() => setSendInvoiceModal(null)}
        />
      ) : null}

      <PageHeader
        route="Setting > Industries"
        heading="Vendors Invoice"
        // CustomButtons={LineOne}
      />
      {leads?.length ? (
        <LeadJobListModal onCancelForm={() => setLeads([])} data={leads} />
      ) : null}
      {isModal ? (
        <CustomModal
          onClose={() => setIsModal(null)}
          invoiceDetail={isModal}
          reloadReport={reloadReport}
        />
      ) : null}
      {isLocked ? (
        <LockedInvoiceModal
          onClose={() => setIsLocked(null)}
          invoiceDetail={isLocked}
          reloadReport={reloadReport}
        />
      ) : null}
      {isLoading && selectedFilter !== "total_jobs" ? <DotsLoader /> : null}
      <div className="grid grid-cols-8 gap-2 justify-between mx-0.5 flex items-cente">
        <div className="col-span-5 grid grid-cols-5 gap-2">
          <button
            className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === "All" ? "border-l-2 border-primary-100" : ""}
        `}
            onClick={() => handleFilterData("All")}
          >
            <span className="text-sm font-pop">All Invoices</span>
            <span className="text-base font-medium font-pop">
              {`${
                sumsAndCounts?.allInvoiceCount
              } ($${sumsAndCounts?.allInvoiceAmountSum?.toLocaleString()})`}
            </span>
          </button>
          {record?.summary?.map((item) => {
            const { _id, total_payable_amount, count } = item;
            return (
              <button
                className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === _id ? "border-l-2 border-primary-100" : ""}
        `}
                onClick={() => handleFilterData(_id)}
              >
                <span className="text-sm font-pop">{_id}</span>
                <span className="text-base font-medium font-pop">
                  {`${count} ($${total_payable_amount
                    ?.toFixed(2)
                    ?.toLocaleString()})`}
                </span>
              </button>
            );
          })}

          <button
            className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_jobs" ? "border-l-2 border-primary-100" : ""
        }
        `}
            onClick={() => handleFilterData("total_jobs")}
          >
            <span className="text-sm font-pop">Total Jobs</span>
            <span className="text-base font-medium font-pop">
              {Math.round((jobRecord?.summary?.total_jobs || 0) * 100) / 100} (
              <NumericFormat
                displayType="text"
                value={(
                  Math.round(
                    (jobRecord?.summary?.total_job_amount || 0) * 100
                  ) / 100
                ).toFixed(2)}
                prefix={`$`}
                thousandSeparator={true}
              />
              )
            </span>
          </button>
        </div>
        {/*  <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === "Paid" ? "border-l-2 border-primary-100" : ""}
        `}
          onClick={() => handleFilterData("Paid")}
        >
          <span className="text-sm font-pop">Paid Invoices</span>
          <span className="text-base font-medium font-pop">
            {`${
              sumsAndCounts?.paidCount
            } ($${sumsAndCounts?.paidAmountSum?.toLocaleString()})`}
          </span>
        </button>

        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${selectedFilter === "Cancelled" ? "border-l-2 border-primary-100" : ""}
        `}
          onClick={() => handleFilterData("Cancelled")}
        >
          <span className="text-sm font-pop">Cancelled Invoices</span>
          <span className="text-base font-medium font-pop">
            {sumsAndCounts?.cancelledAndPartiallyPaidCount?.toLocaleString()}
          </span>
        </button> */}

        <div className="flex col-span-3 items-center justify-end w-full">
          <LineOne />
          {/*<Picker
            filterData={dateRange}
            setFiltersData={setDateRange}
            rangeValue="6 Months"
            dropdown_label="Report by"
            valueKey={"report_by"}
            isDropdown={"No"}
            onChange={(e) => {
              setDateFilter({
                groupOp: "AND",
                rules: [
                  {
                    field: "createdAt",
                    op: "gte",
                    data: e?.from_date,
                  },
                  {
                    field: "createdAt",
                    op: "lte",
                    data: e?.to_date,
                  },
                ],
              });
            }}
            seachButtonOnClick={() =>
              getSERPReport({
                ...sortingModel,
                filters: dateFilter,
                page: 1,
                size: paginationModel.pageSize,
              })
            }
            seachButton={true}
          /> */}
        </div>
      </div>
      {selectedFilter === "total_jobs" ? (
        <VendorBalance
          get_all_jobs={get_all_jobs}
          filters={dateRange}
          record={jobRecord}
          setRecord={setJobRecords}
        />
      ) : (
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            columnDefs={columnDefs}
            items={batchList?.map((record, index) => {
              const counter = index + 1;
              const {
                description,
                invoice_number,
                status,
                leads,
                vendor_id,
                createdAt,
                createdBy,
                paid_amount = 0,
                total_payable_amount = 0,
                total_service_cost = 0,
                total_tech_fee = 0,
                total_parts_charge = 0,
              } = record;
              const { first_name: v_name = "", last_name: v_last = "" } =
                vendor_id;
              const { first_name = "", last_name = "" } = createdBy;
              return {
                records: { ...record },
                counter,
                description,
                invoice_number,
                status,
                leads,
                total_parts_charge: total_parts_charge ? total_parts_charge : 0,
                net_amount: (total_service_cost - total_parts_charge)?.toFixed(
                  2
                ),
                paid_amount: (paid_amount || 0)?.toFixed(2),
                total_payable_amount: (total_payable_amount || 0)?.toFixed(2),
                total_service_cost: (total_service_cost || 0)?.toFixed(2),
                total_tech_fee: (total_tech_fee || 0)?.toFixed(2),
                vendor_id: `${v_name} ${v_last}`,
                createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
                createdBy: `${first_name} ${last_name}`,
                company_profit: (
                  total_service_cost -
                  total_parts_charge -
                  total_tech_fee
                )?.toFixed(2),
              };
            })}
            searchText={searchText}
            setSearchText={setSearchText}
            paginationModel={paginationModel}
            totalItems={record?.totalItems}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onFilterModelChange={onFilterChange}
            CustomToolbar={CustomToolbar}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
          />
        </div>
      )}
    </>
  );
}

export default InvoiceReport;
