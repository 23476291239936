import React, { useEffect } from "react";
import { Button } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  get_did_drd,
  get_inbound_report,
} from "../../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { MultiSelect } from "primereact/multiselect";
import { get_job_status } from "../../../features/jobStatusSlice";
import { Dropdown } from "primereact/dropdown";
import dayjs from "dayjs";
import { campaignReset, get_campaigns } from "../../../features/campaignSlice";
import { get_inbound_group_drd } from "../../../features/inboundGroupSlice";
import { get_users_drd } from "../../../features/usersSlice";
import CustomDropDown from "./Dropdown";
import { FaDownload } from "react-icons/fa";
const InBoundReport = () => {
  const dispatch = useDispatch();
  const componentRef = React.useRef(null);

  const { isLoading, inboundReport, dids } = useSelector(
    (state) => state.reports
  );
  const campaigns = useSelector((state) => state.campaigns);
  const inbound = useSelector((state) => state.inbound);
  const jobStatus = useSelector((state) => state.jobStatus);
  const { usersDrd } = useSelector((state) => state.users);
  const [searchText, setSearchText] = React.useState("");
  const [didSearchText, setDidSearchText] = React.useState("");

  const [groupSearchText, setGroupSearchText] = React.useState("");
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
    campaign: [],
    did: null,
    group_id: null,
    ingroup: [],
    phone_number: null,
    user_id: [],
    call_type: "IN",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_inbound_report(filters));
    dispatch(get_did_drd());
    dispatch(get_users_drd());
    dispatch(get_job_status({ page: 1, size: 10000, active: 1 }));
    // eslint-disable-next-line
  }, []);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const handlePlay = async (vicidial_id) => {
    const audioElement = document.getElementById(vicidial_id);
    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
      }
      audioElement.play();
      setCurrentlyPlayingAudio(audioElement);
    }
  };
  const columnDefs = [
    {
      field: "counter",
      headerName: "Sr.",
      width: 60,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
    },
    {
      field: "lead",
      headerName: "Lead Detail",
      width: 180,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>Lead Number: {params.row.lead?.lead_num}</span>
          <span>
            Status:{" "}
            {`${
              params.row.lead?.is_declined
                ? "Declined"
                : params.row.lead?.job_created
                ? "Job Created"
                : "Open"
            }`}{" "}
            {params.row.lead?.job_number ? (
              <a
                href={`/jobs/update/preview/${params.row.lead?._id}`}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:underline cursor-pointer"
              >
                {`${params.row.lead?.job_number}`}
              </a>
            ) : null}{" "}
          </span>
          {params.row.lead?.job_created ? (
            <span>Job Closed: {params.row.lead?.is_closed ? "Yes" : "No"}</span>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Group ID",
      field: "group_id",
      flex: 1,
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    { headerName: "Phone", field: "phone_number", flex: 1, minWidth: 150 },
    { headerName: "Length", field: "length_in_sec" },
    {
      headerName: "Recording",
      field: "location",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <>
          <audio
            id={params.row.vicidial_id}
            controls
            className="h-10 w-[250px] relative"
            onPlay={() => handlePlay(params.row.vicidial_id)}
          >
            {params.row.location && (
              <source src={params.row.location} type="audio/mpeg" />
            )}
          </audio>
          <a
            href={params.row.location}
            download
            className="h-[30px] w-[30px] z-[100] bg-primary-100 ml-2 flex items-center justify-center rounded-full text-white"
          >
            <FaDownload />
          </a>
        </>
      ),
    },
    {
      headerName: "Area",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Job Status",
      field: "job_status_id",
      minWidth: 150,
      cellClassName: "multiline-cell",
    },
    { headerName: "User", field: "user", flex: 1, minWidth: 100 },
  ];
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  const getRowHeight = (params) => {
    const data = params?.model;
    const rowHeight = data?.lead?.job_created ? 60 : 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want

    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    // const numLines = data?.complete_pickup_address?.split(/,\s*/)?.length;;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const hrs = Math.floor(mins / 60);
    const remainingMins = mins % 60;
    const formattedHours = hrs.toString().padStart(2, "0");
    const formattedMinutes = remainingMins.toString().padStart(2, "0");
    const formattedSeconds = secs.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  const didOptions = dids?.dids
    ?.filter((val) => val.includes(didSearchText))
    ?.slice(0, 500)
    ?.map((did) => ({
      label: did,
      value: did,
    }));
  const did_groups = dids?.did_groups
    ?.filter((val) => val.includes(groupSearchText))
    ?.slice(0, 500)
    ?.map((groupId) => ({
      label: groupId,
      value: groupId,
    }));
  const callType = [
    { value: "IN", label: "IN" },
    { value: "OUT", label: "OUT" },
  ];
  useEffect(() => {
    dispatch(get_campaigns());
    dispatch(get_inbound_group_drd());
    return () => {
      dispatch(campaignReset());
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className="container-fluid">
      <ReportHead route="Reports > Commission Report" heading="Login IP" />

      <div className="grid grid-cols-1 gap-2 md:grid-cols-12 mb-3">
        <div className="md:col-span-9">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
            <div className="md:col-span-3 !px-0.5">
              <Dropdown
                value={filters.call_type}
                onChange={(e) => setFilters({ ...filters, call_type: e.value })}
                options={callType}
                optionValue="value"
                optionLabel="label"
                placeholder="Select Call Type"
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            {filters.call_type === "IN" ? (
              <div className="md:col-span-3 !px-0.5">
                {/* <Dropdown
                  value={filters.did}
                  onChange={(e) => setFilters({ ...filters, did: e.value })}
                  options={didOptions}
                  onFilter={(e) => setDidSearchText(e.filter)}
                  optionValue="value"
                  optionLabel="label"
                  placeholder="Select DID"
                  filter
                  // onBlur={()=>setDidSearchText("")}
                  showClear
                  showFilterClear
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                /> */}
                <CustomDropDown
                  options={dids?.dids}
                  placeholder="Select DID"
                  filter
                  optionLabel="label"
                  optionValue="value"
                  value={filters.did}
                  onChange={(e) => setFilters({ ...filters, did: e })}
                />
              </div>
            ) : null}

            {filters.call_type === "IN" ? (
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <CustomDropDown
                  options={dids?.did_groups}
                  placeholder="Select Group ID"
                  filter
                  optionLabel="label"
                  optionValue="value"
                  value={filters.group_id}
                  onChange={(e) => {
                    setFilters({ ...filters, group_id: e });
                  }}
                />
              </div>
            ) : null}

            <div className="md:col-span-3 !px-0.5 !mb-3">
              <MultiSelect
                // styles={style}
                placeholder="Select Campaign"
                options={campaigns?.record}
                optionLabel="campaign_name"
                optionValue="campaign_id"
                filter
                maxSelectedLabels={2}
                value={filters.campaign}
                onChange={(e) => setFilters({ ...filters, campaign: e.value })}
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            {filters.call_type === "IN" ? (
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <MultiSelect
                  // styles={style}
                  placeholder="Select Ingroup"
                  options={inbound?.inboundDrd}
                  optionLabel="group_name"
                  optionValue="group_id"
                  filter
                  maxSelectedLabels={2}
                  value={filters.ingroup}
                  onChange={(e) => setFilters({ ...filters, ingroup: e.value })}
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                />
              </div>
            ) : null}

            <div className="md:col-span-3 !px-0.5 !mb-3">
              <input
                className="form-control"
                placeholder="Customer Phone#"
                onChange={(e) =>
                  setFilters({ ...filters, phone_number: e.target.value })
                }
                value={filters.phone_number}
              />
            </div>
            <div className="md:col-span-3 !px-0.5 !mb-3">
              <MultiSelect
                // styles={style}
                placeholder="Select User"
                options={usersDrd}
                optionLabel="first_name"
                optionValue="_id"
                filter
                maxSelectedLabels={2}
                value={filters.user_id}
                onChange={(e) => setFilters({ ...filters, user_id: e.value })}
                className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="md:col-span-3 !px-0.5">
              <Button
                text="Search"
                variant="btn_submit"
                onClick={() => dispatch(get_inbound_report(filters))}
              />
              <Button
                className="ml-2"
                text="Reset"
                variant="btn_cancel"
                onClick={() => {
                  setFilters(initialFilters);
                  dispatch(get_inbound_report(initialFilters));
                }}
              />
            </div>
          </div>
        </div>

        <div className="md:col-span-3 !px-0.5">
          <Picker filterData={filters} setFiltersData={setFilters} />
        </div>
      </div>

      <div className="flex-cols md:flex flex-wrap justify-between mx-0.5">
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">In Area</span>
          <span className="text-base font-medium font-pop">
            {inboundReport?.summary?.in_area_calls
              ? inboundReport?.summary?.in_area_calls?.toLocaleString()
              : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Out of Area</span>
          <span className="text-base font-medium font-pop">
            {inboundReport?.summary?.out_of_area_calls
              ? inboundReport?.summary?.out_of_area_calls?.toLocaleString()
              : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Calls</span>
          <span className="text-base font-medium font-pop">
            {inboundReport?.summary?.total_calls
              ? inboundReport?.summary?.total_calls?.toLocaleString()
              : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Time Out</span>
          <span className="text-base font-medium font-pop">
            {inboundReport?.summary?.tech_profit
              ? inboundReport?.summary?.tech_profit?.toLocaleString()
              : 0}
          </span>
        </div>
        <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Droped</span>
          <span className="text-base font-medium font-pop">
            {inboundReport?.summary?.company_profit
              ? inboundReport?.summary?.company_profit?.toLocaleString()
              : 0}
          </span>
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          // exportOption={exportOption}
          handlePrint={handlePrint}
          columnDefs={columnDefs}
          items={inboundReport?.records?.map((record, index) => {
            // const {  call_date } = record?.call;
            const job_id = record?.lead?._id;
            const {
              recording_url,
              length_in_sec,
              call_date,
              phone_number,
              status,
            } = record;
            const lead = record.lead;
            const job_created = record?.lead;
            const is_declined = record?.lead;
            const job_number = record?.lead;
            const job_status_id = record?.lead?.job_status_id;
            const lead_num = record?.lead;
            const did = record?.lead?.call_payload
              ? record?.lead?.call_payload[0]?.did
              : "";
            const group_id = record?.lead?.call_payload
              ? record?.lead?.call_payload[0]?.did_group_id
              : "";
            const formattedTimer = formatTime(length_in_sec);
            const user = `${
              record?.user?.first_name ? record?.user?.first_name : ""
            } ${record?.user?.last_name ? record?.user?.last_name : ""}`;
            return {
              job_id,
              _id: index + 1,
              counter: index + 1,
              records: { ...record, _id: index + 1 },
              location: recording_url,
              length_in_sec: formattedTimer,
              user,
              did,
              group_id,
              phone_number,
              lead_num,
              lead,
              status,
              job_status_id: jobStatus?.record?.records?.find(
                ({ _id }) => _id === job_status_id
              )?.name,
              createdAt: dayjs(call_date).format("ddd, MMM D, YYYY h:mm A"),
              jobCreated: `${
                is_declined ? "Declined" : job_created ? "Job Created" : "Open"
              }`,
              job_number,
            };
          })}
          totalItems={inboundReport?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          gridOptions={getRowHeight}
          searchable="No"
          pagination="No"
          paginationMode="client"
        />
      </div>
      <div className="d-none"></div>
    </div>
  );
};

export default InBoundReport;
