import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_job_status,
  get_job_status,
} from "../../features/jobStatusSlice";
import AddUpdateForm from "./AddUpdateForm";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";

const JobStatus = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.jobStatus);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [jobStatuses, setJobStatuses] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_job_status(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Job Status deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Job Status",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Job Status", msg: "deleted" });
      }
    }
  };
  useEffect(() => {
    setJobStatuses(record)
    // eslint-disable-next-line
  }, [record]);

  useEffect(() => {
    dispatch(get_job_status({ page: 1, size: paginationModel.pageSize,active:1,get_all_records:1 }));
    // eslint-disable-next-line
  }, []);
  const jobStatus = jobStatuses?.records?.filter((status) =>
    status?.name?.toLowerCase().includes(searchText.toLowerCase())&&status?.active
  );
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    { headerName: "name", field: "name", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.records?.active ? (
          <div>
            {userPermission("Update Job Status") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update Job Status"
              />
            )}
            {userPermission("Delete Job Status") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records._id)}
                className="my_navIcon"
                title="Delete Job Status"
              />
            )}
          </div>
        ) : null,
    },
  ];

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_job_status({
        page: +params.page + 1,
        size: params.pageSize,
        active:1,
        get_all_records:1
      })
    );
  };
  const onDragEnd = async (result) => {
    const items = Array.from(jobStatuses?.records);
    const [reorderedItem] = items.splice(result.oldIndex, 1);
    items.splice(result.targetIndex, 0, reorderedItem);

    const jobStatusesWithIndex = items.map((status, index) => ({
      _id: status?._id,
      priority: index,
    }));
    setJobStatuses({...jobStatuses,records:items});
    try {
      await api.post("/api/jobstatus/update_sort_order", jobStatusesWithIndex);
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:366 ~ onDragEnd ~ err:", err);
    }
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Job Status"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Job Status"
        heading="Job Status Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Job Status")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={jobStatus?.map((record, index) => {
            let counter = index + 1;
            let name = record.name;
            let status = record.active ? "Active" : "InActive";
            return {
              records: record,
              counter,
              name,
              status,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={jobStatuses?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          rowReordering={userPermission("Job Status Sorting")}
          handleRowOrderChange={onDragEnd}
        />
      </div>
    </>
  );
};

export default JobStatus;
