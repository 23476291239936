import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateIndustry";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  get_geo_states,
  get_geo_vendors_drd,
} from "../../features/GeoVendorsSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import {
  get_assigned_service_zips,
  reset,
} from "../../features/vendorIndustrySlice";
import { Dropdown } from "primereact/dropdown";
import { Button, DotsLoader } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
const VendorsIndustry = () => {
  const { isLoading, assignedZips } = useSelector(
    (state) => state.vendorIndustries
  );
  const navigate = useNavigate();

  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const location = useLocation();
  const data = location.state;
  let initialFilters = {
    vendor_id: "",
    industry_id: "",
  };
  if (data) {
    const { vendor_id } = data;
    initialFilters = {
      vendor_id,
    };
  }
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filters, setFilters] = React.useState(initialFilters);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  useEffect(() => {
    if(filters.vendor_id){
      dispatch(get_assigned_service_zips(filters));
    }
    dispatch(reset());
    dispatch(get_geo_vendors_drd());
    dispatch(get_geo_industry());
    dispatch(get_geo_states());
    // eslint-disable-next-line
  }, []);
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      flex: 1,
      width: 80,
      filterable: false,
    },
    { headerName: "Vendor Name", field: "vendor", flex: 1, minWidth: 200 },
    { headerName: "Industry", field: "industry_name", flex: 1, minWidth: 200 },
    {
      headerName: "Action",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <div>
          {userPermission("Update User") && (
            <FaEdit
              onClick={()=>navigate("/settings/vendors_industry_update",{state:params.row.records})}
              className="my_navIcon"
              title="Update Vendor Industry"
            />
          )}
        </div>
      ),
      width: 100,
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={
            editingRecord
              ? "Map Industries with Vendor"
              : "Map Industries with Vendor"
          }
          onCancelForm={cancelFormHandler}
        />
      )}
      {vendors?.isLoading ? <DotsLoader /> : null}
      <PageHeader
        route="Setting > Users"
        heading="Vendor Industries Listing"
        onClick={()=>navigate("/settings/vendors_industry_update")}
        isAllowed={userPermission("Geo Location")}
      />
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors"}
            </label>
            <Dropdown
              value={filters.vendor_id}
              onChange={(e) => setFilters({ ...filters, vendor_id: e.value })}
              options={vendors?.geoVendorDrd}
              optionValue="id"
              optionLabel={(option) =>
                `${option?.first_name} ${
                  option?.last_name ? option?.last_name : ""
                }`
              }
              placeholder={vendors?.isLoading ?"Loading...":"Select Vendor"}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <Dropdown
              value={filters.industry_id}
              onChange={(e) => setFilters({ ...filters, industry_id: e.value })}
              options={industry?.record}
              optionLabel="industry_name"
              optionValue="id"
              placeholder="Select Industry"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              filter
            />
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
          <div className="float-right">
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
              onClick={() => {
                if(filters?.vendor_id){
                  dispatch(get_assigned_service_zips(filters))
                }else{toast.error("Vendor is required")}
              }}
                
            />
            <Button
              text="Reset"
              className="py-1.5 px-3 align-middle bg-menu text-white"
              onClick={() => {
                setFilters({ industry_id: "", vendor_id: "" });
                dispatch(reset());
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={assignedZips?.map((data, index) => {
            let { vendor_id } = data;
            let vendor = data?.vendor_name?data?.vendor_name:vendors?.geoVendorDrd?.find(({ id }) => id === vendor_id);
            let industry_name = data?.industry_name?data?.industry_name:industry?.record?.find(
              ({ id }) => id === data?.industry_id
            )?.industry_name;
            return {
              counter: index + 1,
              records: { ...data, _id: index },
              vendor: data?.vendor_name?data?.vendor_name: vendor?.first_name ?`${vendor?.first_name} ${
                vendor?.last_name ? vendor?.last_name : ""
              }`:"",
              industry_name,
            };
          })}
          pagination="No"
          totalItems={assignedZips?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default VendorsIndustry;
