import React, { Fragment } from "react";
import { DotsLoader, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  update_cron_function_priority,
  get_cron_function,
} from "../../features/cronFunctionsSlice";
const UpdatePriorityForm = ({ onCancelForm, modalTitle, functions }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.cronFunctions);
  const [updates, setUpdates] = React.useState(functions);

  const handleSubmit = async () => {
    try {
      const payload = updates
        ?.filter(({ edited }) => !!edited)
        ?.map(({ id, priority }) => ({ id, priority }));
      const res = await dispatch(
        update_cron_function_priority({ updates: payload })
      );

      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(
          res.payload?.data
            ? res.payload?.data
            : `Cron function priority updated`
        );
        dispatch(get_cron_function());
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          toast.error(res?.payload[0]);
        } else {
          toast.error(
            res.payload
              ? res.payload
              : `Cron function priority couldn't be updated`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        toast.error(error?.payload[0]);
      } else {
        toast.error(
          error.payload
            ? error.payload
            : `Cron function priority couldn't be updated`
        );
      }
    }
  };
  return (
    <>
      <Modal
        isUpdate={true}
        title={modalTitle}
        onCancelModal={onCancelForm}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4 bg-white max-h-[55vh] overflow-y-auto">
          {
            <div className="grid grid-cols-2">
              <span className="text-normal font-semibold !border border-gray-300 p-1.5">
                Function name
              </span>
              <span className="text-normal font-semibold !border border-gray-300 p-1.5">
                Priority
              </span>
              {updates?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <span className="text-base !border border-gray-300 p-1.5">
                      {item?.function_name}
                    </span>
                    <span className="text-base !border border-gray-300 p-1.5">
                      <input
                        value={item?.priority}
                        onChange={(e) => {
                          const newPriority = e.target.value; // Parse to integer assuming priority is a number
                          const newUpdates = updates.map((update, i) =>
                            i === index
                              ? {
                                  ...update,
                                  priority: newPriority,
                                  edited: true,
                                }
                              : update
                          );
                          setUpdates(newUpdates); // Update the state
                        }}
                        type="numaric"
                      />
                    </span>
                  </Fragment>
                );
              })}
            </div>
          }
          {/* <div className="!mb-3">
            <FormInput
              errors={errors}
              name="priority"
              label="priority"
              formik={formik}
            />
          </div> */}
        </form>
      </Modal>
    </>
  );
};

export default UpdatePriorityForm;
