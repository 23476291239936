import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createDeclineReason,
  deleteDeclineReason,
  getDeclineReason,
  getDeclineReasonDrd,
  updateDeclineReason,
} from "../services/declineReasonService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  merchantCompanies: [],
  message: "",
  declineReasonDrd:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Decline Reasons
export const get_decline_reasons = createAsyncThunk(
  "get_decline_reasons",
  async (token,thunkAPI) => {
    try {
      return await getDeclineReason(token);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_decline_reasons_drd = createAsyncThunk(
  "get_decline_reasons_drd",
  async (thunkAPI) => {
    try {
      return await getDeclineReasonDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Decline Reason
export const create_decline_reasons = createAsyncThunk(
  "create_decline_reasons",
  async (data, thunkAPI) => {
    try {
      return await createDeclineReason(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Decline Reson
export const update_decline_reasons = createAsyncThunk(
  "update_decline_reasons",
  async (data, thunkAPI) => {
    try {
      return await updateDeclineReason(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Decline Reson
export const delete_decline_reasons = createAsyncThunk(
  "delete_decline_reasons",
  async (id, thunkAPI) => {
    try {
      return await deleteDeclineReason(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const declineReasonSlice = createSlice({
  name: "DeclineReason",
  initialState,
  reducers: {
    declineReasonReset: (state) => {
      state.isError= false;
      state.isLoading= false;
      state.record= [];
      state.merchantCompanies= [];
      state.message= "";
      state.declineReasonDrd=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_decline_reasons.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_decline_reasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_decline_reasons.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.record = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_decline_reasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_decline_reasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.records?.push(action.payload.data);
      })
      .addCase(create_decline_reasons.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_decline_reasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_decline_reasons.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_decline_reasons.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_decline_reasons.fulfilled, (state, action) => {
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result].active = false;
      })
      .addCase(get_decline_reasons_drd.pending, (state) => {
        state.isLoading = true;
        state.declineReasonDrd = [];
      })
      .addCase(get_decline_reasons_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.declineReasonDrd = action.payload?.data;
      })
      .addCase(get_decline_reasons_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { declineReasonReset } = declineReasonSlice.actions;
export default declineReasonSlice.reducer;
