import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  add_domain_runner,
  update_domain_runner,
} from "../../features/domainCenterSlice";
import { FormInput, FormSelectInput, Modal } from "../../components";
import { useFormik } from "formik";
import errorMessage from "../../util/errorMessage";

function AddUpdateDomainRunner({ editingRecord, onCancelForm, modalTitle }) {
  const dispatch = useDispatch();
  const { industryDrd } = useSelector((state) => state.industry);
  const [errors, setErrors] = React.useState([]);
  const initialValues = {
    ip: "",
    industry_id: "",
    runner_name: "",
    mysql_user: "root",
    mysql_password: "",
    phpmyadmin_path: "",
    runner_path: "",
    table_name: "",
    lb_server_file: "",
    ...(editingRecord && {
      id: editingRecord._id,
      ip: editingRecord.ip,
      industry_id: editingRecord.industry_id,
      runner_name: editingRecord.runner_name,
      mysql_user: "root",
      mysql_password: editingRecord.mysql_password,
      phpmyadmin_path: editingRecord.phpmyadmin_path,
      runner_path: editingRecord.runner_path,
      table_name: editingRecord.table_name,
      lb_server_file: editingRecord.lb_server_file,
    }),
  };

  const handleSubmit = async (values) => {
    const action = editingRecord ? update_domain_runner : add_domain_runner;
    const message = editingRecord ? "updated" : "created";
    let payload = { ...values };
    if (editingRecord) {
      delete payload.mysql_password;
    }
    payload = {
      ...payload,
      cms_industry_id: industryDrd?.find(
        ({ _id }) => _id === values?.industry_id
      )?.cms_id,
    };
    try {
      const res = await dispatch(action(payload));
      if (res.payload.status === 200 || res.payload.status === 201) {
        toast.success(`Domain Runner ${message} Successfully`);
        onCancelForm();
      } else {
        toast.error(
          res.payload ? res.payload : `Domain Runner couldn't be ${message}`
        );
      }
    } catch (error) {
      errorMessage({
        payload: error,
        setErrors: setErrors,
        action: "Domain Runner",
        msg: message,
      });
      console.error("~ onSubmit ~ error", error);
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
    >
      <div className="w-full grid grid-cols-2 gap-2">
        <FormInput errors={errors} name="ip" label="IP" formik={formik} />
        <FormSelectInput
          errors={errors}
          name="industry_id"
          label="Industry"
          formik={formik}
          options={industryDrd}
          valueProp="_id"
          labelProp="name"
        />
        <FormInput
          errors={errors}
          name="runner_name"
          label="Runner Name"
          formik={formik}
          readOnly={!!editingRecord}
        />
        {!editingRecord ? (
          <FormInput
            errors={errors}
            name="mysql_password"
            label="MySQL Password"
            formik={formik}
          />
        ) : null}
        <FormInput
          errors={errors}
          name="phpmyadmin_path"
          label="phpMyAdmin Path"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="runner_path"
          label="Runner Path"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="table_name"
          label="Table Name"
          formik={formik}
        />
        <FormInput
          errors={errors}
          name="lb_server_file"
          label="LB Server File"
          formik={formik}
        />
      </div>
    </Modal>
  );
}

export default AddUpdateDomainRunner;
