import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddUpdateUsers from "./AddUpdateUsers";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import CustomModal from "./Modal";
import ShellModal from "./ShellModal";
import Pm2Modal from "./Pm2Modal";
import api from "../../../services/api";
import { DotsLoader, Loader } from "../../../components";
import {
  delete_server,
  get_runners,
  get_runner_apps,
} from "../../../features/serverSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../components/molecules/PageHeader";
function PullToServer() {
  const { isLoading, runner_apps, runners } = useSelector(
    (state) => state.servers
  );
  const dispatch = useDispatch();
  const [editingRecord, setEditingRecord] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [filterConditions, setFilterConditions] = useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [isModal, setIsModal] = useState(false);
  const [isShellModal, setIsShellModal] = useState(false);
  const [shellResponse, setShellResponse] = useState([]);
  const [queryResponse, setQueryResponse] = useState([]);
  const [pm2Model, setpm2Model] = useState(false);
  useEffect(() => {
    dispatch(get_runner_apps());
    dispatch(get_runners());
    // eslint-disable-next-line
  }, []);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === data?._id)?.length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData?.filter((_id) => _id !== data));
    }
  };

  const handleDel = async (_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_server(_id));
        if (res.payload.status === 200) {
          toast.success("Server deleted");
          dispatch(get_runner_apps());
        } else {
          toast.error("Server couldn't be deleted");
        }
      } catch (error) {
        console.log("ImagesList.jsx:48 ~ deleteRecordHandler ~ error", error);
        toast.error("Server couldn't be deleted");
      }
    }
  };
  const options = [
    { value: "Backend", label: "Backend" },
    { value: "Frontend", label: "Frontend" },
    { value: "Cron", label: "Cron" },
  ];

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === filteredData?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "Name", field: "name", flex: 1, minWidth: 100 },
    {
      headerName: "App Folder Name",
      field: "app_folder_name",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "pm2 Process Name",
      field: "pm2_process_name",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Type",
      field: "type",
      type: "singleSelect",
      valueOptions: options,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
    },
    {
      headerName: "Runner",
      field: "runner",
      flex: 1,
      minWidth: 150,
    },
    { headerName: "API URL", field: "api_url", flex: 1, minWidth: 200 },

    {
      field: "actions",
      filterable: false,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (
            <>
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon cursor-pointer"
                size={20}
              />
              <FaTrash
                onClick={() => handleDel(params.row.records._id)}
                className="my_navIcon cursor-pointer ml-2"
                size={20}
              />
            </>
          )}
        </div>
      ),
      width: 100,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  ];
  async function sendRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the server");
    }
    setIsLoader(true);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(item.api_url, item);

        if (response.status === 200) {
          toast.success(
            `${response?.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
          setSelectedData((prevState) =>
            prevState.filter(({ _id }) => _id !== item?._id)
          );
        } else {
          toast.error(
            `${response.data?.message} for ${item.name} & ${item.app_folder_name}`
          );
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `${error?.data?.message} for ${item.name} & ${item.app_folder_name}`
        );
      }
    }

    setIsLoader(false);

    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }

  const data = runner_apps?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const onFilterChange = (e) => {
    setFilterConditions(e?.items);
  };
  function applyFilters(data, filterConditions) {
    return data?.filter((item) => {
      return filterConditions?.every((condition) => {
        const { field, operator, value } = condition;
        const itemValue = item[field];

        // Implement filtering logic based on the operator
        switch (operator) {
          case "contains":
            return itemValue.includes(value);

          case "equals":
            return itemValue === value;

          case "startsWith":
            return itemValue.startsWith(value);

          case "endsWith":
            return itemValue.endsWith(value);

          case "isEmpty":
            return itemValue === "";

          case "isNotEmpty":
            return itemValue !== "";

          case "isAnyOf":
            if (Array.isArray(value)) {
              return value.includes(itemValue);
            }
            return false; // If value is not an array, it cannot be "isAnyOf."
          case "is":
            return itemValue === value;
          case "not":
            return itemValue !== value;

          default:
            return true; // No filter is applied for unknown operators
        }
      });
    });
  }
  const filteredData = applyFilters(data, filterConditions);
  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = filteredData?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  function ErrorModal() {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 bg-black/50 px-4">
        <div className="bg-white p-8 rounded-lg shadow-lg min-w-[680px] ">
          <h1 className="text-center border-b pb-1 font_pop font-semibold text-lg">
            Response for Shell Queries
          </h1>
          <div className="max-h-[70vh] overflow-y-auto">
            {shellResponse?.map((response, index) => {
              return (
                <div className="border-b mb-3" key={index}>
                  <span className="mb-2 border-b font_pop font-semibold text-lg">
                    {response?.runner}
                  </span>
                  {response?.response?.data?.includes("\n")
                    ? response?.response?.data?.split("\n")?.map((txt) => {
                        return <div className="text-lg font-medium">{txt}</div>;
                      })
                    : ""}
                </div>
              );
            })}
          </div>

          <div className="mt-2 flex justify-end items-center">
            <button
              className="border rounded bg-red-600 text-white p-2 text-sm"
              onClick={() => {
                setShellResponse([]);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  function QueryModal() {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0 bg-black/50 px-4">
        <div className="bg-white p-8 rounded-lg shadow-lg min-w-[680px] ">
          <h1 className="text-center border-b pb-1 font_pop font-semibold text-lg">
            Response for Queries
          </h1>
          <div className="max-h-[70vh] overflow-y-auto">
            {queryResponse?.map((response, index) => {
              return (
                <div className="border-b mb-3" key={index}>
                  <span className="mb-2 border-b font_pop font-semibold text-lg">
                    {response?.runner}
                  </span>
                  <div className="text-lg font-medium">
                    {response?.response}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-2 flex justify-end items-center">
            <button
              className="border rounded bg-red-600 text-white p-2 text-sm"
              onClick={() => {
                setQueryResponse([]);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  const CustomButtons = () => {
    return (
      <div className="flex space-x-1">
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white items-center rounded-0"
          onClick={() => setpm2Model(!pm2Model)}
          disabled={selectedData?.length > 0 ? false : true}
        >
          Pm2 Command
        </button>
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white items-center rounded-0"
          onClick={() => setIsShellModal(!isShellModal)}
        >
          Run Shell Query
        </button>
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white items-center rounded-0"
          onClick={() => setIsModal(!isModal)}
        >
          Run Query
        </button>
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white items-center rounded-0 w-[95px]"
          onClick={sendRequests}
          disabled={selectedData?.length > 0 ? false : true}
        >
          Pull
        </button>
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white items-center rounded-0"
          onClick={openFormHandler(0)}
        >
          Add Server
        </button>
      </div>
    );
  };
  return (
    <>
      {isEditing && (
        <AddUpdateUsers
          editingRecord={editingRecord}
          modalTitle="Add New App"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isModal ? (
        <CustomModal
          onClose={() => setIsModal(false)}
          setQueryResponse={setQueryResponse}
          queryResponse={queryResponse}
        />
      ) : null}
      {pm2Model ? (
        <Pm2Modal
          onClose={() => setpm2Model(false)}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
        />
      ) : null}
      {isShellModal ? (
        <ShellModal
          onClose={() => setIsShellModal(false)}
          setShellResponse={setShellResponse}
          shellResponse={shellResponse}
        />
      ) : null}
      {shellResponse?.length > 0 ? <ErrorModal /> : null}
      {queryResponse?.length > 0 ? <QueryModal /> : null}
      {isLoading && <DotsLoader text="Please Wait" />}
      <PageHeader heading="Runner Apps" CustomButtons={CustomButtons} />

      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          totalItems={runner_apps?.totalItems ? runner_apps?.totalItems : 0}
          items={filteredData?.map((server, index) => {
            let counter = index + 1;
            const {
              api_url,
              app_folder_name,
              domain_runner_id,
              name,
              pm2_process_name,
              type,
              _id,
            } = server;
            let created_at = server.created_at
              ? dayjs(server.created_at).format("ddd, MMM D, YYYY h:mm A")
              : null;
            return {
              records: server,
              _id,
              counter,
              name,
              api_url,
              app_folder_name,
              runner: `${domain_runner_id.runner_name} - ${domain_runner_id.ip}`,
              pm2_process_name,
              type,
              created_at,
            };
          })}
          isLoading={isLoading}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onFilterModelChange={onFilterChange}
          pagination="No"
          filterMode="server"
        />
      </div>
    </>
  );
}

export default PullToServer;
