import React from "react";

import {
  DotsLoader,
  FormSelectInput,
  Modal,
} from "../../components";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import {
  get_cities,
  get_zips,
} from "../../features/GeoVendorsSlice";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { MultiSelect } from "primereact/multiselect";
import { create_assigned_service_zips, update_assigned_service_zips } from "../../features/vendorIndustrySlice";
const AddUpdateProvider = ({ editingRecord, onCancelForm, modalTitle }) => {
  let initialValues = {
    vendor_id: "",
    industry_id: "",
    service_area_zips: [],
  };
  const dispatch = useDispatch();
  const { isLoading, states, cities, zips, geoVendorDrd } = useSelector(
    (state) => state.geoVendors
  );
  const { record } = useSelector((state) => state.geoIndustry);
  const [errors, setErrors] = React.useState([]);

  if (editingRecord) {
    const { id, vendor_id, industry_id, service_area_zips } = editingRecord;
    initialValues = {
      id,
      vendor_id,
      industry_id,
      service_area_zips: service_area_zips?.split(","),
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: ProviderSchema,
    onSubmit: async (values) => {
      if (formik.values.industry_id && formik.values.vendor_id) {
          const action = editingRecord ? "updated" : "created";
          const apiFunction = editingRecord
            ? update_assigned_service_zips
            : create_assigned_service_zips;
          const successMessage = editingRecord
            ? "Service zips updated successfully"
            : "Service zips created successfully";
          try {
            const res = await dispatch(apiFunction(values));
            if (res?.payload?.status === 201||res?.payload?.status === 200) {
              toast.success(successMessage);
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Service zips",
                msg: action,
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              setErrors: setErrors,
              action: "Service zips",
              msg: action,
            });
          }
      } else {
        toast.error("Vendor and Industry are required fields");
      }
    },
  });
  React.useEffect(() => {
    if (formik.values.state_id) {
      dispatch(get_cities({ state_id: formik.values.state_id }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.state_id]);
  React.useEffect(() => {
    if (formik.values.city_id) {
      dispatch(get_zips(formik.values.city_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.city_id]);
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={()=>console.warn('zip select')}
      >
        {isLoading && <DotsLoader />}
        <form className="w-full grid mt-4 md:grid-cols-2 gap-x-5">
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="vendor_id"
              label="Vendor"
              formik={formik}
              options={geoVendorDrd}
              valueProp="id"
              labelProp="name"
            />
          </div>

          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="industry_id"
              label="Industry"
              formik={formik}
              options={record}
              valueProp="id"
              labelProp="industry_name"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="state_id"
              label="State"
              formik={formik}
              options={states}
              valueProp="id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="city_id"
              label="City"
              formik={formik}
              options={cities}
              valueProp="id"
              labelProp="name"
            />
          </div>
          <div className="mb-3">
          <MultiSelect
              placeholder="Select Zip"
              options={zips}
              optionLabel="zip"
              optionValue="zip"
              filter
              maxSelectedLabels={0}
              value={formik.values.service_area_zips}
              onChange={(e) => {
                formik.setFieldValue("service_area_zips",e.value);
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          
        </form>
        <div className="mt-">
          <TagsInput
            tags={formik.values.service_area_zips}
            setTags={(tag) => formik.setFieldValue("service_area_zips",tag)}
            placeholder=""
          />
          <div className="flex w-full justify-end">
          <span className="text-right cursor-pointer text-blue-600 hover:underline" onClick={()=>formik.setFieldValue("service_area_zips",[])}>Clear all zips</span>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddUpdateProvider;
