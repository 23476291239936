import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getJobStatusWithCount } from "../services/jobs";
import {
  createJobStatus,
  deleteJobStatus,
  getJobStatus,
  updateJobStatus,
} from "../services/jobStatusService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  jobStatuses:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Job Statuss
export const get_job_status = createAsyncThunk(
  "get_job_status",
  async (data,thunkAPI) => {
    try {
      return await getJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Job Statuss with count
export const get_job_status_with_job_count = createAsyncThunk(
  "get_job_status_with_job_count",
  async (thunkAPI) => {
    try {
      return await getJobStatusWithCount();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Job Status
export const create_job_status = createAsyncThunk(
  "create_job_status",
  async (data, thunkAPI) => {
    try {
      return await createJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Job Status
export const update_job_status = createAsyncThunk(
  "update_job_status",
  async (data, thunkAPI) => {
    try {
      return await updateJobStatus(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Job Status
export const delete_job_status = createAsyncThunk(
  "delete_job_status",
  async (id, thunkAPI) => {
    try {
      return await deleteJobStatus(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const jobStatusSlice = createSlice({
  name: "job_status",
  initialState,
  reducers: {
    jobStatusReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.jobStatuses=[];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_job_status.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_job_status.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.records?.push(action.payload.data);
      })
      .addCase(create_job_status.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_job_status.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_job_status.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_job_status.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_job_status.fulfilled, (state, action) => {
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result].active = false;
        state.isLoading = false;
      })
      .addCase(delete_job_status.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(get_job_status_with_job_count.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_job_status_with_job_count.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobStatuses = action.payload?.data;
      })
      .addCase(get_job_status_with_job_count.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { jobStatusReset } = jobStatusSlice.actions;
export default jobStatusSlice.reducer;
