import api from "./api";

export const getProviders = async () => {
  return await api.get("/api/providers");
};

export const createProvider = async (data) => {
  return await api.post("/api/providers", data);
};

export const updateProvider = async (data) => {
  return await api.put(`/api/providers/${data?.id}`, data);
};
