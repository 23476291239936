import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCronFunctions,
  getCronFunctions,
  updateCronFunctions,
  updateCronFunctionsPriority,
} from "../services/cronFunctionService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get AllowedIP Data
export const get_cron_function = createAsyncThunk(
  "get_cron_function",
  async (thunkAPI) => {
    try {
      return await getCronFunctions();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New AllowedIP
export const create_cron_function = createAsyncThunk(
  "create_cron_function",
  async (data, thunkAPI) => {
    try {
      return await createCronFunctions(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update AllowedIP
export const update_cron_function = createAsyncThunk(
  "update_cron_function",
  async (data, thunkAPI) => {
    try {
      return await updateCronFunctions(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete AllowedIP
export const update_cron_function_priority = createAsyncThunk(
  "update_cron_function_priority",
  async (data, thunkAPI) => {
    try {
      return await updateCronFunctionsPriority(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cronFunctionSlice = createSlice({
  name: "cronFunctions",
  initialState,
  reducers: {
    cronFunctionsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    updatePriority: (state, action) => {
      const result = state.record?.findIndex(
        ({ id }) => id === action?.payload?.id
      );
      state.record[result].priority = action.payload.priority;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_cron_function.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cron_function.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_cron_function.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_cron_function.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_cron_function.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.push(action.payload.data[0]);
      })
      .addCase(create_cron_function.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_cron_function.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_cron_function.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.findIndex(
          ({ id }) => id === action?.payload?.data[0]?.id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data[0],
        };
      })
      .addCase(update_cron_function.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_cron_function_priority.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_cron_function_priority.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(update_cron_function_priority.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cronFunctionsReset, updatePriority } = cronFunctionSlice.actions;
export default cronFunctionSlice.reducer;
