import React, { useEffect, useState } from "react";
import { Button, DotsLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import Select from "react-select";
import {
  clearCities,
  clearZips,
  get_cities,
  get_geo_states,
  get_zips,
} from "../../features/GeoVendorsSlice";
import ZipModal from "./Zips";
const GeoCities = () => {
  const dispatch = useDispatch();
  const { isLoading, states, cities, zips } = useSelector(
    (state) => state.geoVendors
  );
  const [selectedState, setSelectedState] = useState("");
  const [filterData, setfilterData] = useState({
    state_id: "",
    city: "",
    alias: "",
  });
  useEffect(() => {
    dispatch(clearCities())
    dispatch(get_geo_states());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {zips?.length > 0 && (
        <ZipModal
          zips={zips}
          modalTitle="Add Allowed IP"
          onCancelForm={() => dispatch(clearZips())}
          city={selectedState}
        />
      )}
      <PageHeader
        route="Setting > Allowed IP"
        heading="City Listing"
        // onClick={openFormHandler(0)}
        isAllowed={userPermission("Geo Locatio")}
      />
      {isLoading && <DotsLoader />}

      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"States"}
            </label>
            <Select
              name="state"
              placeholder="Select State"
              onChange={(e) => setfilterData({ ...filterData, state_id: e.id })}
              options={states}
              valueKey="value"
              labelKey="label"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                filterData.state_id
                  ? {
                      id: filterData.state_id,
                      name: states?.find(({ id }) => id === filterData.state_id)
                        ?.name,
                    }
                  : null
              }
            />
          </div>
          <div className="w-full mx-2">
            <label className="inputLabel" htmlFor="vendors">
              {"City"}
            </label>
            <input
              placeholder="City"
              onChange={(e) =>
                setfilterData({ ...filterData, city: e.target.value })
              }
              className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              value={filterData?.city}
            />
          </div>
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"City Alias"}
            </label>
            <input
              placeholder="City Alias"
              onChange={(e) =>
                setfilterData({ ...filterData, alias: e.target.value })
              }
              className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
              value={filterData?.alias}
            />
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
          <div className="float-right">
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
              onClick={() => dispatch(get_cities(filterData))}
            />
            <Button
              text="Reset"
              className="py-1.5 px-3 align-middle bg-menu text-white"
              onClick={() => {
                dispatch(clearCities());
                setfilterData({ state_id: "", city: "", alias: "" });
              }}
            />
          </div>
        </div>
      </div>
      <span className="ml-2 mt-3 text-end font_pop">
        Total Records: {cities?.length}
      </span>
      <div className="bg-white my-3 border rounded">
        <div className="table-responsive max-h-[55vh] mb-2">
          <table className="table table-striped table-bordered">
            <thead className="sticky top-0 bg-white border-t border-[#ccc]">
              <tr className='className="bg-white border-t border-[#ccc]'>
                <th className="sticky">#</th>
                <th className="sticky">Name</th>
                <th className="sticky">Latitude</th>
                <th className="sticky">Longitude</th>
                <th className="sticky">Alias</th>
                <th className="sticky">Actions</th>
              </tr>
            </thead>

            <tbody className=" max-h-[45vh]  mb-2 ">
              {cities?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b-[1px] border-slate-200 p-4  "
                  >
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.latitude}</td>
                    <td>{data.longitude}</td>
                    <td className="!max-w-[300px]">
                      {data.alias.replaceAll(",", ", ")}
                    </td>
                    <td>
                      <span
                        onClick={() => {
                          dispatch(get_zips(data.id));
                          setSelectedState(data?.name);
                        }}
                        className="text-blue-600 cursor-pointer hover:underline"
                      >
                        View Zips
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GeoCities;
