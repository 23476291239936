import React from "react";
import { address_icn } from "../../../../images";
import CustomSelect from "../../Components/Selectors";
import { DotsLoader } from "../../../../components";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { BiRefresh } from "react-icons/bi";
function Locations({ formik, setIsDisable, record }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [accuracy, setAccuracy] = React.useState(null);

  const isDropLocation = record?.records?.findIndex(
    (service) => service._id === formik?.values?.job_category_id
  );
  const [autocomplete, setAutocomplete] = React.useState();
  const libraries = ["places", "drawing", "geometry"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries,
  });
  const onLoadPickup = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onLoadDrop = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onChangeAddress = (prefix) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();

      formik?.setFieldValue(
        `complete_${prefix}_address`,
        place?.formatted_address
      );
      formik?.setFieldValue(`${prefix}_latitude`, lat);
      formik?.setFieldValue(`${prefix}_longitude`, lng);

      extractAddress(place, prefix);

    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };

  const extractAddress = (place,  prefix ) => {
    if (!Array.isArray(place?.address_components)) {
      return null;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("postal_code")) {
        formik.setFieldValue(`${prefix}_zip`, value?.long_name);
      }

      if (types.includes("locality")) {
        formik.setFieldValue(`${prefix}_city`, value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue(`${prefix}_state`, value?.short_name);
      }

      if (types.includes("country")) {
        formik.setFieldValue(`${prefix}_country`, value?.long_name);
      }
    });
  };
  React.useEffect(() => {
    if(formik?.values?.complete_pickup_address) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
    // eslint-disable-next-line
  }, [formik?.values?.complete_pickup_address]);

  const getLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      formik.setFieldValue("pickup_latitude", position?.coords?.latitude);
      formik.setFieldValue("pickup_longitude", position?.coords?.longitude);
      setAccuracy(position?.coords?.accuracy);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.coords?.latitude},${position?.coords?.longitude}&sensor=true&key=AIzaSyBbPxeSz0RpKOe33IUL1-Dm6CYAVSW-i3w`
      )
        .then((res) => res.json())
        .then((json) => {
          setIsLoading(false);
          extractAddress(json.results[0], "pickup" );
          formik.setFieldValue(
            "complete_pickup_address",
            json.results[0].formatted_address
          );
        });
    });
  };

  React.useEffect(() => {
    async function calculateRoute() {
      if (
        formik?.values?.complete_pickup_location === "" ||
        formik?.values?.complete_drop_location === ""
      ) {
        return formik.setFieldValue("distance", "");
      } else {
      }

      // eslint-disable-next-line
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: `${formik?.values?.pickup_latitude}, ${formik?.values?.pickup_longitude}`,
        destination: `${formik?.values?.drop_latitude}, ${formik?.values?.drop_longitude}`,
        // eslint-disable-next-line
        travelMode: google.maps.TravelMode.DRIVING,
      });
      formik.setFieldValue(
        "distance",
        (results.routes[0].legs[0].distance.value / 1600).toFixed(2)
      );
    }
    calculateRoute();
    // eslint-disable-next-line
  }, [formik?.values?.drop_longitude]);

  return (
    <div>
      {isLoading && <DotsLoader />}
        <div className="text-base font-pop text-center font-medium bottom-3.7">
          {" "}
          Need address Help? Have google find pickup location{" "}
          <span className="text-[#007bff] underline cursor-pointer" onClick={getLocation}>
            click here
          </span>
        </div>
      {isLoaded && (
        <form>
          {!formik.values?.complete_pickup_address && (
            <Autocomplete
              onPlaceChanged={() => onChangeAddress("pickup")}
              onLoad={(autocomplete) => onLoadPickup(autocomplete)}
            >
              <CustomSelect
                icon={address_icn}
                placeholder="Pickup Address"
                isInput
                formik={formik}
              />
            </Autocomplete>
          )}
          {formik?.values?.complete_pickup_address && (
            <div className="flex gap-4">
              <div className="w-[95%]">
                <CustomSelect
                  icon={address_icn}
                  value={formik?.values?.complete_pickup_address}
                  placeholder="Pickup Address"
                  isInput
                  isDisable
                  formik={formik}
                  label={
                    accuracy ? `Accurate to ${accuracy?.toFixed(2)} meters` : ""
                  }
                />
              </div>
              <div
                className="flex justify-center items-center min-w-[40px]"
                style={{
                  backgroundColor: "#5b6eb6",
                  maxHeight: 40,
                  borderRadius: 5,
                }}
              >
                <BiRefresh
                  size={25}
                  className=""
                  onClick={() => {
                    formik.setFieldValue("complete_pickup_address", "");
                    formik.setFieldValue("distance", "");
                  }}
                  cursor="pointer"
                  color="white"
                />
              </div>
            </div>
          )}

          {record?.records[isDropLocation]?.drop_location_required &&
            !formik.values?.complete_drop_address &&
            formik?.values?.complete_pickup_address && (
              <Autocomplete
                onPlaceChanged={() => onChangeAddress("drop")}
                onLoad={(autocomplete) => onLoadDrop(autocomplete)}
              >
                <CustomSelect
                  icon={address_icn}
                  placeholder="Drop Address"
                  isInput
                  formik={formik}
                  label={
                    formik?.values?.distance
                      ? `Distance is ${formik?.values?.distance} miles`
                      : ""
                  }
                />
              </Autocomplete>
            )}
          {formik.values?.complete_drop_address &&
            formik?.values?.complete_pickup_address && (
              <div className="flex gap-4">
                <div className="w-[95%]">
                  <CustomSelect
                    icon={address_icn}
                    placeholder="Drop Address"
                    isInput
                    formik={formik}
                    label={
                      formik?.values?.distance
                        ? `Distance is ${formik?.values?.distance} miles`
                        : ""
                    }
                    value={formik.values?.complete_drop_address}
                    isDisable
                  />
                </div>
                <div
                  className="flex justify-center items-center min-w-[40px]"
                  style={{
                    backgroundColor: "#5b6eb6",
                    maxHeight: 40,
                    borderRadius: 5,
                  }}
                >
                  <BiRefresh
                    size={25}
                    className=""
                    onClick={() => {
                      formik.setFieldValue("complete_drop_address", "");
                      formik.setFieldValue("distance", "");
                    }}
                    cursor="pointer"
                    color="white"
                  />
                </div>
              </div>
            )}
        </form>
      )}
    </div>
  );
}

export default Locations;
