import axios from "axios";
import api from "./api";

export const getBuyingReport = async (payload) => {
  return await api.post(
    "https://api.cmsdeployer.com/api/geo_locations/location_report",
    payload
  );
};
export const createBatch = async (payload) => {
  return await api.post("/api/domains_batch", payload);
};
export const getBatch = async (payload) => {
  return await api.post("/api/domains_batch/list", payload);
};

export const getBatchDetail = async (data) => {
  const payload = { ...data };
  if (data?.filters?.rules?.length === 0) {
    delete payload.filters;
  }
  return await api.post(
    `api/domains_batch/batch_locations/${data?.batch_id}`,
    payload
  );
};
export const deleteBatch = async (id) => {
  return await api.delete(`/api/domains_batch/${id}`);
};
export const purchaseDomains = async (data) => {
  return await api.post(`/api/domains_batch/purchase_domains`, data);
};
export const getDomains = async (data) => {
  return await api.get(`/api/vendors/domain_prime_time/domains`, {
    params: data,
  });
};
export const importTestDomainDB = async ({ file, domain }) => {
  return await api.post(`/api/domains/import_domain_database/${domain}`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const setTestDomains = async (domains) => {
  return await api.post(`api/domains/set_test_domains`, domains);
};
export const getBatchForExport = async (id) => {
  return await api.get(
    `https://api.cmsdeployer.com/api/geo_locations/batch_detailsJson/${id}`
  );
};

export const getTestLinkIndustries = async () => {
  return await axios.get(
    `https://backend.amplifytest1.top/api/setup/get_all_test_link_with_industry`,
    {
      headers: {
        Authorization: "Bearer AKIA3WTNB4VOJYIFVP7F",
      },
    }
  );
};

export const getBatchForExport2 = async (id) => {
  return await api.get(
    `https://api.cmsdeployer.com/api/geo_locations/batch_detailsJson2/${id}`
  );
};
export const getGoogleConsoles = async () => {
  return await api.get(
    `https://api.cmsdeployer.com/api/google_seo/google_search_console_list`
  );
};
export const getPurchaseDomain = async (data) => {
  return await api.post(
    "/api/vendors/domain_prime_time/purchased_domains_details",
    data
  );
};
export const getPurchaseDomainZips = async (data) => {
  return await api.post(
    "https://api.cmsdeployer.com/api/geo_locations/zips_by_state_city",
    data
  );
};
export const getDomainRankingReport = async (data) => {
  return await api.post("/api/reports/domain_ranking_report", data);
};
export const getNamesiloDomainReport = async (data) => {
  return await api.post("/api/reports/namesilo_domains", data);
};
export const getLeadJobCount = async (data) => {
  return await api.post("/api/did/lead_job_count", data);
};
export const addDomainRankingKeywords = async (data) => {
  return await api.post("/api/reports/add_domain_ranking_keyword", data);
};
export const getDomainDrd = async (industry_id) => {
  return await api.get(`/api/reports/domain_ranking_domains/${industry_id}`);
};
export const deleteDomainRankingKeywords = async (data) => {
  return await api.delete(`/api/reports/add_domain_ranking_keyword/${data}`);
};
export const updateDomainRankingKeywords = async (data) => {
  return await api.put("/api/reports/add_domain_ranking_keyword", data);
};
// Domain Runners
export const getDomainRunners = async (data) => {
  return await api.get(`api/domain_runners`,{params:data});
};
export const updateDomainRunner = async (data) => {
  return await api.put(`api/domain_runners/${data.id}`, data);
};
export const addDomainRunner = async (data) => {
  return await api.post(`api/domain_runners`, data);
};

export const getAlternateDomainPhone = async () => {
  return await api.get(`api/alternate_areacodes`);
};
export const getDomainNumbers = async () => {
  return await api.get(`api/domains/domain_number_data`);
};
export const domain_number_set_pending = async () => {
  return await api.put(`api/domains/domain_number_set_pending`);
};
export const updateDomainNumbers = async (data) => {
  return await api.put(`api/domains/update_domain_phone`, data);
};

export const listVirtualHosts = async () => {
  return await api.get(
    `https://domain-launcher.ecommcube.com/list_virtual_hosts`
  );
};
export const deleteVirtualHosts = async (domain) => {
  return await api.post(
    `https://domain-launcher.ecommcube.com/delete_virtual_hots`,
    domain
  );
};

export const setAlternateAreaCode = async (data) => {
  return await api.post(`/api/domains/set_alternate_area_codes`, data);
};

export const getPendingDomainsPhone = async () => {
  return await api.get(`/api/domains/pending_domains_phone`);
};

export const getDetachDomain = async () => {
  return await api.get(`/api/domains/detach_domain_list`);
};

export const deleteDetachDomain = async (id) => {
  return await api.delete(`/api/domains/remove_detach_domain`, { data: id });
};
export const addDetachDomain = async (domains) => {
  return await api.post(`/api/domains/add_detach_domain`, { domains });
};
