import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_geo_vendors_drd } from "../../features/GeoVendorsSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import { useNavigate } from "react-router-dom";
import { delete_search_log, get_search_logs } from "../../features/geoLocSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { MultiSelect } from "primereact/multiselect";
import AssignTech from "./AssignTech";
import { HiLockClosed } from "react-icons/hi";
import { Dropdown } from "primereact/dropdown";
const GeoLocationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, searchLogs } = useSelector((state) => state.geoLoc);
  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [vendorType, setVendorType] = useState("Own");
  const [filterData, setfilterData] = useState({
    industry_id: "",
    vendor_id: "",
  });
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  useEffect(() => {
    dispatch(get_search_logs());
    dispatch(get_geo_vendors_drd());
    dispatch(get_geo_industry());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(get_geo_vendors_drd({ vendor_type: vendorType }));
    // eslint-disable-next-line
  }, [vendorType]);
  const logs = searchLogs?.filter(
    (log) =>
      log?.industry_id?.name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      log?.vendor_id?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      log?.description?.toLowerCase().includes(searchText.toLowerCase())
  );
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_search_log(record_id));
        if (res?.payload?.status === 200) {
          toast.success("Search Log deleted");
        } else {
          errorMessage({
            payload: res.payload,
            action: "Search Log",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error,
          action: "Search Log",
          msg: "deleted",
        });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "Description", field: "description", flex: 1, minWidth: 200 },
    { headerName: "Date", field: "createAt", flex: 1, minWidth: 200 },
    { headerName: "Tech Name", field: "vendor", flex: 1, minWidth: 150 },
    { headerName: "Industry", field: "industry", flex: 1, minWidth: 150 },
    {
      headerName: "Total Records",
      field: "total_payloads",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Total Population",
      field: "total_population",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Unique Population",
      field: "unique_population",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Total Cities",
      field: "total_cities",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Unique Cities",
      field: "unique_cities",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Unique Cities Per State",
      field: "unique_cities_per_state",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Total State",
      field: "total_states",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Unique State",
      field: "unique_states",
      flex: 1,
      minWidth: 100,
    },
    { headerName: "Total Zips", field: "total_zips", flex: 1, minWidth: 100 },
    { headerName: "Unique Zips", field: "unique_zips", flex: 1, minWidth: 100 },
    {
      headerName: "Assign Tech",
      field: "id",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <span
          className="text-blue-600 cursor-pointer hover:underline"
          onClick={() =>
            navigate(`/geolocation/assign_tech/${params.row.records?.id}`, {
              state: params.row.records,
            })
          }
        >
          Assign Tech
        </span>
      ),
    },
    {
      field: "actions",
      renderCell: (params) =>
        !params?.row?.records?.has_error && params?.row?.records?.is_ready ? (
          <div>
            {userPermission("Geo Location") && (
              <FaEdit
                onClick={() =>
                  navigate(`/update/geolocation/locations`, {
                    state: params.row.records,
                  })
                }
                className="my_navIcon"
                title="Update Geo Location"
              />
            )}
            {params.row.records?.is_locked ? (
              <HiLockClosed className="my_navIcon" size={18} />
            ) : (
              <FaTrash
                onClick={deleteRecordHandler(params.row.records.id)}
                className="my_navIcon"
                title="Delete Geo Location"
              />
            )}
          </div>
        ) : null,
      width: 120,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  React.useEffect(() => {
    const timerID = setInterval(() => {
      // Check if the array contains an object with the desired status
      const hasDesiredStatus = searchLogs.some(
        (obj) => !obj.is_ready && !obj.has_error
      );

      if (hasDesiredStatus) {
        // Perform the rendering or dispatch the necessary action
        dispatch(get_search_logs());
      } else {
        clearInterval(timerID);
      }
    }, 5000);

    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, [searchLogs]);
  return (
    <>
      {isEditing && (
        <AssignTech
          onClose={cancelFormHandler}
          editingRecord={editingRecord}
          modalTitle="Add Vendors"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Vendors"
        heading="Geo Location Listing"
        onClick={() => navigate("/geolocation/locations")}
        isAllowed={userPermission("Geo Location")}
      />

      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        <div className="flex flex-row items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="industries">
              {"Industries"}
            </label>
            <MultiSelect
              placeholder="Select Industry"
              options={industry?.record}
              optionLabel="name"
              optionValue="id"
              filter
              maxSelectedLabels={2}
              value={filterData?.industry_id}
              onChange={(e) => {
                setfilterData({ ...filterData, industry_id: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors Type"}
            </label>
            <Dropdown
              value={vendorType}
              onChange={(e) => {
                setVendorType(e.value);
              }}
              options={[
                { label: "All", value: "All" },
                { label: "Global", value: "Global" },
                { label: "Own", value: "Own" },
              ]}
              optionLabel="label"
              placeholder="Select Vendor Type"
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
          <div className="w-full ml-2">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors"}
            </label>
            <MultiSelect
              placeholder="Select Vendors"
              options={vendors?.geoVendorDrd}
              optionLabel={(option) =>
                `${option?.description ? option?.description + " | " : ""} ${
                  option?.name
                } ${option?.phone ? " | " + option?.phone : ""}`
              }
              optionValue="id"
              filter
              maxSelectedLabels={2}
              value={filterData?.vendor_id}
              onChange={(e) => {
                setfilterData({ ...filterData, vendor_id: e.value });
              }}
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
        </div>

        <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
          <div className="float-right">
            <Button
              text={"Search"}
              className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
              onClick={() => dispatch(get_search_logs(filterData))}
            />
            <Button
              text="Reset"
              className="py-1.5 px-3 align-middle bg-menu text-white"
              onClick={() => {
                setfilterData({ industry_id: "", vendor_id: "" });
                dispatch(get_search_logs());
              }}
            />
          </div>
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={logs?.map((record, index) => {
            let summary_counts;

            if (record && record.summary_counts) {
              try {
                summary_counts = JSON.parse(record.summary_counts);
              } catch (error) {
                console.error("Error parsing summary_counts:", error);
              }
            } else {
              console.warn("Record or summary_counts is missing.");
            }
            let counter = index + 1;
            let industry = record?.industry_id?.name;
            let vendor = record?.vendor_id?.name;
            let total_cities = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.total_cities?.toLocaleString()
              : "Not ready...";
            let unique_cities = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.unique_cities?.toLocaleString()
              : "Not ready...";
            let total_zips = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.total_zips?.toLocaleString()
              : "Not ready...";
            let total_states = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.total_states?.toLocaleString()
              : "Not ready...";
            let unique_zips = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.unique_zips?.toLocaleString()
              : "Not ready...";
            let unique_states = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.unique_states?.toLocaleString()
              : "Not ready...";
            let total_population = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.total_population?.toLocaleString()
              : "Not ready...";
            let unique_population = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.unique_population_per_zip?.toLocaleString()
              : "Not ready...";
            let unique_cities_per_state = record?.has_error
              ? "Has error"
              : record?.is_ready
              ? summary_counts?.unique_cities_per_state?.toLocaleString()
              : "Not ready...";
            let createAt = dayjs(record?.created_at).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            let description = record?.description;
            let total_payloads = record?.total_payloads;
            return {
              counter,
              records: { ...record, _id: record?.id },
              industry,
              vendor,
              total_cities,
              unique_cities,
              total_zips,
              total_states,
              total_population,
              unique_zips,
              unique_states,
              createAt,
              description,
              total_payloads,
              unique_population,
              unique_cities_per_state,
            };
          })}
          // onRowSelectionModelChange={handleRowSelection}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={searchLogs?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode={"client"}
          // checkboxSelection={true}
          // ref={gridRef}
        />
      </div>
    </>
  );
};

export default GeoLocationList;
