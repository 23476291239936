export { login } from "./authService";
export { logout } from "./authService";
export { apiGetStatesByCountryId } from "./statesService";
export { apiGetCountries } from "./countriesService";
export { apiGetTimezones } from "./timezoneService";
export {
  createMerchant,
  updateMerchants,
  deleteMerchant,
  getMerchants,
} from "./merchantService";

const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
/**
 * Calls an API action and handles errors.
 *
 * @param {Object} options - Options for the API call.
 * @param {Function} options.action - The API action function (should be asynchronous).
 * @param {Object} options.payload - The payload to send with the API call.
 * @returns {Promise} - A promise that resolves with the API response or rejects with an error message.
 */

export const apiCalling=async({action, payload})=>{
try{
const res=await action(payload)
return res
}catch(err){
  const message = getErrorMessage(err);
  throw new Error(message);
}
}