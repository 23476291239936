import api from "./api";

export const getCronFunctions = async (data) => {
  return await api.get(`/api/cron_function`,{params:data});
};
export const createCronFunctions = async (data) => {
  return await api.post("/api/cron_function", data);
};
export const updateCronFunctions = async (data) => {
  return await api.put(`/api/cron_function/${data?.id}?disable_only=${data?.disable_only?data?.disable_only:0}`, data);
};
export const updateCronFunctionsPriority = async (data) => {
  return await api.put("/api/cron_function/update_priority", data);
};
