import React, { useEffect, useState } from "react";
import AddUpdateNationSitesModal from "./AddUpdateNationSites";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  get_nation_site,
  nationSiteReset,
} from "../../features/nationSitesSlice";
import { get_industry } from "../../features/IndustrySlice";
const NationSites = () => {
  const dispatch = useDispatch();
  const { isLoading, nationSites } = useSelector((state) => state?.nation);
  const industry = useSelector((state) => state?.industry);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_nation_site());
    dispatch(get_industry({ data: { page: 1, size: 100 } }));
    return () => {
      dispatch(nationSiteReset());
    };
    // eslint-disable-next-line
  }, []);
  const sites = nationSites?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Domain", field: "domain", flex: 1 },
    { headerName: "Industry", field: "industry_id", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Update National Site") ? (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update National Sites"
            />
          ) : null}
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateNationSitesModal
          editingRecord={editingRecord}
          modalTitle="Add National Site"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="National Site Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add National Site")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={sites?.map((record, index) => {
            let counter = index + 1;
            const {
              domain,
              industry_id,
            } = record;

            return {
              counter,
              records: { ...record },
              domain,
              industry_id: industry?.record?.records?.find(
                ({ _id }) => _id === industry_id
              )?.name,
            };
          })}
          totalItems={nationSites?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NationSites;
