import React, { useEffect, useState } from "react";

import { Backdrop, Button, DotsLoader } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get_merchants } from "../../features/merchantSlice";
import { toast } from "react-toastify";
import { FormControl, InputLabel, Select } from "@mui/material";
import errorMessage from "../../util/errorMessage";
import { change_vendor_merchant } from "../../features/GeoVendorsSlice";

const MerchantChange = ({ editingRecord, onCancelForm, modalTitle }) => {
  const merchant = useSelector((state) => state.merchant);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.merchant);
  const [isLoader, setIsLoader] = useState(false);
  let initialValues = {
    id: editingRecord?._id,
    merchant_id: "",
  };
  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,

    validationSchema: Yup.object().shape({
      merchant_id: Yup.string(),
    }),

    onSubmit: async (values) => {
      setIsLoader(true);
      try {
        const res = await dispatch(change_vendor_merchant(values));
        if (res?.payload?.status === 200) {
          toast.success("Merchant changed successfully");
          onCancelForm();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Merchant",
            msg: "changed",
          });
        }
      } catch (err) {
        errorMessage({
          payload: err.payload,
          action: "Merchant",
          msg: "changed",
        });
        console.log("🚀 ~ file: MerchantChange.jsx:46 ~ onSubmit: ~ err:", err);
      }
    },
  });

  useEffect(() => {
    dispatch(get_merchants());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Backdrop onClick={onCancelForm} />
      <div className="fixed w-full max-w-[500px] max-h-[calc(100vh-64px)] overflow-y-auto top-8 left-1/2 bg-white rounded z-[200] shadow-[0_2px_8px_rgba(0,_0,_0,_0.26)] -translate-x-1/2">
        {merchant?.isLoading || isLoading ? <DotsLoader /> : null}
        {isLoader && <DotsLoader />}
        <header className="border-b- border-[#3b0062 ">
          <h5 className=" mb-3 text-[#343434] pb-2.5 w-full border-b border-[#D9D9D9] font-semibold m-3">
            Change merchant for{" "}
            <span className="text-primary-100 font-bold font-pop">{`${
              editingRecord?.first_name
                ? editingRecord?.first_name
                : "" +
                  " " +
                  (editingRecord?.last_name ? editingRecord?.last_name : "")
            }`}</span>{" "}
            vendor
          </h5>
        </header>
        <div className="p-4">
          <FormControl
            sx={{ mt: 0, minWidth: 120, borderColor: "#6666" }}
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="merchant_id">{"Merchant Name"}</InputLabel>
            <Select
              sx={{
                borderColor: "#6666",
                mt: 0,
                background: "#fff",
              }}
              label="Merchant Name"
              size="small"
              fullWidth
              native
              name={"merchant_id"}
              variant="outlined"
              onChange={(e) =>
                formik?.setFieldValue("merchant_id", e.target.value)
              }
            >
              <option value="" label=""></option>
              {merchant?.record?.records?.map((item) =>
                item._id !== editingRecord?.merchant_id ? (
                  <option value={item._id} key={item._id}>
                    {item.company_name}
                  </option>
                ) : null
              )}
            </Select>
          </FormControl>
        </div>
        <div className="p-4 text-right">
          <Button
            text="Submit"
            className="mr-2"
            onClick={formik.handleSubmit}
            isLoading={isLoading ? isLoading : isLoader}
            variant="btn_submit"
          />
        </div>
      </div>
    </>
  );
};

export default MerchantChange;
