import React from "react";
import { useSelector } from "react-redux";
function DialerHeader() {
  const {
    status,
    timer,
    classColor,
    phone_numberD,
    callTypeD,
    did_descriptionD,
    inGroupD,
  } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  return (
    <div className="relative bg-dialer_bar h-[59px] hidden md:flex items-center py-2 px-2 w-auto rounded-t-md transition-all ease-in-ot duration-300">
      <div className="flex flex-row gap-2">
        <div className="flex flex-col items-center justify-center w-auto">
          {status && (
            <span
              className={[
                "text-sm font-semibold min-w-[110px] w-auto text-center rounded flex flex-row justify-center items-center px-1.5 py-1 h-auto",
                classColor,
              ].join(" ")}
            >
              {status === "WAIT" ? "WAITING" : status}
            </span>
          )}
          {timer && (
            <span className="text-xs text-center text-[#8D8D8D] font-medium w-[110px]">
              Timer: {timer}
            </span>
          )}
        </div>
        {(status === "DISPO" || status === "INCALL" || status === "DEAD") &&
        callTypeD ? (
          <div className="flex flex-col items-cente justify-center w-auto text-white bg-[#747E84] !px-2 py-1 rounded">
            {callTypeD && (
              <>
                <small className="text-xs font-bold">
                  {callTypeD ? callTypeD : ""}:{" "}
                  <small className="ml-2 text-xs">
                    {phone_numberD ? phone_numberD : ""}
                  </small>
                </small>
                <small className="text-xs font-bold">
                  InGroup:{" "}
                  <small className="ml-2 text-xs">
                    {inGroupD ? inGroupD : ""}
                  </small>
                </small>
                <small className="text-xs font-bold">
                  GroupID:{" "}
                  <small className="ml-2 text-xs">
                    {did_descriptionD ? did_descriptionD : ""}
                  </small>
                </small>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DialerHeader;
