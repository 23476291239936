import React from "react";
import DotsLoader from "../../atoms/DotsLoader";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import {
  add_note,
  clearNotes,
  get_jobs_by_id,
  get_notes,
} from "../../../features/jobsSlice";
import { toast } from "react-toastify";
import { FaSearch, FaUser } from "react-icons/fa";
import Button from "../../atoms/Button";
import dayjs from "dayjs";
import { FaLaptop } from "react-icons/fa";
import errorMessage from "../../../util/errorMessage";

export function Timeline({ modalTitle, setIsTimeline }) {
  const { loader, jobNotes } = useSelector((state) => state.jobs);

  const [jobNumber, setJobNumber] = React.useState();
  const location = useLocation();
  const [searchText, setSearchText] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [newNote, setNewNote] = React.useState("");
  const [isNotes, setIsNotes] = React.useState(false);
  const [noteFilter, setNoteFilter] = React.useState("");
  const [allNotes, setAllNotes] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [selectNote, setSelectNote] = React.useState();

  const dispatch = useDispatch();

  const isUpdating = location.pathname.startsWith("/jobs/update");
  const id =
    location.pathname.split("/")[3] === "preview"
      ? location.pathname.split("/")[4]
      : location.pathname.split("/")[3];
  const getJob = async () => {
    if (isUpdating) {
      try {
        const res = await dispatch(get_jobs_by_id(id));
        if (res.payload?.status === 200) {
          setJobNumber(res.payload.data.job_number);
        } else {
          errorMessage({ payload: res.payload, action: "Data", msg: "loaded" });
        }
      } catch (error) {
        console.error(
          "Error ~ file: AddUpdateForm.jsx:157 ~ getJob ~ error",
          error
        );
        errorMessage({ payload: error, action: "Job", msg: "loaded" });
      }
    } else {
      setJobNumber("");
    }
  };
  React.useEffect(() => {
    if (isUpdating) {
      getJob();
    } else if (jobNumber) {
      setSearchText("");
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  React.useEffect(() => {
    if (jobNumber) {
      dispatch(get_notes(jobNumber));
    } else {
      dispatch(clearNotes());
    }
    // eslint-disable-next-line
  }, [jobNumber]);

  const handleAddNote = async () => {
    if (jobNumber) {
      try {
        const res = await dispatch(add_note({ id: jobNumber, note: newNote }));
        if (res.payload?.status === 200) {
          toast.success("Note Add Successfully");
          setNewNote("");
          setIsNotes(false);
        } else {
          errorMessage({
            payload: res.payload,
            setErrors: setErrors,
            action: "Note",
            msg: "created",
          });
        }
      } catch (err) {
        errorMessage({
          payload: err,
          setErrors: setErrors,
          action: "Note",
          msg: "created",
        });
      }
    } else {
      toast.error("Please Enter a job number");
    }
  };
  const note_for = [
    {
      label: "All",
      value: "",
    },
    {
      label: "By User",
      value: "User Notes",
    },
    {
      label: "By System",
      value: "System Notes",
    },
  ];
  React.useEffect(() => {
    const filteredArray = jobNotes.filter(({ type }) => {
      if (noteFilter === "") {
        return true;
      } else if (noteFilter === "User Notes") {
        return type === "User Notes";
      } else if (noteFilter === "System Notes") {
        return type === "System Notes";
      }
      return false;
    });
    setAllNotes(filteredArray);
  }, [noteFilter, jobNotes]);
  const notes = allNotes?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(filterText?.toLowerCase())
    );
  });
  const textRef = React.useRef(null);
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  return (
    <Draggable
      defaultPosition={{ x: 0, y: 0 }}
      axis="x"
      cancel="button, input, i, ul, li, textarea"
    >
      <div
        className="justify-between items-center w-full relative"
        style={{ display: "flex", cursor: "move", zIndex: 10 }}
      >
        <div className="fixed overflow-y-auto bottom-11 md:bottom-3.5 right-1.5 bg-white rounded-t-md z-10 shadow-3xl max-w-[380px] min-w-[60vw] md:min-w-[350px] min-h-[70vh] md:min-h-[600px] max-h-[600px] ">
          <header className="border-b-2 border-[#3b0062] flex justify-between items-center">
            <div className="!mx-4 !my-1">
              <h1 className="text-2xl text-[#3b0062] font-medium">
                {modalTitle}
              </h1>
              <span className="text-normal text-[#3b0062]">
                {jobNumber ? `Job# ${jobNumber}` : ""}
              </span>
            </div>
            <div>
              {jobNumber && (
                <button
                  onClick={() => {
                    setIsNotes(!isNotes);
                  }}
                  text="Add Note"
                  className="border !px-2 w-auto h-[40px] text-normal bg-primary-100 text-white"
                >
                  Add Note
                </button>
              )}
              <i
                className="fa fa-minus text-2xl text-[#3b0062] !m-4 cursor-pointer"
                onClick={() => {
                  setIsTimeline(false);
                }}
              />
            </div>
          </header>
          {jobNotes.length > 0 ? (
            <div className="relative w-full px-3.7 !my-2">
              <input
                className={
                  "shadow appearance-none border rounded w-full !py-2 !pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full pl-5 h-[31px]"
                }
                style={{ minHeight: 31 }}
                placeholder="Search"
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
              <div className="absolute top-[3px] left-6 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
                <FaSearch />
              </div>
              <div className="flex w-full">
                <ul className="flex flex-row jistify-between w-full !mt-2 items-center">
                  {note_for?.map((val, index) => {
                    return (
                      <li
                        className={`w-full text-center cursor-pointer ${
                          val.value === noteFilter
                            ? "text-primary-100 border-b border-primary-100"
                            : ""
                        }`}
                        key={index}
                        onClick={() => setNoteFilter(val.value)}
                      >
                        {val.label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {isNotes && (
            <>
              <div className="flex justify-center items-center bottom-2 w-full max-w-[380px]">
                <div className="relative w-full px-3.7 my-2">
                  <textarea
                    className={
                      "shadow appearance-none border rounded w-full py-2 !px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full"
                    }
                    style={{ minHeight: 31 }}
                    placeholder="Add Note"
                    value={newNote}
                    onChange={(e) => {
                      setNewNote(e.target.value);
                    }}
                    rows={5}
                  />
                  {errors !== undefined &&
                    errors?.length > 0 &&
                    errors?.map((error, index) => {
                      return (
                        <>
                          {error?.note && (
                            <small
                              style={{
                                fontSize: 12,
                                marginTop: -5,
                                paddingLeft: 16,
                                color: "#D32F2F",
                              }}
                              key={index}
                            >
                              {error.note
                                .replaceAll(`"note"`, "Note")
                                .replaceAll(`note`, "Note")}
                            </small>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="text-center">
                <Button
                  text="Cancel"
                  className="mx-2"
                  onClick={() => {
                    setIsNotes(false);
                  }}
                  variant="btn_cancel"
                />
                <Button
                  text={"Submit"}
                  className=""
                  onClick={handleAddNote}
                  variant="btn_submit"
                />
              </div>
            </>
          )}
          {loader.timeLine ? (
            <DotsLoader />
          ) : jobNotes.length > 0 ? (
            <div className="pl-8 overflow-auto pt-3 !pb-4 max-h-[400px]">
              {notes?.map((note, index) => {
                const { notes, type, createdAt, createdBy, params } = note;
                return (
                  <div
                    id="_timeline1"
                    className="border-l border-dashed !border-[#ddd] pl-6 pb-8 relative !pr-5 min-h-[70px]"
                  >
                    <span className="text-xl bg-white w-9 h-6 absolute text-center -left-4.5 top-[-2px]">
                      {type === "System Notes" ? <FaLaptop /> : <FaUser />}
                    </span>
                    <div
                      className="flex gap-1 cursor-pointer text-[14px] font_pop"
                      onClick={() =>
                        setSelectNote(
                          selectNote >= 0 && selectNote === index ? null : index
                        )
                      }
                    >
                      <div className="usrName font-bold relative mr-1 ">
                        {createdBy?.username
                          ? createdBy?.username
                          : type?.split(" ")[0]}
                        <span className="ml-1 font-normal">|</span>
                      </div>
                      <div className="postTime">
                        {dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A")}
                      </div>
                    </div>
                    {!params && (
                      <div
                        ref={textRef}
                        className="msgDesc cursor-pointer hover:underline text-[14px] font_pop"
                        onClick={() => {
                          setSelectNote(
                            selectNote >= 0 && selectNote === index
                              ? null
                              : index
                          );
                          !params && handleCopy(notes);
                        }}
                      >
                        {notes}
                      </div>
                    )}
                    {params &&
                      Object.entries(params).map(([key, value], index) => {
                        return (
                          <div
                            key={key}
                            className="cursor-pointer hover:underline text-[14px] font_pop"
                            onClick={() =>
                              handleCopy(`${key
                                .replaceAll("_id", "")
                                .replaceAll("_", " ")}
                            update from "${value.old}" to "${value.new}"`)
                            }
                          >
                            <span className="font-semibold">
                              {`${index + 1}. ${key
                                .replaceAll("_id", "")
                                .replaceAll("_", " ")}`}{" "}
                            </span>
                            update from "{value.old}" to "{value.new}"
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <div className="relative w-full px-3.7 my-2">
                <input
                  className={
                    "shadow appearance-none border rounded w-full py-2 !pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm border w-full pl-5 h-[31px]"
                  }
                  style={{ minHeight: 31 }}
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <div className="absolute top-[3px] left-6 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
                  <FaSearch />
                </div>
              </div>
              <Button
                text={"Search"}
                onClick={() => {
                  setJobNumber(searchText);
                }}
                variant="btn_submit"
              />
              <div className="col-11 text-center mt-5" style={{ height: 500 }}>
                No Data Found
              </div>
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
}
