import React, { useEffect, useState } from "react";
import "./custom.css";
import { laptop, logo_towingwiz, tow_truck_no_bg } from "../../images";
import { Link, useNavigate } from "react-router-dom";
function CoverPage() {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    console.log(
      "🧑‍💻 ~ file: CoverPage.jsx:11 ~ useEffect ~ window.location.hostname:",
      window.location.hostname
    );
    if (window.location.hostname != "towingwiz.com") {
      navigate("/login");
    } else {
      setShowContent(true);
    }
  }, [navigate]);

  if (!showContent) {
    return null;
  }

  return (
    <div>
      <header class="main-header fixed w-full">
        <div class="logo-cont">
          <div class="logo-img">
            <Link to={"/"}>
              <img src={logo_towingwiz} alt="TowingWiz" />
            </Link>
          </div>
        </div>
        {/* <div class="nav-cont">
          <div class="nav-bar">
            <div class="collape navbar-collaps" id="collapsibleNavbar">
              <ul>
                <li>
                  <Link to={"/"} className="text-menu hover:text-primary-100">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="text-menu hover:text-primary-100">
                    About
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="text-menu hover:text-primary-100">
                    Solutions
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="text-menu hover:text-primary-100">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div class="loginBtn-cont">
          <div class="portal-login-btn">
            <Link to={"/login"}>CRM Login</Link>
            {/* <a href="https://app.towingwiz.com/tow" target="_blank">
              CRM Login
            </a> */}
          </div>
        </div>
      </header>
      <section class="banner-section">
        <div class="banner-content">
          <div class="lap-img">
            <img src={laptop} alt="" />
          </div>

          <div class="banner-txt">
            <div class="anim-gif">
              <img src={tow_truck_no_bg} alt="" />
            </div>
            <h1>
              <span class="txt-md">Auto Towing &</span>
              <span class="txt-bold text-primary">Roadside</span>
              <span class="txt-bold">Assistance Software</span>
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CoverPage;
