import api from "./api";

export const getJobStatus = async (data) => {
  return await api.get("/api/jobstatus",{params:data});
};

export const createJobStatus = async (values) => {
  return await api.post("/api/jobstatus", values);
};

export const updateJobStatus = async (values) => {
  return await api.put("/api/jobstatus", values);
};

export const deleteJobStatus = async (id) => {
  return await api.delete("/api/jobstatus", { data: { id } });
};
