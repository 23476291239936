import React, { useState, useEffect } from "react";
import Header from "../../organisms/Header";
import { useSelector, useDispatch } from "react-redux";
import SwitchOfficeModal from "../../../views/SwitchOffice";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../organisms/Sidebar";
import Footer from "../../organisms/Footer";
import Dialer from "../../organisms/Dialer";
import { ToastContainer } from "react-toastify";
import DotsLoader from "../../atoms/DotsLoader";
import {
  setCampaign,
  setColor,
  setDialerPhone,
  setDialerPhoneD,
  setDispoSelectStop,
  setExt,
  setInGroup,
  setStatus,
  setTimer,
} from "../../../features/dialerSlice";
import { get_job_category } from "../../../features/jobCategorySlice";
import SMS from "../../organisms/SMS";
import Recordings from "../../organisms/Recordings";
import { Timeline } from "../../organisms/Timeline";
import Backdrop from "../../atoms/Backdrop";
import socket from "../../organisms/SMS/socket";
import {
  newGroupMsg,
  newMessageList,
} from "../../../features/messageCenterSlice";
import { IoIosWarning } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { hideWarning } from "../../../features/authSlice";
import { get_site_conversation } from "../../../features/conversationSlice";
import Misscalls from "../../organisms/Misscall";
import { sendIframeMsg } from "../../../util/iFrame";
import userPermission from "../../../util/userPermission";
import jwtDecode from "jwt-decode";
import CustomModal from "./ExpireModal";
import { logout, logoutUser } from "../../../features/authSlice";
const Layout = ({ children }) => {
  const { user, sslExpiry } = useSelector((state) => state.auth);
  const [show_navlabel, setShowNavbar] = useState(true);
  const [isExpiring, setExpriring] = useState(false);
  const [show_mobilenav, setShow_mobilenav] = useState(false);
  const [show_nav, setShow_nav] = useState(true);
  const [isSwitchOffice, setIsSwitchOffice] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [isDialer, setIsDialer] = useState("hidden");
  const [logingOut, setLogingOut] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isMisscall, setIsMisscall] = useState(false);
  const [isRecordings, setIsRecordings] = useState(false);
  const [isTimeline, setIsTimeline] = useState(false);
  const [isDroping, setIsDroping] = useState(false);
  const [isDroped, setIsDroped] = useState(false);
  const [isDropingOut, setIsDropingOut] = useState(false);
  const [sslDomains, setSslDomains] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [misscallDate, setMisscallDate] = React.useState(new Date());
  React.useEffect(() => {
    calculateRemainingDays();
    // eslint-disable-next-line
  }, [sslExpiry]);

  const calculateRemainingDays = () => {
    const currentDate = new Date();
    const updatedDomains = sslExpiry?.map((domain) => {
      if (domain.expirationDate) {
        const expirationDate = new Date(domain.expirationDate);
        const remainingTime = expirationDate.getTime() - currentDate.getTime();
        const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
        return { ...domain, remainingDays };
      } else {
        return { ...domain };
      }
    });
    setShowWarning(
      updatedDomains?.filter(({ remainingDays }) => remainingDays < 5)?.length >
        0 && user?.role === "SUPER ADMINISTRATOR"
        ? true
        : false
    );
    setSslDomains(updatedDomains);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
    setShow_nav(!show_nav);
  };
  useEffect(() => {
    if (location?.pathname.includes("client")) {
      setIsForm(true);
    } else {
      setIsForm(false);
    }
  }, [location]);
  useEffect(() => {
    if (!user) {
      setIsDialer("hidden");
    }
    if (user) {
      if (userPermission("Quoting")) {
        dispatch(get_job_category());
      }

      if (userPermission("Conversations")) {
        dispatch(get_site_conversation({ page: 1, size: 100 }));
      }
    }
    // eslint-disable-next-line
  }, [user]);
  const checkTokenExpiry = () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (!refreshToken) {
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
      return;
    }
    // Retrieve the token's expiry time from local storage
    const jwt = jwtDecode(refreshToken);
    // Get current time in Unix timestamp (seconds)
    const currentTime = Math.floor(Date.now() / 1000);

    // Calculate the time left before the token expires
    const timeLeft = jwt.exp - currentTime;
    // Check if the token is going to expire in the next 2 minutes (120 seconds)
    if (timeLeft > 0 && timeLeft <= 120) {
      setExpriring(true);
    } else if (timeLeft <= 0) {
      // Token has already expired
      setExpriring(false);
      alert("Your session has expired. Please log in again.");
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
    }
  };
  useEffect(() => {
    // Check token expiry on component mount
    checkTokenExpiry();
    // Set up an interval to check token expiry every minute
    const interval = setInterval(checkTokenExpiry, 10000); // 60000 milliseconds = 1 minute
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const [hasNewMessage, setHasNewMessage] = useState(false);
  const eventName =
    user?.company_name?.toLowerCase().replaceAll(" ", "_") + "_message_list";
  // React.useEffect(() => {
  //   if (user) {
  //     const handleNewMessage = (message) => {
  //       dispatch(newMessageList(message));
  //       if (!isSMS) {
  //         setHasNewMessage(true);
  //       }
  //     };

  //     const handleNewGroupMessage = (message) => {
  //       dispatch(newGroupMsg(message));
  //       if (!isSMS) {
  //         setHasNewMessage(true);
  //       }
  //     };
  //     // Connect only if not already connected
  //     if (!socket.connected) {
  //       socket.connect();
  //     }
  //     // Setup event listeners only after successful connection
  //     const setupEventListeners = () => {
  //       socket.emit("join", eventName);
  //       socket.on(eventName, handleNewMessage);
  //       socket.on("message_list", handleNewGroupMessage);
  //     };

  //     if (socket.connected) {
  //       setupEventListeners();
  //     } else {
  //       socket.on("connect", setupEventListeners);
  //     }
  //     return () => {
  //       socket.off(eventName, handleNewMessage);
  //       socket.off("message_list", handleNewGroupMessage);
  //       socket.off("connect", setupEventListeners); // Ensure to remove this listener to prevent duplication
  //       if (socket.connected) {
  //         socket.disconnect();
  //       }
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    if (hasNewMessage) {
      setHasNewMessage(false);
    }
  }, [hasNewMessage]);
  const receiveMessage = (event) => {
    if (event?.data?.action === "ManualDialNext") {
      if (event.data.phone_number) {
        dispatch(
          setDialerPhone({
            phone: event.data.phone_number,
            callType: "Outgoing",
            dialer_lead_id: event?.data?.lead_id,
            vicidial_id: event?.data?.vicidial_id,
          })
        );
        dispatch(
          setDialerPhoneD({
            phone: event.data.phone_number,
            callType: "Outgoing",
          })
        );
        if (!event.data.api_click) {
          navigate("/leads/add");
        }
      }
    } else if (event?.data?.action === "check_for_auto_incoming") {
      if (event?.data?.phone_number) {
        dispatch(
          setDialerPhone({
            phone: event.data.phone_number,
            callType: "Incoming",
            dialer_lead_id: event?.data?.lead_id,
            vicidial_id: event?.data?.vicidial_id,
            did_pattern: event?.data?.did_pattern,
            did_description: event?.data?.did_description,
          })
        );
        dispatch(
          setDialerPhoneD({
            phone: event.data.phone_number,
            callType: "Incoming",
            did_pattern: event?.data?.did_pattern,
            did_description: event?.data?.did_description,
          })
        );
        dispatch(setDispoSelectStop(false));
        if (!event.data.api_click) {
          navigate("/leads/add");
        }
      }
    } else if (event.data.action === "agent_state_start") {
      if (event?.data?.status) {
        dispatch(setStatus(event.data.status));
        dispatch(setExt(event.data.phone_login));
      }
      if (event?.data?.campaign) {
        dispatch(setCampaign(event.data.campaign));
      }
      if (event?.data?.HHMMSS) {
        dispatch(setTimer(event.data.HHMMSS));
      }
      if (event?.data?.status === "logout") {
        dispatch(setStatus(""));
        dispatch(setExt(""));
        dispatch(setTimer(""));
        dispatch(setCampaign(""));
      }
      if (event?.data?.status === "logged_out") {
        dispatch(setStatus(""));
        dispatch(setExt(""));
        dispatch(setTimer(""));
      }
      if (event?.data?.classColor) {
        dispatch(setColor(event.data.classColor));
      }
    }
    if (event?.data?.action === "DispoSelectStop") {
      dispatch(setDispoSelectStop(event.data.checked));
    }
    if (event?.data?.action === "DeactivateDOlDSessioNSpan") {
      sendIframeMsg({
        action: "DeactivateDOlDSessioNSpan",
        user: user?.dialer_data?.dialer_user,
        pass: user?.dialer_data?.dialer_pass,
      });
    }
    if (event?.data?.action === "InGroup") {
      dispatch(setInGroup(event?.data?.value));
    }
  };
  const logoutHandler = async () => {
    await dispatch(logout());
    const eventName =
      user?.company_name?.toLowerCase().replaceAll(" ", "_") + "_message_list";
    socket.off(eventName);
    socket.off("message_list");
    socket.off("connect");
    socket.disconnect();
    socket.close();
    sendIframeMsg({
      action: "logout",
      user: user?.dialer_data?.dialer_user,
      pass: user?.dialer_data?.dialer_pass,
    });
    setExpriring(false);
    const timer = setTimeout(() => {
      dispatch(logoutUser());
      dispatch(setStatus(""));
      dispatch(setTimer(""));
    }, 0);
    return () => clearTimeout(timer);
  };
  window.addEventListener("message", receiveMessage);
  return (
    <>
      <style>{`
        @media (min-width: 768px) {
          .middle-cont {
            margin-left: 235px;
            width: calc(100% - 235px);
            height: calc(100vh - 28px);
          }
        }
        @media (max-width: 768px) {
          .middle-cont {
            width: 100%;
          }
        }
      `}</style>
      {user && isForm ? (
        children
      ) : (
        <>
          {user && !isForm && (
            <>
              {showWarning &&
              sslDomains?.filter(({ remainingDays }) => remainingDays < 5)
                ?.length === 1 ? (
                <div className="flex relative bg-[#fcf7b7]  md:w-[calc(100%-235px)] md:ml-[235px] items-center !px-2 !h-[30px] z-[100] font_pop justify-between">
                  <span>
                    <IoIosWarning className="!mr-2" />
                    This is to inform you that the SSL certificate for a Server
                    at "
                    {
                      sslDomains?.find(({ remainingDays }) => remainingDays < 5)
                        ?.domain
                    }
                    " will be expired in{" "}
                    {
                      sslDomains?.find(({ remainingDays }) => remainingDays < 5)
                        ?.remainingDays
                    }
                    .
                  </span>
                  <AiOutlineCloseCircle
                    onClick={() => {
                      dispatch(hideWarning());
                    }}
                  />
                </div>
              ) : sslDomains?.filter(({ remainingDays }) => remainingDays < 5)
                  ?.length > 1 ? (
                <div className="flex relative bg-[#fcf7b7]  md:w-[calc(100%-235px)] md:ml-[235px] items-center !px-2 !h-[30px] z-[100] font_pop justify-between">
                  <span>
                    <IoIosWarning className="!mr-2" />
                    This is to inform you that the SSL certificate for{" "}
                    {
                      sslDomains?.filter(
                        ({ remainingDays }) => remainingDays < 5
                      )?.length
                    }{" "}
                    servers will expire soon.
                  </span>
                  <AiOutlineCloseCircle
                    onClick={() => {
                      dispatch(hideWarning());
                    }}
                  />
                </div>
              ) : null}
              <div className="fixed top-0 w-full z-10">
                {/*<Header
                  toggleNavbar={setShow_mobilenav}
                  show_mobilenav={show_mobilenav}
                />*/}
                <Header toggleNavbar={setShow_nav} show_nav={show_nav} />
              </div>
              <div className="flex flex-nowrap relative overflow-y-hidden overflow-hidden max-w-[100vw] bg-white">
                <Dialer
                  modalTitle="SOFTPHONE"
                  isDialer={isDialer}
                  setIsDialer={setIsDialer}
                  setIsDroping={setIsDroping}
                  isDroping={isDroping}
                  setIsDroped={setIsDroped}
                  isDroped={isDroped}
                  setIsDropingOut={setIsDropingOut}
                />
                <div className="px-0 ">
                  <div
                    className={`transition-all ease-in-out delay-150 fixed w-[235px]  ${
                      show_nav
                        ? "left-[-235px] md:left-0"
                        : "left-0 md:left-[-235px]"
                    }`}
                  >
                    <Sidebar
                      toggleNavbar={toggleNavbar}
                      show_navlabel={show_navlabel}
                      setIsSwitchOffice={setIsSwitchOffice}
                      setIsForm={setIsForm}
                      setLogingOut={setLogingOut}
                      isDroping={isDroping}
                      isDroped={isDroped}
                      isDropingOut={isDropingOut}
                      isDialer={isDialer}
                      showNav={show_nav}
                    />
                  </div>
                </div>

                {/*<div className={"flex justify-center h-screen middle-cont"}>*/}
                <div
                  className={`flex justify-center transition-all ease-in-out delay-150 h-screen w-full pb-5 ${
                    show_nav
                      ? "ml-0 md:!ml-[235px] md:w-[calc(100%-235px)]"
                      : "ml-[235px] md:ml-0"
                  }`}
                >
                  {isSwitchOffice && (
                    <SwitchOfficeModal
                      modalTitle="Switch Office"
                      onCancelForm={() => setIsSwitchOffice(false)}
                    />
                  )}

                  {logingOut && <DotsLoader />}
                  <div
                    className="w-full"
                    style={{
                      overflowY: "auto",
                      marginBottom: 20,
                      paddingTop: 63,
                    }}
                  >
                    <div className="mx-2">
                      {isDropingOut && (
                        <div className="absolute left-[calc(15vw)] top-0 bottom-0 right-0 !bg-[rgba(213,213,213,0.5)] z-[99]">
                          <div className="bg-[#f7f7f7] h-[300px] w-[210px] absolute bottom-0.5 ml-1 shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-t-md" />
                        </div>
                      )}

                      {children}
                    </div>
                    {isSMS && <SMS setIsSMS={setIsSMS} isSMS={isSMS} />}
                    {isMisscall && (
                      <Misscalls
                        setIsMisscall={setIsMisscall}
                        isMisscall={isMisscall}
                        setMisscallDate={setMisscallDate}
                        misscallDate={misscallDate}
                      />
                    )}
                    {user?.role_level !== 7 ? (
                      <div
                        className={`bg-white transition-all ease-in-out delay-150 h-10 fixed bottom-0  box-border text-xs border-[#b2b2b2] shadow-[0_0_6px_rgb(0_0_0_/20%)] pl-0 ${
                          show_nav
                            ? "w-full md:w-[calc(100%-220px)]"
                            : "w-full md:w-full"
                        }`}
                      >
                        <Footer
                          toggleNavbar={toggleNavbar}
                          setIsDialer={setIsDialer}
                          setIsSMS={setIsSMS}
                          isSMS={isSMS}
                          setIsMisscall={setIsMisscall}
                          isMisscall={isMisscall}
                          isDialer={isDialer}
                          setIsRecordings={setIsRecordings}
                          setIsTimeline={setIsTimeline}
                          isRecordings={isRecordings}
                          isTimeline={isTimeline}
                          setMisscallDate={setMisscallDate}
                          misscallDate={misscallDate}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {show_mobilenav && (
                <>
                  <Backdrop onClick={() => setShow_mobilenav(false)} />
                  <div
                    className={[
                      "bottom_navbar col-md-2 px-0 block md:hidden absolute h-full ",
                    ].join(" ")}
                    style={{
                      backgroundColor: "#fff",
                      zIndex: 100,
                      width: "218px",
                      top: 0,
                      transition: "1s",
                    }}
                  >
                    <Sidebar
                      toggleNavbar={toggleNavbar}
                      show_navlabel={show_navlabel}
                      setIsSwitchOffice={setIsSwitchOffice}
                      setIsForm={setIsForm}
                      setLogingOut={setLogingOut}
                    />
                  </div>
                </>
              )}
              {isRecordings && (
                <Recordings
                  setIsRecordings={setIsRecordings}
                  isRecordings={isRecordings}
                  modalTitle="Recordings"
                />
              )}
              {isTimeline && (
                <Timeline
                  setIsTimeline={setIsTimeline}
                  isTimeline={isTimeline}
                  modalTitle="Timeline"
                />
              )}
            </>
          )}
        </>
      )}
      {!user && !isForm ? children : !user && isForm && children}

      {isExpiring && user ? (
        <CustomModal
          logoutHandler={logoutHandler}
          onClose={() => setExpriring(false)}
        />
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Layout;
