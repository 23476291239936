import React, { useEffect, useState } from "react";
import { Button, DotsLoader } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { get_cities, get_geo_states } from "../../features/GeoVendorsSlice";
import { toast } from "react-toastify";
import api from "../../services/api";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
const MajorCities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [majorCities, setMajorCities] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { isLoading, states, cities } = useSelector(
    (state) => state.geoVendors
  );
  const initialValue = {
    state_id: "",
    state_name: "",
    city_id: "",
    city_name: "",
    latitude: "",
    longitude: "",
    city_population:""
  };
  const [filterData, setfilterData] = useState(initialValue);
  useEffect(() => {
    dispatch(get_geo_states());
    getMajorCities();
    // eslint-disable-next-line
  }, []);

  const getMajorCities = async () => {
    try {
      const res = await api.get("/api/reports/major_city");
      if (res.status === 200) {
        setMajorCities(res.data);
      }
    } catch (err) {
      console.error(
        "🚀 ~ file: MajorCities.jsx:36 ~ getMajorCities() ~ err:",
        err
      );
    }
  };
  const addMojorCities = async () => {
    
    if (filterData?._id) {
      try {
        const res = await api.put("/api/reports/major_city", {
          ...filterData,
          id: filterData?._id,
        });
        if (res.status === 200) {
          const data = res.data;
          //   setMajorCities(res.data);
          const dataIndex = majorCities.findIndex(
            (item) => item._id === data?._id
          );

          if (dataIndex !== -1) {
            // Step 3: Replace the existing item with the new data
            const updatedData = [...majorCities];
            updatedData[dataIndex] = { ...updatedData[dataIndex], ...data };

            // Step 4: Update the state with the modified data
            setMajorCities(updatedData);
          }
          toast.success("Major City updated successfully");
          setfilterData(initialValue);
        }
      } catch (err) {
        console.error(
          "🚀 ~ file: MajorCities.jsx:36 ~ getMajorCities() ~ err:",
          err
        );
        toast.error("Major City couldn't be update");
      }
    } else {
      try {
        const res = await api.post("/api/reports/major_city", filterData);
        if (res.status === 200) {
          setMajorCities([res?.data, ...majorCities]);
          setfilterData(initialValue);
          toast.success("Major City added successfully");
        }
      } catch (err) {
        console.error(
          "🚀 ~ file: MajorCities.jsx:36 ~ getMajorCities() ~ err:",
          err
        );
        toast.error("Major City couldn't be update");
      }
    }
  };
  const deleteMojorCities = async (id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) {
      return;
    }
    try {
      const res = await api.delete(`/api/reports/major_city/${id}`);
      if (res.status === 200) {
        getMajorCities()
      }
    } catch (err) {
      console.error(
        "🚀 ~ file: MajorCities.jsx:36 ~ getMajorCities() ~ err:",
        err
      );
    }
  };
  const record = majorCities?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  useEffect(() => {
   if(filterData?.city_id&& !filterData?.city_population){
    const population=cities?.find(({id})=>id===filterData?.city_id)?.city_population
    setfilterData({...filterData,city_population:population})
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities])
  
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    { headerName: "State Name", field: "state_name", flex: 1, minWidth: 150 },
    { headerName: "City Name", field: "city_name", flex: 1, minWidth: 150 },
    { headerName: "City Population", field: "city_population", flex: 1, minWidth: 150 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={() => {
              dispatch(get_cities({ state_id: params.row.records?.state_id }));
              setfilterData({ ...params.row.records })}}
            className="my_navIcon"
            title="Update City"
          />
          <FaTrashAlt
            onClick={() => deleteMojorCities(params.row.records?._id)}
            className="my_navIcon"
            title="Delete City"
          />
        </div>
      ),
      width: 100,
    },
  ];
  return (
    <>
      <PageHeader
        route="Setting > Vendors"
        heading="Major Cities"
        onClick={() => navigate("/geolocation/locations")}
        // isAllowed={userPermission("Geo Location")}
      />
      <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
        {isLoading && <DotsLoader />}

        <div className="flex flex-row items-center">
          <div className="flex flex-col w-full ml-2">
            <label className="inputLabel" htmlFor="industries">
              {"State"}
            </label>
            <Select
              name="state"
              placeholder="Select State"
              onChange={(e) => {
                setfilterData({
                  ...filterData,
                  state_id: e?.id,
                  state_name: e?.name,
                  city_id: "",
                  city_name: "",
                  latitude: "",
                  longitude: "",
                  city_population:""
                });
                dispatch(get_cities({ state_id: e.id }));
              }}
              options={states}
              valueKey="id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                filterData?.state_id
                  ? {
                      id: filterData.state_id,
                      name: filterData.state_name,
                    }
                  : null
              }
            />
          </div>
          <div className="w-full ml-2 flex flex-col ">
            <label className="inputLabel" htmlFor="logs">
              {"City"}
            </label>
            <Select
              placeholder="Select City"
              onChange={(e) => {
                setfilterData({
                  ...filterData,
                  city_id: e?.id,
                  city_name: e?.name,
                  latitude: e.latitude,
                  longitude: e.longitude,
                  city_population:e.city_population
                });
              }}
              options={cities}
              valueKey="id"
              labelKey="name"
              className="w-full"
              menuPosition="fixed"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={
                filterData?.city_id
                  ? {
                      id: filterData.city_id,
                      name: filterData.city_name,
                    }
                  : null
                //   className="h-[38px] max-w-[600px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              }
            />
          </div>
          <Button
            text={filterData?._id ? "Update" : "Save"}
            className="py-1.5 px-3 align-middle mx-2 bg-primary-100 text-white !mt-6"
            onClick={() => {
              if (!filterData?.state_id || !filterData?.city_id) {
                toast.error("Please Select State and City");
              } else {
                addMojorCities();
              }
            }}
          />
          <Button
            text="Cancel"
            className="py-1.5 px-3 align-middle bg-menu text-white !mt-6"
            onClick={() => {
              setfilterData(initialValue);
            }}
          />
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={record?.map((record, index) => {
            let counter = index + 1;
            const { state_name, city_name,city_population } = record;
            return {
              counter,
              records: record,
              state_name,
              city_name,
              city_population:city_population?Number(city_population)?.toLocaleString():0
            };
          })}
          // onRowSelectionModelChange={handleRowSelection}
          totalItems={majorCities?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          paginationMode={"client"}
          // checkboxSelection={true}
          // ref={gridRef}
        />
      </div>
    </>
  );
};

export default MajorCities;
