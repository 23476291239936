import React, { useState } from "react";
import { DotsLoader, Loader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import { get_industry, industryReset } from "../../features/IndustrySlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  FaDownload,
  FaEdit,
  FaFileInvoiceDollar,
  FaSearch,
} from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import { useNavigate } from "react-router-dom";
import { get_recordings } from "../../features/dialerSlice";
import { toast } from "react-toastify";
import QuotingModal from "./QuotingModal";
import { get_users_drd } from "../../features/usersSlice";
import { userPermissionNew } from "../../util/userPermissionNew";
function QuotingRequests() {
  const { usersDrd } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = React.useState("");
  const [selectedLead, setSelectedLead] = React.useState(null);
  const [isQuoteModal, setIsQuoteModal] = useState(null);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPReport = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    const payload = {
      ...filter,
      filters: {
        ...filter?.filters,
        rules: [
          ...(filter?.filters?.rules || []),
          { field: "quoting_request_status", op: "eq", data: "Pending" },
        ],
      },
    };
    if (!payload?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("api/leads/report", payload);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    dispatch(get_users_drd());
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handlePlay = async (lead_num) => {
    setLoading(true);
    setSelectedLead(lead_num);
    try {
      const res = await dispatch(
        get_recordings({ lead_id: lead_num, type: "Lead" })
      );
      if (
        res?.payload?.status === 200 &&
        res?.payload?.data?.recordings?.length > 0
      ) {
        const vicidialId = res?.payload?.data?.recordings[0]?.vicidial_id;
        const url = res?.payload?.data?.recordings[0]?.location;

        setRecord((prevRecord) => {
          const newData = [...prevRecord.data]; // Create a shallow copy of the data array
          const resultIndex = newData.findIndex(
            ({ vicidial_id }) => vicidial_id === vicidialId
          );

          if (resultIndex > -1) {
            newData[resultIndex] = {
              ...newData[resultIndex],
              recording: url,
            };
          }

          return {
            ...prevRecord,
            data: newData,
          };
        });
        // await dispatch(
        //   setRecording({
        //     vicidial_id: res?.payload?.data?.recordings[0]?.vicidial_id,
        //     url: res?.payload?.data?.recordings[0]?.location,
        //   })
        // );
        const audioElement = document.getElementById(
          res?.payload?.data?.recordings[0]?.vicidial_id
        );
        if (audioElement) {
          if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
            currentlyPlayingAudio.pause();
          }
          audioElement.play();
          setCurrentlyPlayingAudio(audioElement);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("No Record found");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching audio URL:", error);
    }
  };
  const columnDefs = [
    { headerName: "Lead #", field: "lead_num" },
    // { headerName: "Created By", field: "createdBy", flex: 1 },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: usersDrd,
      renderCell: (params) => params.value?.createdBy,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
    },
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Phone", field: "phone", flex: 1 },
    {
      headerName: "Created",
      field: "createdAt",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Status",
      field: "job_created",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { value: false, label: "Job not created" },
        { value: true, label: "Job created" },
      ],
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        job_created: value?.value,
      }),
      valueFormatter: (params) => params.value?.job_created,
      renderCell: (params) => (
        <div>
          {`${params.row.job_created}${params.row.job_number ? " | " : ""}`}
          {params.row.job_number ? (
            <a
              href={`/jobs/update/preview/${params.row._id}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:underline cursor-pointer"
            >
              {`${params.row.job_number}`}
            </a>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Recording",
      field: "recording",
      flex: 1,
      minWidth: 300,
      filterable: false,
      renderCell: (params) =>
        params.row.records.call_type !== "Manual" &&
        params.row.records.vicidial_id ? (
          <div className="relative flex items-center">
            <audio
              id={params.row.records?.vicidial_id}
              controls
              className="h-10 w-[250px] relative"
            >
              {params.row.records.recording && (
                <source
                  src={params.row.records.recording}
                  type="audio/mpeg"
                  onClick={() => handlePlay(params.row.records?.lead_num)}
                />
              )}
            </audio>
            {!params.row.records.recording ? (
              <button
                onClick={() => handlePlay(params.row.records?.lead_num)}
                className="h-[30px] w-[30px] z-[100] bg-primary-100 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center rounded-full text-white"
              >
                {loading && selectedLead === params.row.records?.lead_num ? (
                  <Loader />
                ) : null}
                <FaDownload />
              </button>
            ) : null}
          </div>
        ) : params.row.records.call_type === "Manual" ? (
          <span>Manual Lead</span>
        ) : null,
    },
    {
      headerName: "Request Status",
      field: "quoting_request_status",
      flex: 1,
      type: "singleSelect",
      minWidth: 150,
      filterable: false,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { label: "NA", value: "NA" },
        { label: "Pending", value: "Pending" },
        { label: "Quoted", value: "Quoted" },
        { label: "Rejected", value: "Rejected" },
      ],
      renderCell: (params) => params.value?.quoting_request_status,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        quoting_request_status: value?.value,
      }),
      valueFormatter: (params) => params.value?.quoting_request_status,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      renderCell: (params) =>
        !params.row.records.is_declined && !params.row.records.job_created ? (
          <div>
            {userPermission("Update Lead") && (
              <FaEdit
                onClick={() => {
                  navigate(`/leads/update/${params.row._id}`);
                }}
                className="my_navIcon"
                title="Update Lead"
              />
            )}
            {userPermissionNew("Quoting Request") &&
            params.row.records.quoting_request_status === "Pending" ? (
              <FaFileInvoiceDollar
                onClick={() => {
                  setIsQuoteModal(params.row.records);
                }}
                className="my_navIcon"
                title="Quoting Request"
              />
            ) : null}
          </div>
        ) : (
          <>
            {!params.row.records.is_declined &&
              params.row.records.job_created && (
                <div>
                  {userPermission("Update Lead") && (
                    <FaEdit
                      onClick={() => {
                        navigate(`/jobs/update/${params.row._id}`);
                      }}
                      className="my_navIcon"
                      title="Update Job"
                    />
                  )}
                </div>
              )}
          </>
        ),
    },
  ];

  React.useEffect(() => {
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const reloadReport = () => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };

  return (
    <>
      <PageHeader
        route="Setting > Industries"
        // heading="Quotes"
      />
      {isQuoteModal ? (
        <QuotingModal
          onClose={() => setIsQuoteModal(null)}
          invoiceDetail={isQuoteModal}
          reloadReport={reloadReport}
        />
      ) : null}
      {isLoading ? <DotsLoader /> : null}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const {
              _id,
              lead_num,
              job_number,
              createdBy,
              name,
              phone,
              is_declined,
              createdAt,
              job_created,
              recording,
              quoting_request_status,
            } = record;
            return {
              _id,
              counter: index + 1,
              records: record,
              lead_num,
              createdBy: createdBy?.username,
              name,
              phone,
              is_declined,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              recording,
              job_created: `${
                is_declined ? "Declined" : job_created ? "Job Created" : "Open"
              }`,
              quoting_request_status:
                quoting_request_status === "NA" ? "" : quoting_request_status,
              job_number,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </div>
    </>
  );
}

export default QuotingRequests;
