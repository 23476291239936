import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotsLoader from "../../components/atoms/DotsLoader";
import {
  clearAll,
  get_geo_location_detail,
  get_search_logs,
} from "../../features/geoLocSlice";
import "react-bootstrap-tagsinput/dist/bootstrap.css";
import "react-tagsinput/react-tagsinput.css";
import { useLocation } from "react-router-dom";
import { get_geo_vendors, get_geo_vendors_drd } from "../../features/GeoVendorsSlice";
import { get_geo_industry } from "../../features/GeoIndustrySlice";
import Summary from "./Summary";
import { Button } from "../../components";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
const GeoReport = () => {
  const location = useLocation();
  const editingRecord = location?.state;
  let initialValues = {
    vendor_id: null,
    industry_id: null,
    description: "",
    payload: [],
  };
  if (editingRecord) {
    const { id, vendor_id, industry_id, description, payload } = editingRecord;
    initialValues = {
      id,
      vendor_id: vendor_id?.id,
      industry_id: industry_id?.id,
      description,
      payload: payload ? JSON.parse(payload) : null,
    };
  }
  const isUpdating = location.pathname.startsWith(
    "/update/geolocation/locations"
  );
  const { isLoading, locationDetails } = useSelector((state) => state.geoLoc);
  const [vendor_id, setVendor_id] = useState([]);
  const [industry_id, setIndustry_id] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [loader, setLoader] = useState(false);
  const industry = useSelector((state) => state.geoIndustry);
  const vendors = useSelector((state) => state.geoVendors);
  const [currentPayloadIndex, setCurrentPayloadIndex] = useState(0);
  const dispatch = useDispatch();
  const [isSummary, setIsSummary] = useState(false);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [vendorType, setVendorType] = useState("Own");
  useEffect(() => {
    // dispatch(clearAll());
    dispatch(get_geo_vendors());
    dispatch(get_geo_industry());
    dispatch(clearAll());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(get_geo_vendors_drd({vendor_type:vendorType}));
    // eslint-disable-next-line
  }, [vendorType]);
  const cityCount = {};
  let totalCitiesList = [];
  let uniqueCitiesList = [];
  let totalCities = 0;
  let uniqueCities = 0;
  let duplicateCities = [];

  const stateCount = {};
  let totalStatesList = [];
  let uniqueStatesList = [];
  let duplicateStates = [];

  let totalZipsList = [];
  let uniqueZipsList = [];
  const zipCount = {};
  let totalZips = 0;
  let uniqueZips = 0;
  let duplicateZips = [];
  let total_population = 0;
  locationDetails?.map((data) => (
    data.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      if (Array.isArray(item?.cities)) {
        item.cities.forEach((city) => {
          totalCities++;
          totalCitiesList = [...totalCitiesList, city.name];
          if (cityCount[city.name]) {
            cityCount[city.name]++;
          } else {
            cityCount[city.name] = 1;
            uniqueCitiesList = [...uniqueCitiesList, city.name];
            uniqueCities++;
          }
        });
        for (let city in cityCount) {
          if (cityCount[city] > 1 && !duplicateCities.includes(city)) {
            duplicateCities.push(city);
          }
        }
      } else if (Array.isArray(item?.data?.cities)) {
        item.data.cities.forEach((city) => {
          totalCities++;
          totalCitiesList = [...totalCitiesList, city.name];
          if (cityCount[city.name]) {
            cityCount[city.name]++;
          } else {
            cityCount[city.name] = 1;
            uniqueCitiesList = [...uniqueCitiesList, city.name];
            uniqueCities++;
          }
        });
        for (let city in cityCount) {
          if (cityCount[city] > 1 && !duplicateCities.includes(city)) {
            duplicateCities.push(city);
          }
          if (cityCount[city] === 3) {

          }
        }
      }

      if (Array.isArray(item?.states)) {
        item.states.forEach((state) => {
          totalStatesList = [...totalStatesList, state.name];
          if (stateCount[state.name]) {
            stateCount[state.name]++;
          } else {
            stateCount[state.name] = 1;
            uniqueStatesList = [...uniqueStatesList, state.name];
          }
        });
        for (let state in stateCount) {
          if (stateCount[state] > 1 && !duplicateStates.includes(state)) {
            duplicateStates.push(state);
          }
        }
      } else if (Array.isArray(item?.data?.states)) {
        item.data.states.forEach((state) => {
          totalStatesList = [...totalStatesList, state.name];
          if (stateCount[state.name]) {
            stateCount[state.name]++;
          } else {
            stateCount[state.name] = 1;
            uniqueStatesList = [...uniqueStatesList, state.name];
          }
        });
        for (let state in stateCount) {
          if (stateCount[state] > 1 && !duplicateStates.includes(state)) {
            duplicateStates.push(state);
          }
        }
      }
      if (Array.isArray(item?.zips)) {
        item.zips.forEach((zip) => {
          totalZips++;
          totalZipsList = [...totalZipsList, zip.zip];
          if (zipCount[zip.zip]) {
            zipCount[zip.zip]++;
          } else {
            zipCount[zip.zip] = 1;
            uniqueZipsList = [...uniqueZipsList, zip.zip];
            total_population = total_population + zip?.zip_population;
            uniqueZips++;
          }
        });
        for (let zip in zipCount) {
          if (zipCount[zip] > 1 && !duplicateZips.includes(zip)) {
            duplicateZips.push(zip);
          }
        }
      } else if (Array.isArray(item?.data?.zips)) {
        item.data.zips.forEach((zip) => {
          totalZips++;
          totalZipsList = [...totalZipsList, zip.zip];
          if (zipCount[zip.zip]) {
            zipCount[zip.zip]++;
          } else {
            zipCount[zip.zip] = 1;
            uniqueZipsList = [...uniqueZipsList, zip.zip];
            total_population = total_population + zip?.zip_population;
            uniqueZips++;
          }
        });
        for (let zip in zipCount) {
          if (zipCount[zip] > 1 && !duplicateZips.includes(zip)) {
            duplicateZips.push(zip);
          }
        }
      }
      return null;
    })
  ));

  const getSearchList = async () => {
    dispatch(clearAll())
    try {
      const res = await dispatch(
        get_search_logs({
          industry_id: industry_id,
          vendor_id: vendor_id,
          merchant:user?.user_group
        })
      );
      if (res?.payload?.status === 200) {
        getData2(res.payload?.data);
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
    }
  };
  const getData2 = async (payloadArray) => {
    try {
      setLoader(true)
      for (let i = 0; i < payloadArray.length; i++) {
        const payload = JSON.parse(payloadArray[i]?.payload);
        for (let j = 0; j < payload.length; j++) {
          const res = await dispatch(get_geo_location_detail((payload[j])));
          if(res?.payload?.status===200){
            if(res.payload.data?.length>0){
              // const updatedList = res.payload.data?.map((val) => {
              //   const response = { ...val, vendor: payloadArray[i]?.vendor_id?.name };
              //   return response;
              // });
              // if (updatedList) {
              //   setSearchList((prevDetails) => [...prevDetails, ...updatedList]);
              // }
              // eslint-disable-next-line array-callback-return
              res.payload.data?.map((val)=>{
                const response={...val,vendor:payloadArray[i]?.vendor_id?.name}
                setSearchList(prevDetails => [...prevDetails, [response]]);
              })
            }
          }
        }
      }
      setLoader(false)
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
      setLoader(false)
    }
  };

  const getData = async (payload) => {
    try {
      const res = await dispatch(get_geo_location_detail(payload));
      if (res?.payload?.status === 200) {
        setCurrentPayloadIndex(currentPayloadIndex + 1);
      }
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:84 ~ FilterHandler ~ err:", err);
    }
    // setLocState([...locState,loc])
  };
  useEffect(() => {
    if (isUpdating) {
      if (currentPayloadIndex < initialValues?.payload?.length) {
        getData(initialValues?.payload[currentPayloadIndex]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayloadIndex]);

  return (
    <>
      <div className="container-fluid bg-[#f5f5f5] overflow-y-auto">
        {isLoading || loader? <DotsLoader />:null}
        {isSummary && (
          <Summary
            totalCitiesList={totalCitiesList}
            totalStatesList={totalStatesList}
            totalZipsList={totalZipsList}
            onClose={() => setIsSummary(false)}
            uniqueCitiesList={uniqueCitiesList}
            uniqueStatesList={uniqueStatesList}
            uniqueZipsList={uniqueZipsList}
            duplicateCities={duplicateCities}
            duplicateZips={duplicateZips}
            duplicateStates={duplicateStates}
            zipCount={zipCount}
          />
        )}
        <div className="items-center !m-2 bg-slate-50 !my-2 border rounded p-3">
          <div className="flex flex-row items-center">
          <div className="w-full">
            <label className="inputLabel" htmlFor="vendors">
              {"Vendors Type"}
            </label>
            <Dropdown
              value={vendorType}
              onChange={(e) => {
                setVendorType( e.value );
              }}
              options={[
                { label: "All", value: "All" },
                { label: "Global", value: "Global" },
                { label: "Own", value: "Own" },
              ]}
              optionLabel="label"
              placeholder="Select Vendor Type"
              className="!mb-2 h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
            <div className="w-full ml-2">
              <label className="inputLabel" htmlFor="vendors">
                {"Vendors"}
              </label>
              <MultiSelect
                placeholder="Select Vendors"
                options={vendors?.geoVendorDrd}
                optionLabel={(option)=>`${option?.first_name||''} ${option?.last_name||''}`}
                optionValue="id"
                filter
                maxSelectedLabels={2}
                value={vendor_id}
                onChange={(e) => setVendor_id(e.value)}
                className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
            <div className="w-full ml-2">
              <label className="inputLabel" htmlFor="industries">
                {"Industries"}
              </label>
              <MultiSelect
                placeholder="Select Industries"
                options={industry?.record}
                optionLabel="name"
                optionValue="id"
                filter
                maxSelectedLabels={2}
                value={industry_id}
                onChange={(e) => setIndustry_id(e.value)}
                className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>
          </div>

          <div className="col-span-12 md:col-span-3 flex justify-center !mt-2">
            <div className="float-right">
              <Button
                text={industry_id?.length>0||vendor_id?.length>0?"Search":"Show All"}
                className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                onClick={getSearchList}
              />
              <Button
                text="Clear"
                className="py-1.5 px-3 align-middle bg-menu text-white"
                onClick={() => {
                  dispatch(clearAll())
                }}
              />
            </div>
          </div>
        </div>
        <div className="bg-white !my-3 border rounded">
          {locationDetails?.length > 0 && (
            <div className="flex flex-row justify-between m-2">
             <div className="flex flex-row m-2">
                <div className="font-semibold">
                  <span>Total Records:{searchList?.length}</span>
                </div>
              </div>
              <div className="flex flex-row m-2">
                <div className="font-semibold">
                  <span>Total Cities:{totalCities}</span>
                </div>
                <div className="font-semibold ml-3">
                  <span>Total Zip Codes:{totalZips}</span>
                </div>
              </div>
              <div className="flex flex-row m-2">
                <div className="font-semibold">
                  <span>Unique Cities:{uniqueCities}</span>
                </div>
                <div className="font-semibold ml-3">
                  <span>Unique Zip Codes:{uniqueZips}</span>
                </div>
              </div>
              <button
                className="ml-2 btn btn-outline-success h-[40.39px]"
                type="button"
                data-testid="button-clearAll"
                onClick={() => setIsSummary(true)}
              >
                Show Summary
              </button>
            </div>
          )}

          {searchList?.length > 0 && (
            <>
              {searchList?.map((data, index) => {
                return data?.map((log, index2) => {
                  const record = log?.data;
                  const names = record?.cities
                    ?.map((item) => {
                      return item.name;
                    })
                    .join(", ");
                  const states = record?.states
                    ?.map((item) => item.name)
                    .join(", ");
                  const zips = record?.zips?.map((item) => item.zip).join(", ");
                  return (
                    <details className="border mt-3 mb-3" open>
                      <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                        <span>{`Record Search By: ${
                          data[index2]?.type
                        } | Location: ${
                          data[index2]?.name ? data[index2]?.name : ""
                        } ${
                          initialValues.payload[index]?.radiusInMeters
                            ? `| Radius: ${initialValues.payload[index]?.radiusInMeters}`
                            : ""
                        } | Vendor: ${
                          data[index2]?.vendor
                            ? data[index2]?.vendor
                            : ""
                        }`}</span>
                      </summary>

                      <div className=" m-2 bg-slate !my-3 border rounded p-1">
                        <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
                          <div className="w-2/6 px-2">
                            <span className="font-semibold ml-1.5">Zip </span>
                            <span className="font-normal ">
                              {`(${record?.zips?.length} found)`}{" "}
                            </span>

                            <textarea
                              className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                              value={zips}
                              rows={3}
                            />
                          </div>
                          <div className="w-2/6 px-2">
                            <span className="font-semibold ml-3">States </span>
                            <span className="font-normal ">
                              {`(${record?.states?.length} found)`}{" "}
                            </span>
                            <textarea
                              className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                              value={states}
                              rows={3}
                            />
                          </div>
                          <div className="w-2/6 px-2">
                            <span className="font-semibold ml-3">Cities </span>
                            <span className="font-normal ">
                              {`(${record?.cities?.length} found)`}{" "}
                            </span>
                            <textarea
                              className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                              value={names}
                              rows={3}
                            />
                          </div>
                        </div>
                      </div>
                    </details>
                  );
                });
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GeoReport;
