import React from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  create_cron_function,
  update_cron_function,
} from "../../features/cronFunctionsSlice";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.cronFunctions);
  const [errors, setErrors] = React.useState([]);

  const initialValues = {
    function_name: "",
    description: "",
    priority: "",
    ...(editingRecord && {
      id: editingRecord.id,
      function_name: editingRecord.function_name,
      description: editingRecord.description,
      priority: editingRecord.priority,
      is_disabled: editingRecord.is_disabled,
    }),
  };
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_cron_function : create_cron_function;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success(`Cron function ${message}`);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Cron function couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Cron function couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="function_name"
              label="Function Name"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="description"
              label="Description"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="priority"
              label="priority"
              formik={formik}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
