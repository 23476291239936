import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateCronFunctions";
import { FaEdit } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import {
  cronFunctionsReset,
  get_cron_function,
  updatePriority,
  update_cron_function,
} from "../../features/cronFunctionsSlice";
import ToggleSwitch from "../../components/molecules/ToggleButton";
import api from "../../services/api";
import UpdatePriorityForm from "./UpdatePriority";
import dayjs from "dayjs";
const CronFunctions = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state?.cronFunctions);
  const [isEditing, setIsEditing] = useState(false);
  const [isPriority, setIsPriority] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [editCell, setEditCell] = React.useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsPriority(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_cron_function());
    return () => {
      dispatch(cronFunctionsReset());
    };
    // eslint-disable-next-line
  }, []);
  const cronFunctions = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleToggleActive = async ({ id, function_name, is_disabled }) => {
    const c = window.confirm(
      `Are you sure you want to ${
        is_disabled ? "disable" : "enable"
      } ${function_name} function?`
    );
    if (c) {
      try {
        const res = await dispatch(
          update_cron_function({ id, disable_only: 1 })
        );
        if (res?.payload?.status === 200) {
          toast.success(
            `${function_name} function successfully ${
              is_disabled ? "disabled" : "enabled"
            }`
          );
          // cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: `${function_name} function`,
            msg: `${is_disabled ? "disabled" : "enabled"}`,
          });
        }
      } catch (err) {
        errorMessage({
          payload: err.payload,
          action: `${function_name} industry`,
          msg: `${is_disabled ? "deactivated" : "activated"}`,
        });
        console.error("🚀 ~ file: index.jsx:63 ~ openFormHandler ~ err:", err);
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 60 },
    { headerName: "Function Name", field: "function_name", flex: 1,minWidth: 200, },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Disabled",
      field: "is_disabled",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <ToggleSwitch
            // label={params?.row?.status?"Active":"Inactive"}
            checked={params?.row?.is_disabled ? true : false}
            onChange={() => handleToggleActive(params.row.records)}
            unique_by={params.row.records.id}
          />
        );
      },
    },
    {
      headerName: "Available After",
      field: "available_after",
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Ready",
      field: "is_ready",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      headerName: "Priority",
      field: "priority",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      editable: true,
    },
    { headerName: "Status", field: "status", minWidth: 100, flex: 1 },
    {
      field: "actions",
      align: "center",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update AllowedIP"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  const onStartEditing = (data) => {
    setEditCell(data);
  };
  const processRowUpdate = async (newRow) => {
    // const updatedRow = {...newRow, records?.priority:newRow.priority};
    const updatedRow = {
      ...newRow,
      records: { ...newRow.records, priority: newRow?.priority },
    };
    try {
      api
        .put(`/api/cron_function/update_priority`, {
          updates: [
            {
              id: updatedRow?.records?.id,
              priority: updatedRow?.priority,
            },
          ],
        })
        .then(() => {
          toast.success(
            `Priority of ${newRow?.function_name} updated successfully`
          );
          dispatch(
            updatePriority({
              id: updatedRow?.records?.id,
              priority: updatedRow?.priority,
            })
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error.response.data.message ||
              `Priority couldn't be updated for ${newRow?.function_name}`
          );
        })
        .finally(() => {
          console.log(
            `Priority of ${newRow?.function_name} updated successfully`
          );
        });
      return updatedRow;
    } catch (err) {
      console.error("🚀 ~ file: index.jsx:282 ~ err:", err);
      toast.error(
        err.response.data.message ||
          `Priority couldn't be updated for ${newRow?.function_name}`
      );
    }
  };
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Cron Function"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isPriority ? (
        <UpdatePriorityForm
          modalTitle="Update Priority"
          onCancelForm={cancelFormHandler}
          functions={record}
        />
      ) : null}
      <PageHeader
        route="Setting > Allowed IP"
        heading="Cron Function Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Allowed IP")}
        extraButton
        additionalBtnAllow
        extraButtonClick={() => setIsPriority(true)}
        additionalBtnTitle="Update Priority"
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={cronFunctions?.map((record, index) => {
            const {
              function_name,
              description,
              is_disabled,
              is_ready,
              priority,
              status,
              available_after,
            } = record;
            const counter = index + 1;
            return {
              counter,
              records: record,
              function_name,
              description,
              is_disabled,
              is_ready: is_ready ? "Yes" : "No",
              priority,
              status,
              available_after: dayjs(available_after).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };
          })}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          editMode="cell"
          onProcessRowUpdateError={() => {}}
          onCellEditStart={(params) => {
            onStartEditing({
              ...params.row,
            });
          }}
          processRowUpdate={processRowUpdate}
        />
      </div>
    </>
  );
};

export default CronFunctions;
