// InteliquentNumbers
import React, { useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton, TextField } from "@mui/material";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { Button, DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
import api from "../../../services/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime"; // Import the plugin
function InteliquentNumbers() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPDomainReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/domains/inteliquent_numbers", filter); //vishnu
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRowHeight = (params) => {
    const rowHeight = 30; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    if (data?.domain && data.domain?.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data?.domain.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        {/* <InputLabel>Value</InputLabel> */}
        <TextField
          value={value || ""}
          variant="standard"
          label="Filter value"
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        />
      </div>
    );
  };
  const domainCountOperators = [
    {
      label: "=",
      value: "arraySize",
      InputComponent: CustomSelectInput,
      InputComponentProps: { type: "singleSelect" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          return params.value === filterItem.value;
        };
      },
    },
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Phone Number",
      field: "phone",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => (
        <div>
          {params?.row?.domain?.map((item, index) => {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${item}`}
                className="text-[#000] hover:text-blue-600 hover:underline no-underline"
                key={index}
              >
                {item}
              </a>
            );
          })}
        </div>
      ),
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      headerName: "Domain Count",
      field: "domain_count",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
      filterOperators: domainCountOperators,
    },
    {
      headerName: "Phone Status",
      field: "phone_status",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 100,
    },
  ];
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPDomainReport({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPDomainReport({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPDomainReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const syncData = async () => {
    const c = window.confirm("Are you sure want to start the process?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "https://api.logicalcrm.com/api/dataSync/start",
        {
          sync_type: "update_inteliquent_phone_nums",
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message || "Process Start successfully");
      } else if (res.status === 201) {
        toast.info(res.data.message || "Process already running");
      } else {
        toast.error(res.data.message || "Process couldn't be Started");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message || "Process couldn't be Started");
      console.log("🚀 ~ syncData ~ err:", err);
    }
  };
  const CustomButtons = () => {
    const displayTimeDifference = (endTime) => {
      const now = dayjs();
      const end = dayjs.utc(endTime); // Make sure to use utc() if your time is in UTC

      const diffHours = now.diff(end, "hour");
      const diffMinutes = now.diff(end, "minute");

      if (diffHours < 24) {
        // If the difference is less than 24 hours, but more than an hour, show "X hours ago"
        if (diffHours >= 1) {
          return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
        } else {
          // If the difference is less than an hour, show "X minutes ago"
          return diffMinutes === 1
            ? "1 minute ago"
            : `${diffMinutes} minutes ago`;
        }
      } else {
        // Otherwise, format the date
        return end.format("ddd, MMM D, YYYY h:mm A");
      }
    };

    return (
      <div className="flex flex-col">
        <Button text="Sync" variant="btn_submit" onClick={syncData} />
        <span className="text-xs">
          {displayTimeDifference(record?.syncStatus?.end_time)}
        </span>
      </div>
    );
  };
  return (
    <>
      <PageHeader route="Setting > Industries" heading="Inteliquent Numbers" CustomButtons={CustomButtons} />
      {isLoading || loading ? <DotsLoader /> : null}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const counter = index + 1;
            const { domain, phone_status, phone } = record;
            return {
              records: { ...record },
              counter,
              domain,
              phone,
              phone_status,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          //   density="standard"
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{ domain_count: false }}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
}

export default InteliquentNumbers;
