import React,{useRef} from "react";
import { FaChevronDown } from "react-icons/fa";
import { Button } from "../../components";
function useVisible(onCancelModal) {
    const modalRef = useRef(null);
  
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        onCancelModal();
      }
    };
  
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancelModal();
      }
    };
  
    React.useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { modalRef };
  }
function Summary(props) {
    const { modalRef } = useVisible(props.onClose);
  const {
    totalCitiesList,
    totalStatesList,
    totalZipsList,
    uniqueCitiesList,
    uniqueStatesList,
    uniqueZipsList,
    duplicateCities,
    duplicateZips,
    duplicateStates
  } = props;
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative">
      <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
      <button
        onClick={props.onClose}
        className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
      <div className="z-10 w-auo w-full md:max-w-[90%] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white"
      ref={modalRef}
      >
        <details className="border mt-3 mb-3" open>
          <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
            <span>{`Total Record`}</span>
            <FaChevronDown iconClass="fas fa-times" />
          </summary>

          <div className=" m-2 bg-slate !my-3 border rounded p-1">
            <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-1.5">Total Zip </span>
                <span className="font-normal ">
                  {`(${totalZipsList?.length} found)`}{" "}
                </span>

                <textarea
                  className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={totalZipsList}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Total States </span>
                <span className="font-normal ">
                  {`(${totalStatesList?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={totalStatesList}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Total Cities </span>
                <span className="font-normal ">
                  {`(${totalCitiesList?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                  value={totalCitiesList}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </details>

        <details className="border mt-3 mb-3" open>
          <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
            <span>{`Unique Record`}</span>
            <FaChevronDown iconClass="fas fa-times" />
          </summary>

          <div className=" m-2 bg-slate !my-3 border rounded p-1">
            <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-1.5">Unique Zip </span>
                <span className="font-normal ">
                  {`(${uniqueZipsList?.length} found)`}{" "}
                </span>

                <textarea
                  className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={uniqueZipsList}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Unique States </span>
                <span className="font-normal ">
                  {`(${uniqueStatesList?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={uniqueStatesList}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Unique Cities </span>
                <span className="font-normal ">
                  {`(${uniqueCitiesList?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                  value={uniqueCitiesList}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </details>

        <details className="border mt-3 mb-3" open>
          <summary className="flex flex-row items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
            <span>{`Duplicate Record`}</span>
            <FaChevronDown iconClass="fas fa-times" />
          </summary>

          <div className=" m-2 bg-slate !my-3 border rounded p-1">
            <div className="flex flex-row py-3 bg-slate-50 items-center justify-evenly">
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-1.5">Duplicate Zip </span>
                <span className="font-normal ">
                  {`(${duplicateZips?.length} found)`}{" "}
                </span>

                <textarea
                  className="w-full mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={duplicateZips}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Duplicate States </span>
                <span className="font-normal ">
                  {`(${duplicateStates?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px] "
                  value={duplicateStates}
                  rows={3}
                />
              </div>
              <div className="w-2/6 px-2">
                <span className="font-semibold ml-3">Duplicate Cities </span>
                <span className="font-normal ">
                  {`(${duplicateCities?.length} found)`}{" "}
                </span>
                <textarea
                  className="w-full mt-1 !ml-2 px-3 py-2 bg-white border shadow-sm border-slate-300  block w-full rounded-[4px]"
                  value={duplicateCities}
                  rows={3}
                />
              </div>
            </div>
          </div>
        </details>
        <div className="flex w-full justify-end">
          <Button
            text="Close"
            className="mx-2"
            onClick={props.onClose}
            variant="btn_cancel"
          />
        </div>
      </div>
    </div>
    </div>
  );
}

export default Summary;
